import { model } from '..';
import { AppleAccess, BQProject, Field, Form, FeedForm } from '.';
import * as Api from '@/Api';
import { OrganizationRole } from './Organization';

export type EtlAction =
	| 'UPDATE_FIELD'
	| 'SELECT_FORM'
	| 'FETCH_USER_ORGANIZATION_ROLES'
	// ACCESSES
	| 'FETCH_BQ_PROJECT_ACCESSES'
	| 'FETCH_APPLE_ACCESSES'
	// FEEDS
	| 'FETCH_APPLE_FEEDS'
	| 'UPDATE_ETL_CONFIG'
	| 'CREATE_ETL_CONFIG'
	| 'RUN_ETL_CONFIG';

type Reducer = { [key in EtlAction]: any };

export const etlReducer: Reducer = {
	UPDATE_FIELD: updateField,
	SELECT_FORM: selectForm,
	FETCH_USER_ORGANIZATION_ROLES: fetchUserOrganizationRoles,
	// ACCESSES
	FETCH_BQ_PROJECT_ACCESSES: fetchBQProjectAccesses,
	FETCH_APPLE_ACCESSES: fetchAppleAccesses,
	// FEEDS
	FETCH_APPLE_FEEDS: fetchAppleFeeds,
	UPDATE_ETL_CONFIG: updateConfig,
	CREATE_ETL_CONFIG: createConfig,
	RUN_ETL_CONFIG: runConfig
};

function updateField(params: { id: string; form: Form; value: string | number | boolean }) {
	const field = model.currentEtlForm.fields.find((field: Field) => field.id === params.id) as Field;
	field.value = params.value;
}

function selectForm(form: Form) {
	console.log('form', form);

	model.currentEtlForm = form;
}

function runEtlFeedOnce(feed: FeedForm) {
	model.runConfigOnceState = 'loading';
	// Api.runEtlFeedOnce({

	// })
}

function fetchUserOrganizationRoles() {
	model.organizationRoles = { _tag: 'loading' };
	Api.getUserOrganizationRoles()
		.run()
		.then((res: { data: { data: { me: { organizationRoles: OrganizationRole[] } } } }) => {
			model.organizationRoles = {
				_tag: 'loaded',
				value: res.data.data.me.organizationRoles
			};
		})
		.catch(() => {
			model.organizationRoles = {
				_tag: 'error',
				value: 'An error accured while fetching organization roles of the user'
			};
			setTimeout(() => {
				model.organizationRoles = { _tag: 'notLoading' };
			}, 1500);
		});
}

// ACCESSES

function fetchBQProjectAccesses() {
	model.bQProjectAcceses = { _tag: 'loading' };
	Api.getBQProjects()
		.run()
		.then(({ data: { data: { bQProjects } } }: { data: { data: { bQProjects: BQProject[] } } }) => {
			model.bQProjectAcceses = {
				_tag: 'loaded',
				value: bQProjects
			};
		})
		.catch(() => {
			model.bQProjectAcceses = { _tag: 'error', value: 'An error accured while fetching big query projects' };
			setTimeout(() => {
				model.bQProjectAcceses = { _tag: 'notLoading' };
			}, 1500);
		});
}

function fetchAppleAccesses() {
	model.appleAcceses = { _tag: 'loading' };
	Api.getAppleAccesses()
		.run()
		.then(({ data: { data: { appleAccesses } } }: { data: { data: { appleAccesses: AppleAccess[] } } }) => {
			model.appleAcceses = { _tag: 'loaded', value: appleAccesses };
		})
		.catch(() => {
			model.appleAcceses = { _tag: 'error', value: 'An error accured while fetching accesses for apple' };
			setTimeout(() => {
				model.appleAcceses = { _tag: 'notLoading' };
			}, 1500);
		});
}

// FEEDS

function fetchAppleFeeds() {
	model.appleFeeds = { _tag: 'loading' };
	Api.getAppleFeeds()
		.run()
		.then(({ data: { data: { appleFeeds } } }: { data: { data: { appleFeeds: AppleFeed[] } } }) => {
			model.appleFeeds = { _tag: 'loaded', value: appleFeeds };
		})
		.catch(() => {
			model.appleFeeds = { _tag: 'error', value: 'An error accured while fetching feeds for apple' };
			setTimeout(() => {
				model.appleFeeds = { _tag: 'notLoading' };
			}, 1500);
		});
}

function createConfig() {
	model.createConfigState = 'loading';
	switch (model.currentEtlForm.type) {
		// ACCESSES
		case 'bQProject': {
			const { fields } = model.currentEtlForm;
			Api.createBQProject({
				organizationId: fields[0].value,
				descriptiveName: fields[1].value,
				projectId: fields[2].value,
				clientEmail: fields[3].value,
				privateKey: fields[4].value
			})
				.run()
				.then(res => {
					model.createConfigState = 'loaded';
					fetchBQProjectAccesses();
					setTimeout(() => {
						model.createConfigState = 'notLoading';
					}, 1500);
				})
				.catch(e => {
					model.createConfigState = 'error';
					setTimeout(() => {
						model.createConfigState = 'notLoading';
					}, 1500);
				});
			return;
		}
		case 'appleAcces': {
			const { fields } = model.currentEtlForm;
			Api.createAppleAccess({
				descriptiveName: fields[0].value,
				login: fields[1].value,
				password: fields[2].value,
				accountName: fields[3].value,
			})
				.run()
				.then(res => {
					model.createConfigState = 'loaded';
					fetchAppleAccesses();
					setTimeout(() => {
						model.createConfigState = 'notLoading';
					}, 1500);
				})
				.catch(e => {
					model.createConfigState = 'error';
					setTimeout(() => {
						model.createConfigState = 'notLoading';
					}, 1500);
				});
			return;
		}
		// FEEDS
		// case 'apple': {
		// 	const { fields } = model.currentEtlForm;
		// 	Api.createAppleFeed
		// 	return;
		// }
		default: {
			console.log('fok');
		}
	}
}

function updateConfig() {
	const currentEtlForm = model.currentEtlForm as Required<Form>;
	model.updateConfigState = 'loading';
	switch (currentEtlForm.type) {
		case 'bQProject': {
			const { fields, metadata } = currentEtlForm;
			Api.updateBQProject({
				id: metadata.id,
				organizationId: fields[0].value,
				descriptiveName: fields[1].value,
				projectId: fields[2].value,
				clientEmail: fields[3].value,
				privateKey: fields[4].value
			})
				.run()
				.then(res => {
					model.updateConfigState = 'loaded';
					fetchBQProjectAccesses();
					setTimeout(() => {
						model.updateConfigState = 'notLoading';
					}, 1500);
				})
				.catch(e => {
					model.updateConfigState = 'error';
					setTimeout(() => {
						model.updateConfigState = 'notLoading';
					}, 1500);
				});
			return;
		}
		case 'appleAcces': {
			const { fields, metadata } = currentEtlForm
			Api.updateAppleAccess({
				id: metadata.id,
				descriptiveName: fields[0].value,
				login: fields[1].value,
				password: fields[2].value,
				accountName: fields[3].value,
			})
				.run()
				.then(res => {
					model.updateConfigState = 'loaded';
					fetchAppleAccesses();
					setTimeout(() => {
						model.updateConfigState = 'notLoading';
					}, 1500);
				})
				.catch(e => {
					model.updateConfigState = 'error';
					setTimeout(() => {
						model.updateConfigState = 'notLoading';
					}, 1500);
				});
			return;
		}
		default:
			console.error('Forgot to implement some update case');
	}
}

function runConfig() {
	// model.currentEtlForm = appleAcceses.
}
