import {
	archiveViewRequestParams,
	copyViewRequestParams,
	deleteViewRequestParams,
	publishViewRequestParams,
	restoreViewRequestParams
} from '@/Api';
import { createIntents } from '@/model';

export const viewsIntents: ViewsIntents = createIntents(
	(present): ViewsIntents => ({
		GET_USER_VIEWS: () => present({ type: 'GET_USER_VIEWS' }),
		GET_ORGANIZATION_VIEWS: () => present({ type: 'GET_ORGANIZATION_VIEWS' }),
		CREATE_VIEW: props => present({ type: 'CREATE_VIEW', payload: props }),
		UPDATE_VIEW: props => present({ type: 'UPDATE_VIEW', payload: props }),
		DELETE_VIEW: props => present({ type: 'DELETE_VIEW', payload: props }),
		PUBLISH_VIEW: props => present({ type: 'PUBLISH_VIEW', payload: props }),
		ARCHIVE_VIEW: props => present({ type: 'ARCHIVE_VIEW', payload: props }),
		RESTORE_VIEW: props => present({ type: 'RESTORE_VIEW', payload: props }),
		COPY_VIEW: props => present({ type: 'COPY_VIEW', payload: props })
	})
);

export type ViewsIntents = {
	GET_USER_VIEWS(): void;
	GET_ORGANIZATION_VIEWS(): void;
	CREATE_VIEW(props: { name: string }): void;
	UPDATE_VIEW(props: { id: string; name: string; urlQueryParams?: string }): void;
	DELETE_VIEW(props: deleteViewRequestParams): void;
	PUBLISH_VIEW(props: publishViewRequestParams): void;
	ARCHIVE_VIEW(props: archiveViewRequestParams): void;
	RESTORE_VIEW(props: restoreViewRequestParams): void;
	COPY_VIEW(props: copyViewRequestParams): void;
};
