import * as React from 'react';
import { intents } from '@/intents';
import { view } from 'funwork-js';

export const View = view(() => {
	return (
		<div className="w-full h-full bg-primary center">
			<div className="center-v flex-col rounded w-80 h-96 bg-sidebar-dd">
				<div className="xl center font-bold mt-6 text-white">Welcome</div>
				<div className="h-full center">
					<button
						className="outline-none bg-c-accent1 hover:bg-c-accent1-darkened rounded p-2-4"
						onClick={() => {
							intents.SIGN_USER_IN();
						}}
					>
						<span className="text-white">Google sign in</span>
					</button>
				</div>
			</div>
		</div>
	);
});
