import { retryFeed } from '@/Api';
import { ViewLoadingStateIcon, AsyncState } from '@/Async';
import { model } from '@/model';
import { view } from 'funwork-js';
import React from 'react';

// ------------------------------------------------ VIEW ------------------------------------------------

export const View = view(() => {
	const [state, setState] = React.useState<AsyncState>('notLoading');

	return (
		<>
			<div className="p-8-4-0">
				<h1>Retry lambda</h1>
				<div className="center-v">
					<div
						className="center rounded bg-primary hover:bg-dropdown p-2-4 cursor-pointer"
						onClick={() => {
							if (model.retryLambdaId) {
								setState('loading');
								retryFeed(model.retryLambdaId).fork(e => setState('error'), res => setState('loaded'));
							}
						}}
					>
						Retry data feed
						<ViewLoadingStateIcon className="ml-2" state={state} />
					</div>
				</div>
			</div>
		</>
	);
});
