import { intents } from '@/intents';
import { model } from '@/model';
import { PredicateInputPresentational } from '@components/Dimensions/PredicateInputPresentational/PredicateInputPresentational';
import { SidebarDropdownPresentational } from '@components/Sidebar/SidebarDropdownPresentational';
import {
	getOperandByTypeAndOperator,
	getOperatorDropdownOptionsOfRawFilterDimension,
	getOperatorTypeOfRawField
} from '@dsl/operators';
import { FilterRowPresentational } from '@reusable/FilterRowPresentational/FilterRowPresentational';
import { view } from 'funwork-js';
import React from 'react';

export const FilterRowOfRawFilters = view(({ dimension, label, operand, operator, value, type }) => {
	const [temporaryDimensionValue, setTemporaryDimensionValue] = React.useState(dimension);
	const [temporaryOperatorValue, setTemporaryOperatorValue] = React.useState(operator);
	const [temporaryInputValue, setTemporaryInputValue] = React.useState(value);

	const [isEditable, setIsEditable] = React.useState(false);

	let operatorType = getOperatorTypeOfRawField(temporaryDimensionValue);

	React.useEffect(() => {
		operatorType = getOperatorTypeOfRawField(temporaryDimensionValue);
	}, [temporaryDimensionValue]);

	const onConfirm = e => {
		e.stopPropagation();
		setIsEditable(false);
		intents.UPDATE_RAW_FILTER({ dimension, label, operand, operator, value, type })({
			dimension: temporaryDimensionValue,
			operator: temporaryOperatorValue,
			value: temporaryInputValue
		});
	};

	const onRemove = () => intents.REMOVE_RAW_FILTER({ dimension, label, operand, operator, value, type });
	const onCancel = () => {
		setIsEditable(false);
		setTemporaryDimensionValue(dimension);
		setTemporaryOperatorValue(operator);
		setTemporaryInputValue(value);
	};

	const dimensionDropdown = (
		<SidebarDropdownPresentational
			onOpen={() => setIsEditable(true)}
			options={model.rawFieldsSchema.map(item => ({
				value: item.value,
				text: item.label,
				label: <div className="ellipsis max-w-15">{item.label}</div>
			}))}
			value={temporaryDimensionValue}
			onChange={(_, val) => {
				if (operatorType !== getOperatorTypeOfRawField(val)) {
					setTemporaryInputValue('');
				}
				setTemporaryDimensionValue(val);
			}}
		/>
	);

	const operatorDropdown = (
		<SidebarDropdownPresentational
			onOpen={() => setIsEditable(true)}
			options={getOperatorDropdownOptionsOfRawFilterDimension(operator)(operatorType)(temporaryDimensionValue)}
			value={temporaryOperatorValue}
			onChange={(_, val) => setTemporaryOperatorValue(val)}
		/>
	);

	const input = (
		<PredicateInputPresentational
			value={temporaryInputValue}
			operand={getOperandByTypeAndOperator(operatorType)(temporaryOperatorValue)}
			onInput={setTemporaryInputValue}
			isEditable={isEditable}
		/>
	);

	return (
		<FilterRowPresentational
			className="override:pl-8"
			dropdown1={dimensionDropdown}
			dropdown2={operatorDropdown}
			input={input}
			onRemove={onRemove}
			onConfirm={onConfirm}
			onCancel={onCancel}
			onBeginEditing={() => setIsEditable(true)}
			active={isEditable}
		/>
	);
});
