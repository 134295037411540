import { intents, Intents } from '@/intents';
import { model, Model, napSetup as _napSetup } from '@/model';
import { getPathWithoutStaging } from '@/Metrics';
// import { Maybe } from '@functions/crocks';

export const napSetup = () =>
	_napSetup<Model, Intents>({
		model,
		intents,
		naps: [
			initializeAnalyticsPage,
			fetchUserOrganizationRoles,
			fetchBQProjectAccesses,
			fetchAppleAccesses,
			fetchTableData,
			setHovered
		]
	});

const initializeAnalyticsPage = (model: Model, intents: Intents) => {
	const path = getPathWithoutStaging(model.path);
	if (!model.user) return;
	if (!(path[0] === '' || path[0] === 'explore')) return;
	if (model.isAnalyticsPageReady) return;
	intents.INITIALIZE_ANALYTICS_PAGE();
	intents.GET_USER_VIEWS();
	intents.GET_ORGANIZATION_VIEWS();
};

const fetchUserOrganizationRoles = (model: Model, intents: Intents) => {
	const path = getPathWithoutStaging(model.path);
	if (!model.user) return;
	if (path[0] !== 'etl') return;
	intents.FETCH_USER_ORGANIZATION_ROLES();
};

const fetchBQProjectAccesses = (model: Model, intents: Intents) => {
	const path = getPathWithoutStaging(model.path);
	if (!model.user) return;
	if (path[0] !== 'etl') return;
	intents.FETCH_BQ_PROJECT_ACCESSES();
};

const fetchAppleAccesses = (model: Model, intents: Intents) => {
	const path = getPathWithoutStaging(model.path);
	if (!model.user) return;
	if (path[0] !== 'etl') return;
	intents.FETCH_APPLE_ACCESSES();
};

let fetchId = 0;
const fetchTableData = (model: Model, intents: Intents) => {
	if (!model.user) return;
	if (!model.isAnalyticsPageReady) return;
	if (!model.shouldFetchTableData) return;
	if (model.lastDataFetchId === fetchId) return;
	if (model.breakdowns.allIds.length === 0) return;
	fetchId = model.lastDataFetchId;
	intents.FETCH_TABLE_DATA();
};

const setHovered = (model: Model) => {
	model.rowsToShow = model.flattenedData.filter(item => item.visible);
};
