import * as Reusable from '@/Reusable';
import { unit } from '@functions/crocks';
import * as React from 'react';
import { classNames } from '@/imports';
import { SidebarItemPresentational } from '@components/Sidebar/SidebarItemPresentational';
import { view } from 'funwork-js';

interface Props {
	className?: string;
	icon?;
	label: string | JSX.Element;
	onChevron;
	opened: boolean;
	empty: boolean;
}

export const LabelPresentational = view(
	({ className = '', onChevron = unit, icon, label, opened = false, empty = false }: Props) => {
		const classes = classNames(className, 'label-presentational pl-6 override:text-sidebar-font-dark');
		const rotateClass = classNames('transition-03', { '-rotateZ-180': opened });

		const chevronClick = () => {
			if (!empty) onChevron();
		};

		return (
			<SidebarItemPresentational
				className={classes}
				onClick={chevronClick}
				icon={
					empty ? (
						<Reusable.ViewIcon className="pointer-events-none" name="circle" weight="solid" size="nano" />
					) : (
						<Reusable.ViewIcon className={rotateClass} name="chevron-down" size="nano" />
					)
				}
				onIconClick={chevronClick}
				hasEdit={false}
			>
				<div className="center-v nowrap w-full">
					<div className="center-v mr-2">{icon}</div>
					{label}
				</div>
			</SidebarItemPresentational>
		);
	}
);
