import { model } from '@/model';

export type SidebarAction =
	| 'TOGGLE_DIMENSION_FILTERS_OF_SIDEBAR'
	| 'TOGGLE_DIMENSIONS_OF_SIDEBAR'
	| 'TOGGLE_ORGANIZATION_ACTIVE_VIEWS_OF_SIDEBAR'
	| 'TOGGLE_ORGANIZATION_ARCHIVED_VIEWS_OF_SIDEBAR'
	| 'TOGGLE_ORGANIZATION_VIEWS_OF_SIDEBAR'
	| 'TOGGLE_SIDEBAR'
	| 'TOGGLE_USER_VIEWS_OF_SIDEBAR'
	| 'TOGGLE_VIEWS_OF_SIDEBAR'
	| 'TOGGLE_ACCESSES_OF_SIDEBAR'
	| 'TOGGLE_FEEDS_OF_SIDEBAR'
	| 'TOGGLE_BQ_PROJECT_ACCESSES_OF_SIDEBAR'
	| 'TOGGLE_APPLE_ACCESSES_OF_SIDEBAR'
	| 'TOGGLE_APPLE_FEEDS_OF_SIDEBAR';

export const sidebarReducer = {
	TOGGLE_DIMENSION_FILTERS_OF_SIDEBAR: toggleSidebarRawFilters,
	TOGGLE_DIMENSIONS_OF_SIDEBAR: toggleSidebarDimensions,
	TOGGLE_ORGANIZATION_ACTIVE_VIEWS_OF_SIDEBAR: toggleSidebarOrganizationActiveViews,
	TOGGLE_ORGANIZATION_ARCHIVED_VIEWS_OF_SIDEBAR: toggleSidebarOrganizationArchivedViews,
	TOGGLE_ORGANIZATION_VIEWS_OF_SIDEBAR: toggleSidebarOrganizationViews,
	TOGGLE_SIDEBAR: toggleSidebar,
	TOGGLE_USER_VIEWS_OF_SIDEBAR: toggleSidebarUserViews,
	TOGGLE_VIEWS_OF_SIDEBAR: toggleSidebarViews,
	TOGGLE_ACCESSES_OF_SIDEBAR: toggleAccessesSidebar,
	TOGGLE_FEEDS_OF_SIDEBAR: toggleFeedsSidebar,
	TOGGLE_BQ_PROJECT_ACCESSES_OF_SIDEBAR: toggleBQProjectAccessesSidebar,
	TOGGLE_APPLE_ACCESSES_OF_SIDEBAR: toggleAppleAccessesSidebar,
	TOGGLE_APPLE_FEEDS_OF_SIDEBAR: toggleAppleFeedsSidebar
};

function toggleSidebar() {
	model.isSidebarOpened = !model.isSidebarOpened;
}

function toggleSidebarDimensions() {
	model.isDimensionsSectionOpened = !model.isDimensionsSectionOpened;
}

function toggleSidebarRawFilters() {
	model.isRawFiltersSectionOpened = !model.isRawFiltersSectionOpened;
}

function toggleSidebarViews() {
	model.isViewsOpened = !model.isViewsOpened;
}
function toggleSidebarOrganizationActiveViews() {
	model.isOrganizationActiveViewsOpened = !model.isOrganizationActiveViewsOpened;
}
function toggleSidebarOrganizationArchivedViews() {
	model.isOrganizationArchivedViewsOpened = !model.isOrganizationArchivedViewsOpened;
}
function toggleSidebarOrganizationViews() {
	model.isOrganizationViewsOpened = !model.isOrganizationViewsOpened;
}
function toggleSidebarUserViews() {
	model.isUserViewsOpened = !model.isUserViewsOpened;
}
function toggleAccessesSidebar() {
	model.isAccessesSectionOpened = !model.isAccessesSectionOpened;
}
function toggleFeedsSidebar() {
	model.isFeedsSectionOpened = !model.isFeedsSectionOpened;
}
function toggleBQProjectAccessesSidebar() {
	model.isBQProjectAccessesOpened = !model.isBQProjectAccessesOpened;
}
function toggleAppleAccessesSidebar() {
	model.isAppleAccessesOpened = !model.isAppleAccessesOpened;
}
function toggleAppleFeedsSidebar() {
	model.isAppleFeedsOpened = !model.isAppleFeedsOpened;
}
