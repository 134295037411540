import { intents } from '@/intents';
import * as User from '@/User';
import { Maybe } from '@functions/crocks';
import { useOnClickOutside } from '@hooks/';
import { view } from 'funwork-js';
import React from 'react';
import { classNames } from './imports';
import { getPathWithoutStaging } from './Metrics';
import { model } from './model';
import * as Home from './Page/Home';
import * as Etl from './Page/Etl';
import * as Login from './Page/Login';
import * as Retry from './Page/Retry';
import * as Sidebar from './Page/Sidebar';
import * as Reusable from './Reusable';

export { Login };

export type Page = '' | 'explore' | 'login' | 'lambda-retry' | 'etl';

export const View = view(() => {
	const { user } = model;
	const path = getPathWithoutStaging(model.path);

	return (
		<div className="flex h-100vh w-100vw">
			{!user ? (
				<Login.View />
			) : (
				<div className="flex flex-grow">
					<Sidebar.View toggleOpened={intents.TOGGLE_SIDEBAR} closed={!model.isSidebarOpened}>
						{path[0] === '' || path[0] === 'explore' ? (
							<Home.Sidebar.View Section={Sidebar.Section} />
						) : path[0] === 'etl' ? (
							<Etl.Sidebar.View Section={Sidebar.Section} />
						) : (
							undefined
						)}
					</Sidebar.View>
					<div className="flex-grow w-content h-content">
						<ViewHeader />
						{!user || path[0] === 'login' ? (
							<Login.View />
						) : path[0] === '' || path[0] === 'explore' ? (
							<Home.View />
						) : path[0] === 'lambda-retry' ? (
							<Retry.View />
						) : path[0] === 'etl' ? (
							<Etl.View />
						) : (
							<Login.View />
						)}
					</div>
				</div>
			)}
		</div>
	);
});

const ViewHeader = view(() => {
	return (
		<div className="relative flex h-header-height ai-center bg-white border-b border-sidebar-font-light">
			<div className="center-v jc-between w-full h-full px-4-2">
				<div className="flex pl-2">
					{/* <Logo /> */}
					<Navigation />
				</div>
				<User.View />
			</div>
		</div>
	);
});

const Navigation = view(() => {
	const { path } = model;

	const goToPage = (page: Page) => () => {
		intents.SET_URL(page);
	};

	const pageClasses = (_page: Page[]) => classNames('cursor-pointer', { 'text-c-accent1': _page.includes(path[0]) });

	return (
		<div className="center w-full children:mr-6">
			<div className={pageClasses(['', 'explore'])} onClick={goToPage('explore')}>
				<Reusable.ViewIcon className="mr-2" name="chart-pie" />
				<span className="uppercase">Explore</span>
			</div>
			<div className={pageClasses(['etl'])} onClick={goToPage('etl')}>
				<Reusable.ViewIcon className="mr-2" name="chart-network" />
				<span className="uppercase">Etl</span>
			</div>
		</div>
	);
});
