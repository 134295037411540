import * as Api from '@/Api';
import { getCumulativeMetricMatch, getMetricValueOfCompositeString, getRowsToShow, isLastActiveDay } from '@/Metrics';
import { Page } from '@/Page';
import { getYesterday } from '@/Predicates';
import { User } from '@/Session';
import { isPredicateOfDimensionAndMetric } from '@dsl/predicates';
import { flattenTableData, nestData } from '@dsl/table';
import {
	assoc,
	compose,
	equals,
	filter,
	fromPairs,
	getPath,
	getProp,
	map,
	not,
	omit,
	pick,
	toPairs
} from '@functions/crocks';
import { debounce } from '@functions/debounce';
import { pickByFunctionOnKey } from '@functions/pickByFunctionOnKey';
import download from 'downloadjs';
import O from 'patchinko/constant';
import { deserializeUrlToModel, model, Model } from '.';
import { EtlAction, etlReducer } from './etl/reducer';
import { getEnhancedMetric, MetricsAction, metricsReducer } from './metrics/reducer';
import { RawFiltersAction, rawFiltersReducer } from './rawFilters/reducer';
import { SessionAction } from './session/reducer';
import { SidebarAction, sidebarReducer } from './sidebar/reducer';
import { TableAction, tableReducer } from './table/reducer';
import { getHeadOfSplit } from './utils';
import { ViewsAction, viewsReducer } from './views/reducer';

export type Action =
	| MetricsAction
	| RawFiltersAction
	| SidebarAction
	| TableAction
	| SessionAction
	| ViewsAction
	| EtlAction
	| 'ADD_DIMENSION'
	| 'COLLAPSE_ALL_ROWS'
	| 'EXPAND_ROWS_OF_DIMENSION'
	| 'EXPORT_TABLE_AT_DIMENSION'
	| 'FETCH_ALMOST_ALL_TABLE_DATA'
	| 'FETCH_MORE_TABLE_DATA'
	| 'FETCH_TABLE_DATA'
	| 'INITIALIZE_ANALYTICS_PAGE'
	| 'LOAD_MODEL'
	| 'REMOVE_ALL_SORTINGS'
	| 'REMOVE_DIMENSION'
	| 'REMOVE_PREDICATE_OF_DIMENSION'
	| 'REMOVE_SORTING_OF_DIMENSION'
	| 'SET_URL'
	| 'SIGN_USER_IN'
	| 'SIGN_USER_OUT'
	| 'TOGGLE_ROW'
	| 'UPDATE_DIMENSION'
	| 'UPDATE_FILTER_PREDICATE_OF_DIMENSION'
	| 'UPDATE_IS_METRIC_PICKER_OPENED'
	| 'UPDATE_ORDER_OF_DIMENSIONS'
	| 'UPDATE_ORDER_OF_SORTINGS_OF_DIMENSION'
	| 'UPDATE_SORTING_METRIC_OF_DIMENSION'
	| 'UPDATE_SORTING_OF_DIMENSION'
	| 'UPDATE_TABLE_WIDTH';

type Reducer = { [key in Action | 'default']: any };
export const reducer: Reducer = {
	...metricsReducer,
	...sidebarReducer,
	...rawFiltersReducer,
	...tableReducer,
	...viewsReducer,
	...etlReducer,
	ADD_DIMENSION: addDimension,
	COLLAPSE_ALL_ROWS: collapseAllRows,
	EXPAND_ROWS_OF_DIMENSION: expandRowsOfBreakdown,
	EXPORT_TABLE_AT_DIMENSION: exportTableAtBreakdown,
	FETCH_ALMOST_ALL_TABLE_DATA: fetchAllTableData,
	FETCH_MORE_TABLE_DATA: fetchMoreTableData,
	FETCH_TABLE_DATA: debounce(350, fetchTableData),
	INITIALIZE_ANALYTICS_PAGE: initializeAnalyticsPage,
	LOAD_MODEL: loadModel,
	REMOVE_ALL_SORTINGS: removeAllOrderings,
	REMOVE_DIMENSION: removeBreakdownLevel,
	REMOVE_PREDICATE_OF_DIMENSION: removePredicate,
	REMOVE_SORTING_OF_DIMENSION: removeBreakdownOrdering,
	SET_URL: setPage,
	SIGN_USER_IN: signInUser,
	SIGN_USER_OUT: signOutUser,
	TOGGLE_ROW: toggleRow,
	UPDATE_DIMENSION: setBreakdownLevel,
	UPDATE_FILTER_PREDICATE_OF_DIMENSION: setBreakdownFilter,
	UPDATE_IS_METRIC_PICKER_OPENED: setIsMetricPickerOpened,
	UPDATE_ORDER_OF_DIMENSIONS: changeBreakdownsOrder,
	UPDATE_ORDER_OF_SORTINGS_OF_DIMENSION: changeBreakdownSortingOrder,
	UPDATE_SORTING_METRIC_OF_DIMENSION: changeBreakdownOrdering,
	UPDATE_SORTING_OF_DIMENSION: setBreakdownOrdering,
	UPDATE_TABLE_WIDTH: setTableWidth,
	UPDATE_USER: updateUser,
	default: (_, payload) => {
		console.error('Action type ', payload.type, 'has no handler');
	}
};

function setPage(page: Page) {
	if (model.path[0] === 'staging') {
		model.path[1] = page;
		return;
	}
	model.path[0] = page;
}

function updateUser(user: User) {
	model.user = user;
}

function signInUser() {
	Api.signIn();
}

function signOutUser() {
	Api.signOut();
}

function loadModel(urlQueryParams: string) {
	const newModel = deserializeUrlToModel({ path: model.path.join('/'), query: JSON.parse(urlQueryParams) });
	for (const key in newModel) {
		model[key] = newModel[key];
	}
	model.selectedMetrics = enhanceSelectedMetrics(model, model.metricsSchema);
	model.shouldFetchTableData = true;
	model.lastDataFetchId = (model.lastDataFetchId + 1) % 100;
}

function initializeAnalyticsPage() {
	Api.getInitialAnalyticsData()
		.run()
		.then(res => {
			const { breakdowns, metrics, rawFields, filters } = res.data;

			const _breakdownsSchema = { byId: {}, allIds: [] };
			for (const item of breakdowns) {
				_breakdownsSchema.byId[item.value] = item;
				_breakdownsSchema.allIds.push(item.value);
			}

			const _filtersSchema = { byId: {}, allIds: [] };
			for (const item of filters) {
				_filtersSchema.byId[item.type] = item;
				_filtersSchema.allIds.push(item.type);
			}

			const metricsSchema = metrics.reduce(
				(acc, item) => assoc(item.value)({ ...item, predicates: _filtersSchema.byId[item.type] })(acc),
				{}
			);

			const _rawFieldsSchema = { byId: {}, allIds: [] };
			for (const item of rawFields) {
				_rawFieldsSchema.byId[item.value] = item;
				_rawFieldsSchema.allIds.push(item.value);
			}

			const filterTypes = {};
			for (const filterType of filters) {
				filterTypes[filterType.type] = filterType.operators;
			}

			const columns = metrics.reduce((acc, metric) => {
				if (!metric.params) return acc.concat({ ...metric, predicates: filterTypes[metric.type] });
				if (metric.params.day_of_activity.required) {
					return acc.concat({
						...metric,
						value: `dx_${metric.value}`,
						label: `DX ${metric.label}`,
						predicates: filterTypes[metric.type]
					});
				}
				return acc.concat(
					{ ...metric, predicates: filterTypes[metric.type], params: undefined },
					{
						...metric,
						value: `dx_${metric.value}`,
						label: `DX ${metric.label}`,
						predicates: filterTypes[metric.type]
					}
				);
			}, []);

			const rawFieldsSchema = rawFields.map(rawField => ({
				...rawField,
				predicates: filterTypes[rawField.type]
			}));

			const selectedMetrics = enhanceSelectedMetrics(model, metricsSchema);
			const customAddCumulativeMetricsOptions = selectedMetrics.reduce(
				(acc, val) =>
					getPath(['params', 'day_of_activity'])(val)
						.map(number => (acc.includes(number) ? acc : acc.concat(number)))
						.map(
							filter(
								number =>
									!model.addCumulativeMetricsOptions.recommended.includes(number) &&
									!model.addCumulativeMetricsOptions.early.includes(number)
							)
						)
						.option(acc),
				[]
			);

			const predicates = enhancePredicates(model, metricsSchema);

			O(model, {
				selectedMetrics,
				metricsSchema,
				addCumulativeMetricsOptions: O({
					custom: customAddCumulativeMetricsOptions
				}),
				_breakdownsSchema,
				_filtersSchema,
				_rawFieldsSchema,
				columns,
				rawFieldsSchema,
				predicates,
				breakdownsSchema: breakdowns,
				filtersSchema: filters,
				isAnalyticsPageReady: true,
				shouldFetchTableData: true,
				lastDataFetchId: O(index => (index + 1) % 100),
				tableState: model.breakdowns.allIds.length === 0 ? 'notLoading' : 'loading'
			});
		})
		.catch(e => {
			console.error('Failed to load initial data', e);
		});
}

export function enhanceSelectedMetrics(model, metricsSchema) {
	return model.selectedMetrics.map(getEnhancedMetric(metricsSchema));
}

export function enhancePredicates(model, metricsSchema) {
	const predicates = { allIds: model.predicates.allIds, byIds: {} };
	for (const predicateKey in model.predicates.byIds) {
		const { label, operand } = metricsSchema[
			getMetricValueOfCompositeString(model.predicates.byIds[predicateKey].metric).value
		].predicates.operators.find(({ operator }) => operator === model.predicates.byIds[predicateKey].operator);

		predicates.byIds[predicateKey] = {
			...model.predicates.byIds[predicateKey],
			label,
			operand
		};
	}

	return predicates;
}

function fetchTableData() {
	const fetchId = model.lastDataFetchId;
	model.tableState = 'loading';
	Api.fetchAnalyticsData(prepareFetchArguments(model))
		.run()
		.then(res => {
			if (model.lastDataFetchId !== fetchId) return;
			const data = nestData(8, res.data.data);
			model.tableData = data;
			
			model.flattenedData = flattenTableData(data, 8).map(row => {
				return {
					...row,
					opened: row.path.reduce((acc, val) => acc && acc[val], model.openedRows),
					visible:
						row.path.length === 1 ||
						!!row.path.slice(0, -1).reduce((acc, val) => acc && acc[val], model.openedRows)
				};
			});
			// model.rowsToShow = model.flattenedData.filter(item => item.visible);
			model.rowsToShow = getRowsToShow();
			model.tableState = 'loaded';
			model.shouldFetchTableData = false;
		})
		.catch(() => {
			if (model.lastDataFetchId !== fetchId) return;
			model.tableState = 'error';
			model.shouldFetchTableData = false;
		});
}

function fetchAllTableData() {
	O(model, {
		breakdowns: O({
			byIds: O(byIds => {
				for (const key in byIds) {
					O(byIds[key], {
						size: 10000
					});
				}
				return byIds;
			})
		}),
		shouldFetchTableData: true,
		lastDataFetchId: O(index => (index + 1) % 100)
	});
}

function fetchMoreTableData({ breakdownLevel, after, amount }) {
	const id = model.breakdowns.allIds[breakdownLevel];
	O(model, {
		breakdowns: O({
			byIds: O({
				[id]: O({
					size: O(size => {
						return size + amount;
					})
				})
			})
		}),
		shouldFetchTableData: true,
		lastDataFetchId: O(index => (index + 1) % 100)
	});
}

function exportTableAtBreakdown<T>(a: { breakdownLevel: number; propagateError: boolean }): Promise<T>;
function exportTableAtBreakdown({ breakdownLevel, propagateError = false }) {
	return Api.exportAnalyticsData({
		...prepareFetchArguments(model),
		exportBreakdown: model.breakdowns.allIds[breakdownLevel]
	})
		.run()
		.then(res => {
			download(
				res.data,
				`${model.breakdowns.allIds.slice(0, breakdownLevel + 1).join('-')}-${new Date()
					.toISOString()
					.slice(0, -5)}.csv`,
				'text/csv'
			);
		})
		.catch(e => {
			console.error(e);
			if (propagateError) throw e;
		});
}

function setBreakdownLevel({ breakdownLevel, id }) {
	const previousBreakdown = model.breakdowns.allIds[breakdownLevel];
	const removeUnnecessaryAllIds = filter(id => previousBreakdown !== getHeadOfSplit('-')(id));
	const removeUnnecessaryByIds = compose(
		fromPairs,
		filter(pair => getHeadOfSplit('-')(pair.fst()) !== previousBreakdown),
		toPairs
	);

	O(model, {
		breakdowns: O({
			byIds: O({
				[previousBreakdown]: O,
				[id]: { id, size: 10 }
			}),
			allIds: O({ [breakdownLevel]: id })
		}),
		predicates: O({
			allIds: O(removeUnnecessaryAllIds),
			byIds: O(removeUnnecessaryByIds)
		}),
		orderings: O({
			allIds: O(removeUnnecessaryAllIds),
			byIds: O(removeUnnecessaryByIds)
		}),
		openedRows: {},
		shouldFetchTableData: true,
		lastDataFetchId: O(index => (index + 1) % 100)
	});
}

function removeBreakdownLevel(breakdownLevel) {
	const id = model.breakdowns.allIds[breakdownLevel];
	const removeUnnecessaryAllIds = filter(_id => id !== getHeadOfSplit('-')(_id));
	const removeUnnecessaryByIds = compose(
		fromPairs,
		filter(pair => getHeadOfSplit('-')(pair.fst()) !== id),
		toPairs
	);

	O(model, {
		breakdowns: O({
			byIds: O({ [id]: O }),
			allIds: O(allIds => allIds.filter(breakdownId => breakdownId !== id))
		}),
		predicates: O({
			allIds: O(removeUnnecessaryAllIds),
			byIds: O(removeUnnecessaryByIds)
		}),
		orderings: O({
			allIds: O(removeUnnecessaryAllIds),
			byIds: O(removeUnnecessaryByIds)
		}),
		openedBreakdown: O(openedBreakdown => {
			if (openedBreakdown === breakdownLevel) return undefined;
			if (openedBreakdown > breakdownLevel) return openedBreakdown - 1;
			return openedBreakdown;
		}),
		openedRows: {},
		shouldFetchTableData: true,
		lastDataFetchId: O(index => (index + 1) % 100)
	});
}

function addDimension(id) {
	const hasCohort = model.breakdowns.allIds.find(equals('cohort'));

	O(model, {
		breakdowns: O({
			byIds: O({ [id]: { id, size: 10 } }),
			allIds: O(allIds => {
				return hasCohort
					? allIds
							.filter(not(equals('cohort')))
							.concat(id)
							.concat('cohort')
					: allIds.concat(id);
			})
		}),
		openedRows: {},
		shouldFetchTableData: true,
		lastDataFetchId: O(index => (index + 1) % 100)
	});
}

function changeBreakdownsOrder({ oldIndex, newIndex }) {
	const id = model.breakdowns.allIds[oldIndex];

	O(model, {
		breakdowns: O({
			allIds: O(breakdowns => {
				const breakdown = breakdowns.splice(oldIndex, 1)[0];
				breakdowns.splice(newIndex, 0, breakdown);
				return breakdowns;
			}),
			byIds: O({ [id]: O({ size: 10 }) })
		}),
		openedFilter: O(openedFilter => {
			if (openedFilter === oldIndex) return newIndex;
			if (oldIndex < openedFilter && openedFilter <= newIndex) return openedFilter - 1;
			if (openedFilter < oldIndex && newIndex <= openedFilter) return openedFilter + 1;
			return openedFilter;
		}),
		openedRows: {},
		shouldFetchTableData: true,
		lastDataFetchId: O(index => (index + 1) % 100)
	});
}

function changeBreakdownSortingOrder({ breakdownId, oldIndex, newIndex }) {
	if (oldIndex === newIndex) return;

	O(model, {
		orderings: O({
			byIds: O({
				[breakdownId]: O(orderBy => {
					const sort = orderBy.splice(oldIndex, 1)[0];
					orderBy.splice(newIndex, 0, sort);
					return orderBy;
				})
			})
		}),
		shouldFetchTableData: true,
		lastDataFetchId: O(index => (index + 1) % 100)
	});
}

function setBreakdownFilter({ breakdownId, column, predicate }) {
	const predicateId = `${breakdownId}-${column}-${predicate.operator}`;

	O(model, {
		breakdowns: O({
			byIds: O({
				[breakdownId]: O({
					filters: O((filters: string[]): string[] => {
						if (filters) {
							if (!filters.includes(predicateId)) {
								return filters.concat(predicateId);
							}
							return filters;
						}
						return [predicateId];
					})
				})
			})
		}),

		predicates: O({
			byIds: O(byIds => {
				if (predicate.operator) {
					byIds[predicateId] = {
						id: predicateId,
						breakdown: breakdownId,
						metric: column,
						operator: predicate.operator,
						label: predicate.label,
						operand: predicate.operand,
						value: isLastActiveDay(column) && !predicate.value ? getYesterday() : predicate.value
					};
				}
				return byIds;
			}),
			allIds: O(allIds => {
				if (predicate.operator) {
					if (!allIds.includes(predicateId)) {
						return allIds.concat(predicateId);
					}
				}
				return allIds;
			})
		}),

		...(Object.keys(predicate).length === 0
			? {}
			: {
					shouldFetchTableData: true,
					lastDataFetchId: O(index => (index + 1) % 100)
			  })
	});
}

function removePredicate({ breakdownId, column, predicate }) {
	const predicateId = `${breakdownId}-${column}-${predicate}`;

	model.predicates.allIds = model.predicates.allIds.filter(item => item !== predicateId);
	model.predicates.byIds = pick(model.predicates.allIds, model.predicates.byIds);

	O(model, {
		shouldFetchTableData: true,
		lastDataFetchId: O(index => (index + 1) % 100)
	});
}

function toggleRow({ path, open }) {
	const { openedRows } = model;
	const rowToOpen = path.slice(0, -1);
	const index = path.slice(-1)[0];

	const _path = rowToOpen.reduce((acc, val) => {
		if (!acc[val]) {
			acc[val] = {};
		}
		return acc[val];
	}, openedRows);

	if (_path[index]) {
		delete _path[index];
	} else {
		_path[index] = { '.': '.' };
	}

	const joinedPath = path.join('-');

	model.flattenedData.forEach((row, index) => {
		if (joinedPath === row.path.join('-')) {
			model.flattenedData[index] = { ...row, opened: open };
			return;
		}
		if (open) {
			if (row.path.join('-').startsWith(joinedPath) && row.path.length === path.length + 1) {
				model.flattenedData[index] = { ...row, visible: true };
			}
			return;
		}
		if (row.path.join('-').startsWith(joinedPath)) {
			model.flattenedData[index] = { ...row, visible: false };
		}
	});

	model.rowsToShow = getRowsToShow();
}

function collapseAllRows() {
	model.openedRows = {};
	model.flattenedData.forEach((row, index) => {
		if (row.opened) model.flattenedData[index] = { ...row, opened: false };
		if (row.path.length !== 1 && row.visible) model.flattenedData[index] = { ...row, visible: false };
	});
	model.rowsToShow = getRowsToShow();
}

function expandRowsOfBreakdown(breakdownLevel: number) {
	model.openedRows = expandBreakdown(breakdownLevel + 1)({ entries: model.tableData });
	model.flattenedData.forEach((row, index) => {
		if (!row.opened) {
			model.flattenedData[index] = {
				...row,
				opened: !!row.path.reduce((acc, val) => acc && acc[val], model.openedRows)
			};
		}
		if (!row.visible) {
			model.flattenedData[index] = {
				...row,
				visible:
					row.path.length === 1 ||
					!!row.path.slice(0, -1).reduce((acc, val) => acc && acc[val], model.openedRows)
			};
		}
	});
	model.rowsToShow = getRowsToShow();
}

function setBreakdownOrdering({ breakdownId, column, order }) {
	O(model, {
		orderings: O({
			allIds: O(orderings => {
				if (orderings) {
					if (orderings.includes(breakdownId)) return orderings;
					return orderings.concat(breakdownId);
				}
				return [breakdownId];
			}),
			byIds: O({
				[breakdownId]: O(orderings => {
					const orderIndex = orderings && orderings.findIndex(order => order.metric === column);
					if (orderIndex !== undefined && orderIndex !== -1) {
						orderings[orderIndex] = { order, metric: column };
						return orderings;
					}
					return orderings ? orderings.concat({ order, metric: column }) : [{ order, metric: column }];
				})
			})
		}),
		shouldFetchTableData: true,
		lastDataFetchId: O(index => (index + 1) % 100)
	});
}

function changeBreakdownOrdering({ breakdownId, metricFrom, metricTo }) {
	O(model, {
		orderings: O({
			byIds: O({
				[breakdownId]: O(orderings => {
					const orderIndex = orderings && orderings.findIndex(order => order.metric === metricFrom);
					const { order } = orderings[orderIndex];
					orderings[orderIndex] = { order, metric: metricTo };
					return orderings;
				})
			})
		}),
		shouldFetchTableData: true,
		lastDataFetchId: O(index => (index + 1) % 100)
	});
}

function removeBreakdownOrdering({ breakdownId, column }) {
	model.orderings.byIds[breakdownId] = model.orderings.byIds[breakdownId].filter(
		ordering => ordering.metric !== column
	);

	if (model.orderings.byIds[breakdownId].length === 0) {
		model.orderings.byIds = omit([breakdownId])(model.orderings.byIds);
		model.orderings.allIds = model.orderings.allIds.filter(breakdown => breakdown !== breakdownId);
	}

	O(model, {
		shouldFetchTableData: true,
		lastDataFetchId: O(index => (index + 1) % 100)
	});
}

function removeAllOrderings() {
	O(model, {
		orderings: O({
			byIds: {},
			allIds: []
		}),
		shouldFetchTableData: true,
		lastDataFetchId: O(index => (index + 1) % 100)
	});
}

function setIsMetricPickerOpened(value) {
	O(model, { isMetricPickerOpened: value || false });
}

function setTableWidth(width) {
	model.tableWidth = width;
}

export function prepareFetchArguments(model: Model) {
	const metrics = model.selectedMetrics.map(pick(['value', 'params']));

	const breakdowns = model.breakdowns.allIds.map(breakdownId => {
		const breakdown = model.breakdowns.byIds[breakdownId];
		const filterBy = {};
		for (const column of breakdown.filters || []) {
			const [, metric] = column.split('-');
			const checked = getCumulativeMetricMatch(metric);
			const _metric = checked ? checked[2] : metric;
			if (!filterBy[_metric]) {
				filterBy[_metric] = [];
			}
			const predicates = pickByFunctionOnKey(isPredicateOfDimensionAndMetric(breakdownId)(metric))(
				model.predicates.byIds
			);
			for (const predicate in predicates) {
				const { operator, value } = predicates[predicate];
				if (value === null) continue;
				if (checked) {
					const index = filterBy[_metric].findIndex(
						item => item.params && item.params.day_of_activity === checked[1]
					);
					if (index !== -1) {
						filterBy[_metric][index][operator] = value;
					} else {
						filterBy[_metric].push({
							params: { day_of_activity: checked[1] },
							[operator]: value
						});
					}
				} else {
					const index = filterBy[_metric].findIndex(item => !item.params);
					if (index !== -1) {
						filterBy[_metric][index][operator] = value;
					} else {
						filterBy[_metric].push({ [operator]: value });
					}
				}
			}
		}

		const ordering = getProp(breakdownId)(model.orderings.byIds);
		const orderBy = ordering
			.map(
				map(item => {
					const checked = getCumulativeMetricMatch(item.metric);
					const value = checked ? checked[2] : item.metric;
					return {
						metric: {
							value,
							...(checked && { params: { day_of_activity: checked[1] } })
						},
						order: item.order
					};
				})
			)
			.option(false);

		return {
			...(Object.keys(filterBy).length > 0 && { filterBy }),
			...(orderBy && { orderBy }),
			groupBy: breakdown.id,
			size: breakdown.size
		};
	});

	const filterBy = {};
	for (const filter of model.rawFilters) {
		if (!filterBy[filter.dimension]) filterBy[filter.dimension] = {};
		if (!filterBy[filter.dimension][filter.operator]) {
			if (filter.operand === 'string[]' && filter.value) {
				filterBy[filter.dimension][filter.operator] = [];
			}
		}

		if (filter.operand === 'string[]' && filter.value) {
			filterBy[filter.dimension][filter.operator].push(filter.value);
			continue;
		}
		if (filter.operand === 'isoDate' && filter.value) {
			filterBy[filter.dimension][filter.operator] =
				(filter.value && new Date(filter.value).toISOString()) || filter.value;
			continue;
		}
		if (filter.value) {
			filterBy[filter.dimension][filter.operator] = filter.value;
		}
	}

	return { metrics, breakdowns, filterBy };
}

// function openRows(path) {
// 	return model => {
// 		return O(model, {
// 			openedRows: O(openedRows => {
// 				const rowContainer = path.reduce((acc, val) => {
// 					return acc[val].entries;
// 				}, model.tableData);

// 				const opened = path.reduce((acc, val) => {
// 					return acc[val];
// 				}, openedRows);

// 				rowContainer.forEach((row, index) => {
// 					opened[index] = { '.': '.' };
// 					row.entries.forEach((_item, i) => {
// 						opened[index][i] = { '.': '.' };
// 					});
// 				});
// 				return openedRows;
// 			})
// 		});
// 	};
// }

function expandBreakdown(level) {
	return entry => {
		if (level === 0) {
			return { '.': '.' };
		}

		return entry.entries.reduce((acc, val, index) => {
			acc[index] = expandBreakdown(level - 1)(val);
			return acc;
		}, {});
	};
}

export const setModelToFetchTableData = model =>
	O(model, {
		shouldFetchTableData: true,
		lastDataFetchId: (model.lastDataFetchId + 1) % 100
	});
