import { classNames } from '@/imports';
import { intents } from '@/intents';
import { model } from '@/model';
import { MetricSchema, SelectedMetric } from '@/model/metrics';
import { isCumulativeMetric, isExactMetricSelected, isLifetimeMetric, isMetricSelected } from '@/model/metrics/reducer';
import * as Reusable from '@/Reusable';
import { CumulativeMetricButtonPresentational } from '@components/MetricPicker/CumulativeMetricButtonPresentational/CumulativeMetricButtonPresentational';
import { LifetimeMetricButtonPresentational } from '@components/MetricPicker/LifetimeMetricButtonPresentational/LifetimeMetricButtonPresentational';
import { MetricAddButtonsContainer } from '@components/MetricPicker/MetricAddButtonsContainer/MetricAddButtonsContainer';
import { MetricAddCustomNumberButtonPresentational } from '@components/MetricPicker/MetricAddCustomNumberButtonPresentational/MetricAddCustomNumberButtonPresentational';
import { MetricNumberButtonPresentational } from '@components/MetricPicker/MetricNumberButtonPresentational/MetricNumberButtonPresentational';
import { MetricPickerContainer } from '@components/MetricPicker/MetricPickerContainer/MetricPickerContainer';
import { MetricPickerLayout } from '@components/MetricPicker/MetricPickerLayout/MetricPickerLayout';
import { MetricPickerLeftLayout } from '@components/MetricPicker/MetricPickerLeftLayout/MetricPickerLeftLayout';
import { MetricPickerTitle } from '@components/MetricPicker/MetricPickerTitle/MetricPickerTitle';
import { MetricPickerTitleAddition } from '@components/MetricPicker/MetricPickerTitle/MetricPickerTitleAddition/MetricPickerTitleAddition';
import { MetricPickerTopPresentational } from '@components/MetricPicker/MetricPickerTopPresentational/MetricPickerTopPresentational';
import { OpenMetricPickerButtonPresentational } from '@components/MetricPicker/OpenMetricPickerButtonPresentational/OpenMetricPickerButtonPresentational';
import { capitalize } from '@functions/capitalize';
import { DnDListPresentational } from '@reusable/DnDListPresentational/DnDListPresentational';
import { view } from 'funwork-js';
import * as React from 'react';

export const View = view(() => (
	<MetricPickerLayout
		top={
			<MetricPickerTopPresentational
				onClose={intents.CLOSE_METRIC_PICKER}
				onInput={intents.UPDATE_FILTER_OF_METRIC_SELECTION}
				inputValue={model.metricFilterInput}
			/>
		}
		left={<MetricPickerLeft />}
		right={<SelectedMetrics />}
		bottom={<AddCumulativeMetrics />}
	/>
));

export const OpenMetricPickerButton = view(() => {
	return (
		<OpenMetricPickerButtonPresentational
			onClick={intents.OPEN_METRIC_PICKER}
			metricsLength={model.selectedMetrics.length}
		/>
	);
});

const LifeTimeMetrics = view(() => {
	const icon = <Reusable.ViewIcon size="big" weight="solid" name="arrows-h" />;
	const title = <MetricPickerTitle icon={icon} title="Lifetime metrics" />;
	const content = <LifeTimeMetricButtons />;
	return <MetricPickerContainer title={title} content={content} />;
});

const LifeTimeMetricButtons = view(() => {
	return (
		<div className="flex flex-wrap">
			{Object.values(model.metricsSchema)
				.filter(isLifetimeMetric)
				.filter(metric =>
					metric.label.toLowerCase().includes(model.metricFilterInput.toLowerCase())
				)
				.map(item => {
					const isSelected = isMetricSelected({ value: item.value })(model.selectedMetrics);
					const onClick = () =>
						isSelected
							? intents.UNSELECT_METRIC({ value: item.value })
							: intents.SELECT_METRIC({ value: item.value });

					return (
						<LifetimeMetricButtonPresentational
							key={item.value}
							onClick={onClick}
							inverted={!isSelected}
							className="mr-2 mb-2"
						>
							{item.label}
						</LifetimeMetricButtonPresentational>
					);
				})}
		</div>
	);
});

const CumulativeMetrics = view(() => {
	const icon = <Reusable.ViewIcon size="big" weight="solid" name="arrow-to-right" />;
	const title = (
		<MetricPickerTitle
			icon={icon}
			title="Cumulative metrics"
			right={
				<MetricPickerTitleAddition
					onClick={() => intents.UNCHECK_ALL_METRICS()}
					content="unselect all cum. metrics"
				/>
			}
		/>
	);
	return <MetricPickerContainer title={title} content={<CumulativeMetricButtons />} />;
});

const CumulativeMetricButtons = view(() => {
	return (
		<div className="flex flex-wrap">
			{Object.values(model.metricsSchema)
				.filter(isCumulativeMetric)
				.filter(metric =>
					metric.label.toLowerCase().includes(model.metricFilterInput.toLowerCase())
				)
				.map((metric, index) => (
					<CumulativeMetricButton key={index} metric={metric} />
				))}
		</div>
	);
});

const CumulativeMetricButton = view(({ metric }: { metric: MetricSchema }) => {
	const isSelected = isMetricSelected({ value: metric.value, params: {} })(model.selectedMetrics);
	const isChecked = isMetricSelected({ value: metric.value, params: {} })(
		model.checkedCumulativeMetrics
	);
	const onClick = () =>
		isChecked
			? intents.UNCHECK_CUMULATIVE_METRIC({ value: metric.value, params: {} })
			: intents.CHECK_CUMULATIVE_METRIC({ value: metric.value, params: {} });
	const iconName = isChecked ? 'check-square' : 'square';

	const onRemove = () => intents.UNSELECT_CUMULATIVE_METRIC({ value: metric.value });

	const roundness = { selected: 'rounded-r-none', default: 'rounded' };
	const type = isSelected ? 'selected' : 'default';
	const classes = classNames('cursor-grab mr-1px w-full h-full', roundness[type]);

	return (
		<div className="center-v mr-2 mb-2 h-8">
			<CumulativeMetricButtonPresentational onClick={onClick} inverted={!isSelected} className={classes}>
				<Reusable.ViewIcon className="mr-2" name={iconName} size="normal" />
				{`DX ${metric.label}`}
			</CumulativeMetricButtonPresentational>
			{isSelected && (
				<CumulativeMetricButtonPresentational
					onClick={onRemove}
					inverted={!isSelected}
					className="override:p-2 rounded-l-none h-full"
				>
					<Reusable.ViewIcon name="times" weight="solid" />
				</CumulativeMetricButtonPresentational>
			)}
		</div>
	);
});

const SelectedMetrics = view(() => {
	const title = `${model.selectedMetrics.length} currently displayed metrics`;
	const content = <SelectedMetricButtons />;
	return <MetricPickerContainer title={title} content={content} isScrollable />;
});

const MetricPickerLeft = view(() => {
	const top = <LifeTimeMetrics />;
	const bottom = <CumulativeMetrics />;

	return <MetricPickerLeftLayout top={top} bottom={bottom} />;
});

const AddCumulativeMetrics = view(() => {
	const icon = <Reusable.ViewIcon name="check-square" size="large" />;
	const title = <MetricPickerTitle icon={icon} title="Add cumulative metrics" />;
	return <MetricPickerContainer title={title} content={<AddCumulativeMetricsContent />} />;
});

const AddCumulativeMetricsContent = view(() => {
	const options = Object.keys(model.addCumulativeMetricsOptions).map(item => ({
		text: capitalize(item),
		value: item
	}));
	const select = (
		<Reusable.ViewDropdown
			options={options}
			selectedItemClassName="center"
			value={model.selectedAddCumulativeMetricPreset}
			icon={<Reusable.ViewIcon name="chevron-down" size="nano" />}
			onChange={(_, value) => intents.SELECT_OPTION_FOR_ADDING_CUMULATIVE_METRICS(value)}
		/>
	);
	return <MetricAddButtonsContainer select={select} numbers={<MetricNumberButtons />} />;
});

const MetricNumberButtons = view(() => {
	const selected =
		model.addCumulativeMetricsOptions[model.selectedAddCumulativeMetricPreset];
	const isCustom = model.selectedAddCumulativeMetricPreset === 'custom';
	return (
		<div className="flex">
			<MetricAsteriskButton />
			{selected.map((option, index) => (
				<MetricNumberButton key={index} option={option} />
			))}
			{isCustom && <MetricAddCustomNumberButton />}
		</div>
	);
});

const MetricAddCustomNumberButton = view(() => {
	return <MetricAddCustomNumberButtonPresentational onAdd={intents.ADD_NUMBER_FOR_CUMULATIVE_METRICS_SELECTION} />;
});

const MetricNumberButton = view(({ option }: { option: number }) => {
	const generalMetricToExact = metric => ({ value: metric.value, params: { day_of_activity: option } });

	const isInverted = !model.checkedCumulativeMetrics.every(metric =>
		isExactMetricSelected(generalMetricToExact(metric))(model.selectedMetrics)
	);
	const isPartial =
		isInverted &&
		model.checkedCumulativeMetrics.some(metric =>
			isExactMetricSelected(generalMetricToExact(metric))(model.selectedMetrics)
		);
	const onClick = () => {
		if (!isInverted) {
			return intents.UNSELECT_METRICS(model.checkedCumulativeMetrics.map(generalMetricToExact));
		}
		return intents.SELECT_METRICS(model.checkedCumulativeMetrics.map(generalMetricToExact));
	};
	return (
		<MetricNumberButtonPresentational
			key={option}
			onClick={onClick}
			content={option}
			isInverted={isInverted}
			isPartial={isPartial}
		/>
	);
});

const MetricAsteriskButton = view(() => {
	const options =
		model.addCumulativeMetricsOptions[model.selectedAddCumulativeMetricPreset];
	const generalMetricToExact = metric => option => ({ value: metric.value, params: { day_of_activity: option } });

	const isInverted = !model.checkedCumulativeMetrics.every(metric =>
		options.every(option =>
			isExactMetricSelected(generalMetricToExact(metric)(option))(model.selectedMetrics)
		)
	);
	const isPartial =
		isInverted &&
		model.checkedCumulativeMetrics.some(metric =>
			options.some(option =>
				isExactMetricSelected(generalMetricToExact(metric)(option))(model.selectedMetrics)
			)
		);
	const onClick = () => {
		if (isPartial || !isInverted) {
			return intents.UNSELECT_METRICS(
				model.checkedCumulativeMetrics.reduce(
					(acc, val) => acc.concat(options.map(option => generalMetricToExact(val)(option))),
					[]
				)
			);
		}
		return intents.SELECT_METRICS(
			model.checkedCumulativeMetrics.map(metric => options.map(generalMetricToExact(metric))).flat()
		);
	};
	return (
		<MetricNumberButtonPresentational
			key="asteriks"
			onClick={onClick}
			content={<Reusable.ViewIcon name="asterisk" size="big" />}
			isInverted={isInverted}
			isPartial={isPartial}
		/>
	);
});

const SelectedMetricButtons = view(() => {
	if (model.selectedMetrics.length === 0) return null;

	return (
		<DnDListPresentational onDragEnd={intents.UPDATE_ORDER_OF_METRICS}>
			{model.selectedMetrics.map(metric => provided => (
				<SelectedMetricButton metric={metric} provided={provided} />
			))}
		</DnDListPresentational>
	);
});

const SelectedMetricButton = view(({ metric, provided }: { provided; metric: SelectedMetric }) => {
	const isCumulative = isCumulativeMetric(metric);
	const onRemove = () => intents.UNSELECT_METRIC(metric);
	const content = isCumulative ? `D${metric.params.day_of_activity} ${metric.label}` : metric.label;

	const Button = isCumulative ? CumulativeMetricButtonPresentational : LifetimeMetricButtonPresentational;

	return (
		<div
			className="center-v mb-2 h-8"
			ref={provided.innerRef}
			{...provided.dragHandleProps}
			{...provided.draggableProps}
		>
			<Button className="cursor-grab mr-1px w-full h-full rounded-r-none">
				<Reusable.ViewIcon className="mr-2" name="grip-vertical" weight="solid" />
				{content}
			</Button>
			<Button onClick={onRemove} className="override:p-2 rounded-l-none h-full">
				<Reusable.ViewIcon name="times" weight="solid" />
			</Button>
		</div>
	);
});
