import * as Reusable from '@/Reusable';
import { useOnClickOutside } from '@hooks/';
import { view } from 'funwork-js';
import React from 'react';
import { intents } from './intents';
import { model } from './model';

export const View = view(() => {
    const { user } = model;
	const [userSettingsOpened, setUserSettingsOpened] = React.useState(false);
	const popupRef = React.useRef(null);

	useOnClickOutside(popupRef, () => {
		setTimeout(() => {
			setUserSettingsOpened(false);
		}, 0);
	});

	return (
		<div className="relative">
			<div className="center-v">
				<div className="mr-4">
					<div>
						{user.name} {user.surname}
					</div>
					<div className="text-xxs opacity-half">{user.email}</div>
				</div>
				<div className="center">
					<ViewAvatar onClick={() => setUserSettingsOpened(true)} imgSrc={user.profilePictureURL} />
				</div>
			</div>
			<Reusable.Popup
				ref={popupRef}
				opened={userSettingsOpened}
				className="w-full border shadow-lg py-2 bg-white right-0 absolute rounded"
				arrowPosition="none"
			>
				<div className="hover:bg-primary p-2-4 cursor-pointer" onClick={intents.SIGN_USER_OUT}>
					Sign out
				</div>
			</Reusable.Popup>
		</div>
	);
});

const ViewAvatar = view(({ onClick, imgSrc }) => {
	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<img
			width="48px"
			height="48px"
			onClick={onClick}
			className="cursor-pointer rounded-full"
			src={imgSrc}
			alt="avatar"
		></img>
	);
});
