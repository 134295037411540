import * as Reusable from '@/Reusable';
import { cx } from 'emotion';
import * as React from 'react';

export const OpenMetricPickerButtonPresentational = ({ onClick, metricsLength }) => {
	const classes = cx(
		'open-metric-picker-button-presentational',
		'center',
		'rounded',
		'p-2-4',
		'bg-c-accent2',
		'hover:bg-c-accent2-darkened',
		'text-white',
		'cursor-pointer'
	);

	return (
		<div className={classes} onClick={onClick}>
			<Reusable.ViewIcon name="plus-square" className="mr-2" />
			<span>
				<b>{metricsLength}</b> Metrics
			</span>
		</div>
	);
};
