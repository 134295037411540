import { model } from '@/model';
import { view } from 'funwork-js';
import React from 'react';
import { classNames } from './imports';
import { intents } from './intents';
import { Form as FormType, Field } from './model/etl';
import * as Reusable from './Reusable';
import { ViewLoadingStateIcon } from './Async';

// -----------------------------------------------------------------------------------------
// FORM
// -----------------------------------------------------------------------------------------

export const Form = view(() => {
	return (
		<>
			<h1>{model.currentEtlForm.label}</h1>
			<Feed />
			{hr}
			<div className="flex children:mr-2 last-child:mr-0">
				<CreateConfigButton />
				<UpdateConfigButton />
				<RunOnceButton />
			</div>
		</>
	);
});

const UpdateConfigButton = view(() => {
	return (
		<button className="flex btn" onClick={intents.UPDATE_ETL_CONFIG}>
			Update
			{model.updateConfigState === 'notLoading' ? null : (
				<ViewLoadingStateIcon className="ml-2" state={model.updateConfigState} />
			)}
		</button>
	);
});

const CreateConfigButton = view(() => {
	return (
		<button className="flex btn" onClick={intents.CREATE_ETL_CONFIG}>
			Create 
			{model.createConfigState === 'notLoading' ? null : (
				<ViewLoadingStateIcon className="ml-2" state={model.createConfigState} />
			)}
		</button>
	);
});

const RunOnceButton = view(() => {
	return (
		<button className='flex btn' onClick={intents.RUN_ETL_CONFIG}>
			Run once
			{model.runConfigOnceState === 'notLoading' ? null : (
				<ViewLoadingStateIcon className="ml-2" state={model.runConfigOnceState} />
			)}
		</button>
	);
});

// -----------------------------------------------------------------------------------------
// FEED
// -----------------------------------------------------------------------------------------

const Feed = view(() => {
	return (
		<>
			{model.currentEtlForm.fields.map((field: Field) => {
				const { id, label, type, value } = field;

				if (field.type === 'select') {
					return (
						<div key={id} className={fieldClass}>
							<Label id={field.id} text={field.label}>
								<Reusable.ViewDropdown
									className="flex-grow p-2-4 border"
									selectedItemClassName="center-v w-full jc-between"
									icon={<Reusable.ViewIcon name="chevron-down" size="nano" />}
									onChange={(_, val) => {
										intents.UPDATE_FIELD({ id, value: val });
									}}
									value={value as string}
									defaultValue=""
									options={field.options}
								/>
							</Label>
						</div>
					);
				}
				if (field.type === 'multiselect') {
					return (
						<div key={id} className={fieldClass}>
							<Label id="date" text="Date">
								<Reusable.ViewDropdown
									multiple
									className="flex-grow p-2-4 border"
									selectedItemClassName="center-v w-full jc-between"
									icon={<Reusable.ViewIcon name="chevron-down" size="nano" />}
									onChange={(_, val) => {
										intents.UPDATE_FIELD({ id, value: val });
									}}
									value={value as string}
									defaultValue=""
									options={field.options}
								/>
							</Label>
						</div>
					);
				}

				return (
					<InputField
						key={id}
						type={field.type}
						inputClass={type !== 'checkbox' ? 'flex-grow' : undefined}
						id={id}
						value={value}
						text={label}
						onChange={(value: Field['value']) => (intents.UPDATE_FIELD({ id, value }), value)}
					/>
				);
			})}
		</>
	);
});

// -----------------------------------------------------------------------------------------
// VIEW UTILS
// -----------------------------------------------------------------------------------------

interface InputFieldProps<T extends string | number | boolean> extends Omit<Reusable.InputProps<T>, 'className'> {
	className?: string;
	labelClass?: string;
	inputClass?: string;
	text: string;
}

const InputField = view(
	React.forwardRef<HTMLInputElement, InputFieldProps<string | number | boolean>>(
		(
			{
				className = '',
				labelClass = '',
				inputClass = '',
				text,
				...inputProps
			}: InputFieldProps<string | number | boolean>,
			ref
		) => {
			const fieldClasses = classNames(className, 'center-v h-9 mb-2');
			const inputClasses = classNames(inputClass);

			return (
				<div className={fieldClasses}>
					<Label className={labelClass} id={inputProps.id} text={text}>
						<Reusable.ViewInput ref={ref} {...inputProps} className={inputClasses} />
					</Label>
				</div>
			);
		}
	)
);

const hr = <hr className="border w-full border-sidebar-font-dark" />;
const fieldClass = 'center-v h-9 mb-2';
const Label = view(({ className = '', id, text, children }) => {
	const classes = classNames(className, 'flex w-full');

	return (
		<label className={classes} htmlFor={id}>
			<div className="center-v w-48 mr-4">{text}</div>
			{children}
		</label>
	);
});
