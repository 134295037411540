import { MetricValue } from "@/model/metrics";

// export const flattenTableData = (
// 	entries: { [key: string]: string | number | null }[],
// 	breakdownsCount: number
// ): DiminishedRow[] => {
// 	if (entries.length === 0) return [];
// 	const paths = [];

// 	return entries.map(entry => {
// 		const breakdowns = getFirstNKeys(breakdownsCount, entry);
// 		const fields = getOffsetNKeys(breakdownsCount, entry);

// 		const name: Field = {
// 			key: { value: '_key' },
// 			value: Object.values(breakdowns)
// 				.reverse()
// 				.find(item => item !== 'rolledup') as string
// 		};

		
// 		return {
// 			fields: [name, ...Object.entries(fields).map(fieldEntryToField)],
// 			first: false,
// 			last: false,
// 			path: [0]
// 		};
// 	});
// };

export const flattenTableData = (entries, index = 0, path = [], acc = []): DiminishedRow[] => {
	if (entries.length === 0) return acc;
	return flattenTableData(
		entries.slice(1),
		index + 1,
		path,
		acc
			.concat({ path: path.concat(index), first: index === 0, last: entries.length === 1, fields: entries[0].fields })
			.concat(flattenTableData(entries[0].entries, 0, path.concat(index)))
	);
};

export const nestData = (
    breakdownsCount: number,
    rows: { [key: string]: string | number | null }[],
    entries: Entry[] = []
): Entry[] => {
    if (rows.length === 0) return entries;

    const row = rows[0];

    const breakdowns = Object.values(
        getFirstNKeys(breakdownsCount, row)
    ).filter(item => item !== "rolledup");
    const fields = Object.entries(getOffsetNKeys(breakdownsCount, row)).map(
        fieldEntryToField
    );

    const entry: Entry = {
        entries: [],
        fields: [
            { key: { value: "_key" }, value: breakdowns.reverse()[0] },
            ...fields
        ]
    };

    addToEntries(entries, entry, breakdowns);

    return nestData(breakdownsCount, rows.slice(1), entries);
};

const addToEntries = (
    entries: Entry[],
    entry: Entry,
    breakdowns: (string | number | null)[]
) => {
    let pointer = entries;
    for (const breakdown of breakdowns) {
        const index = entries.findIndex(
            item => item.fields[0].value === breakdown
        );
        if (index !== -1) {
            pointer = entries[index].entries;
        }
    }
    pointer.push(entry);
    return entries;
};

const getFirstNKeys = (
	n: number,
	object: { [key: string]: string | number | null }
): { [key: string]: string | number | null } => Object.fromEntries(Object.entries(object).slice(0, n));

const getOffsetNKeys = (
	n: number,
	object: { [key: string]: string | number | null }
): { [key: string]: string | number | null } => Object.fromEntries(Object.entries(object).slice(n));

const fieldEntryToField = (fieldEntry: [string, string | number | null]): Field => {
	return {
		key: getMetricValueOfCompositeString(fieldEntry[0]),
		value: fieldEntry[1]
	};
};

const getMetricValueOfCompositeString = (compositeString: string): MetricValue => {
	const checked = getCumulativeMetricMatch(compositeString);
	return checked
		? { value: checked[2], params: { day_of_activity: parseInt(checked[1], 10) } }
		: { value: compositeString };
};

const getCumulativeMetricMatch = (metric: string) => {
	return metric.match(/^d(\d+)_(.*)$/);
};

type Entry = {
    fields: Field[];
    entries: Entry[];
};