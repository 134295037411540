import { createIntents } from '@/model';
import { User } from '@/Session';

export const sessionIntents: SessionIntents = createIntents(
	(present): SessionIntents => ({
		UPDATE_USER: user => present({ type: 'UPDATE_USER', payload: user })
	})
);

export type SessionIntents = {
	UPDATE_USER: (user: User | undefined) => void;
};
