import React from 'react';
import { AsyncState, AsyncData } from '@/Async';
import {
	OrganizationField,
	createOrganizationField,
	OrganizationRole,
	Organization,
	createOrganizationOptions,
	organizationRoleToOption
} from './Organization';

export type EtlModel = {
	currentEtlForm: Form;
	runConfigOnceState: AsyncState;
	updateConfigState: AsyncState;
	createConfigState: AsyncState;
	organizationRoles: AsyncData<OrganizationRole[], string>;
	bQProjectAcceses: AsyncData<BQProject[], string>;
	appleAcceses: AsyncData<AppleAccess[], string>;
	appleFeeds: AsyncData<Apple[], string>;
};

export const etlModel = (): EtlModel => ({
	currentEtlForm: createEmptyForm(),
	runConfigOnceState: 'notLoading',
	updateConfigState: 'notLoading',
	createConfigState: 'notLoading',
	organizationRoles: { _tag: 'notLoading' },
	// Accesses
	bQProjectAcceses: { _tag: 'notLoading' },
	appleAcceses: { _tag: 'notLoading' },
	// Feeds
	appleFeeds: { _tag: 'notLoading' }
});

export type Item = Access | Feeds;
export type Access = BQProject | AppleAccess;
export type Feeds = Apple;

export type Form = EmptyForm | AccessForm | FeedForm;

type AccessForm =
	| BQProjectForm
	| AppleAccessForm
	| AppsFlyerAccessForm
	| FacebookAccessForm
	| GoogleAdsAccessForm
	| GoogleAdsCustomerAccessForm
	| GooglePlayAccessForm
	| IronSourceAccessForm
	| LibringAccessForm
	| UnityAccessForm;

export type FeedForm =
	| AppleForm
	| AppleScraperForm
	| AppsFlyerCampaignForm
	| AppsFlyerForm
	| FacebookAdsForm
	| FixerForm
	| GoogleAdwordsForm
	| GooglePlayForm
	| IronSourceForm
	| LibringForm
	| UnityAdsForm
	| UnityAdvertiserForm;

type EmptyForm = { type: ''; label: 'No form selected'; metadata?: Metadata; fields: [] };
export const createEmptyForm = (): EmptyForm => ({ type: '', label: 'No form selected', fields: [] });

type Metadata = {
	id: string;
	createdAt: Date;
	updatedAt: Date;
};

type BQDestination = {
	id: string;
	createdAt: Date;
	updatedAt: Date;
	organizationId: string;
	descriptiveName: string;
	bQProjects: BQProject['id'][];
	dataset: string;
	tablePrefix: string;
	gameId: string;
	tableSuffix: string;
};

type BQDestinationForm = {
	type: 'bQDestination';
	label: 'Big query destination';
	metadata?: Metadata;
	fields: [
		OrganizationField,
		DescriptiveNameField,
		BQProjectsField,
		DatasetField,
		TablePrefixField,
		GameIdField,
		TableSuffixField
	];
};

export const createBQDestinationForm = ({
	organizationField = createOrganizationField(),
	descriptiveNameField = createDescriptiveNameField(),
	bQProjectsField = createBQProjectsField(),
	datasetField = createDatasetField(),
	tablePrefixField = createTablePrefixField(),
	gameIdField = createGameIdField(),
	tableSuffixField = createTableSuffixField()
}: {
	organizationField?: OrganizationField;
	descriptiveNameField?: DescriptiveNameField;
	bQProjectsField?: BQProjectsField;
	datasetField?: DatasetField;
	tablePrefixField?: TablePrefixField;
	gameIdField?: GameIdField;
	tableSuffixField?: TableSuffixField;
} = {}): BQDestinationForm => ({
	type: 'bQDestination',
	label: 'Big query destination',
	fields: [
		organizationField,
		descriptiveNameField,
		bQProjectsField,
		datasetField,
		tablePrefixField,
		gameIdField,
		tableSuffixField
	]
});

export type BQProject = {
	id: string;
	createdAt: Date;
	updatedAt: Date;
	organization: Organization;
	descriptiveName: string;
	projectId: string;
	clientEmail: string;
	privateKey: string;
};

type BQProjectForm = {
	type: 'bQProject';
	label: 'Big query project';
	metadata?: Metadata;
	fields: [OrganizationField, DescriptiveNameField, ProjectIdField, ClientEmailField, PrivateKeyField];
};

export const createBQProjectForm = ({
	metadata,
	organizationField = createOrganizationField(),
	descriptiveNameField = createDescriptiveNameField(),
	projectIdField = createProjectIdField(),
	clientEmailField = createClientEmailField(),
	privateKeyField = createPrivateKeyField()
}: {
	metadata?: Metadata;
	organizationField?: OrganizationField;
	descriptiveNameField?: DescriptiveNameField;
	projectIdField?: ProjectIdField;
	clientEmailField?: ClientEmailField;
	privateKeyField?: PrivateKeyField;
} = {}): BQProjectForm => ({
	metadata,
	type: 'bQProject',
	label: 'Big query project',
	fields: [organizationField, descriptiveNameField, projectIdField, clientEmailField, privateKeyField]
});

export const bQProjectToForm = (
	{ organization, descriptiveName, projectId, clientEmail, privateKey, ...metadata }: BQProject,
	organizationRoles: OrganizationRole[]
): BQProjectForm =>
	createBQProjectForm({
		metadata,
		organizationField: createOrganizationField(
			organization.id,
			createOrganizationOptions(organizationRoles.map(organizationRoleToOption))
		),
		descriptiveNameField: createDescriptiveNameField(descriptiveName),
		projectIdField: createProjectIdField(projectId),
		clientEmailField: createClientEmailField(clientEmail),
		privateKeyField: createPrivateKeyField(privateKey)
	});

type IntegrationFeedParams = {
	id: string;
	organization: Organization;
	bqDestinations: BQDestination[];
	eligibleAt: string;
	enabled: boolean;
};

export type AppleAccess = {
	id: string;
	createdAt: Date;
	updatedAt: Date;
	descriptiveName: string;
	login: string;
	password: string;
	accountName: string;
};

type AppleAccessForm = {
	type: 'appleAcces';
	label: 'Apple access';
	metadata?: Metadata;
	fields: [DescriptiveNameField, LoginField, PasswordField, AccountNameField];
};
export const createAppleAccessForm = ({
	metadata,
	descriptiveNameField = createDescriptiveNameField(),
	loginField = createLoginField(),
	passwordField = createPasswordField(),
	accountNameField = createAccountNameField()
}: {
	metadata?: Metadata;
	descriptiveNameField?: DescriptiveNameField;
	loginField?: LoginField;
	passwordField?: PasswordField;
	accountNameField?: AccountNameField;
} = {}): AppleAccessForm => ({
	metadata,
	type: 'appleAcces',
	label: 'Apple access',
	fields: [descriptiveNameField, loginField, passwordField, accountNameField]
});

export const appleAccessToForm = ({ descriptiveName, login, password, accountName, ...metadata }: AppleAccess): AppleAccessForm =>
	createAppleAccessForm({
		metadata,
		descriptiveNameField: createDescriptiveNameField(descriptiveName),
		loginField: createLoginField(login),
		passwordField: createPasswordField(password),
		accountNameField: createAccountNameField(accountName)
	});

export type Apple = {
	id: string;
	createdAt: Date;
	updatedAt: Date;
	descriptiveName: string;
	params: IntegrationFeedParams;
	appleAccessId: AppleAccess['id'];
	daysInPast: number;
};

type AppleForm = {
	type: 'apple';
	label: 'Apple';
	metadata?: Metadata;
	fields: [OrganizationField, EligibleAtField, EnabledField, DescriptiveNameField, DaysInPastField];
};
export const createAppleForm = ({
	metadata,
	organizationField = createOrganizationField(),
	eligibleAtField = createEligibleAtField(),
	enabledField = createEnabledField(),
	descriptiveNameField = createDescriptiveNameField(),
	daysInPastField = createDaysInPastField()
}: {
	metadata?: Metadata;
	organizationField?: OrganizationField;
	eligibleAtField?: EligibleAtField;
	enabledField?: EnabledField;
	descriptiveNameField?: DescriptiveNameField;
	daysInPastField?: DaysInPastField;
} = {}): AppleForm => ({
	metadata,
	type: 'apple',
	label: 'Apple',
	fields: [organizationField, eligibleAtField, enabledField, descriptiveNameField, daysInPastField]
});

export const appleToForm = ({
	params: { organization, eligibleAt, enabled },
	descriptiveName,
	daysInPast,
	...metadata
}: Apple, organizationRoles: OrganizationRole[]): AppleForm =>
	createAppleForm({
		metadata,
		organizationField: createOrganizationField(
			organization.id,
			createOrganizationOptions(organizationRoles.map(organizationRoleToOption))
		),
		eligibleAtField: createEligibleAtField(eligibleAt),
		enabledField: createEnabledField(enabled),
		descriptiveNameField: createDescriptiveNameField(descriptiveName),
		daysInPastField: createDaysInPastField(daysInPast)
	});

type AppleScraperForm = {
	type: 'appleScraper';
	label: 'Apple Scraper';
	metadata?: Metadata;
	fields: [OrganizationField, EligibleAtField, EnabledField, DescriptiveNameField, DaysInPastField];
};
export const createAppleScraperForm = ({
	metadata,
	organizationField,
	eligibleAtField,
	enabledField,
	descriptiveNameField,
	daysInPastField
}: {
	metadata?: Metadata;
	organizationField: OrganizationField;
	eligibleAtField: EligibleAtField;
	enabledField: EnabledField;
	descriptiveNameField: DescriptiveNameField;
	daysInPastField: DaysInPastField;
}): AppleScraperForm => ({
	metadata,
	type: 'appleScraper',
	label: 'Apple Scraper',
	fields: [organizationField, eligibleAtField, enabledField, descriptiveNameField, daysInPastField]
});

type AppsFlyerAccessForm = {
	type: 'appsFlyerAccess';
	label: 'Apps flyer access';
	metadata?: Metadata;
	fields: [DescriptiveNameField, ApiTokenField, GameIdField];
};
export const createAppsFlyerAccessForm = ({
	metadata,
	descriptiveNameField,
	apiTokenField,
	gameIdField
}: {
	metadata?: Metadata;
	descriptiveNameField: DescriptiveNameField;
	apiTokenField: ApiTokenField;
	gameIdField: GameIdField;
}): AppsFlyerAccessForm => ({
	metadata,
	type: 'appsFlyerAccess',
	label: 'Apps flyer access',
	fields: [descriptiveNameField, apiTokenField, gameIdField]
});

type AppsFlyerCampaignForm = {
	type: 'appsFlyerCampaign';
	label: 'Apps flyer campaign';
	metadata?: Metadata;
	fields: [OrganizationField, EligibleAtField, EnabledField, DescriptiveNameField, DaysInPastField];
};
export const createAppsFlyerCampaignForm = ({
	metadata,
	organizationField,
	eligibleAtField,
	enabledField,
	descriptiveNameField,
	daysInPastField
}: {
	metadata?: Metadata;
	organizationField: OrganizationField;
	eligibleAtField: EligibleAtField;
	enabledField: EnabledField;
	descriptiveNameField: DescriptiveNameField;
	daysInPastField: DaysInPastField;
}): AppsFlyerCampaignForm => ({
	metadata,
	type: 'appsFlyerCampaign',
	label: 'Apps flyer campaign',
	fields: [organizationField, eligibleAtField, enabledField, descriptiveNameField, daysInPastField]
});

type AppsFlyerForm = {
	type: 'appsFlyer';
	label: 'Apps flyer';
	metadata?: Metadata;
	fields: [
		OrganizationField,
		EligibleAtField,
		EnabledField,
		DescriptiveNameField,
		DaysInPastField,
		ReportField,
		EventField
	];
};
export const createAppsFlyerForm = ({
	metadata,
	organizationField,
	eligibleAtField,
	enabledField,
	descriptiveNameField,
	daysInPastField,
	reportField,
	eventField
}: {
	metadata?: Metadata;
	organizationField: OrganizationField;
	eligibleAtField: EligibleAtField;
	enabledField: EnabledField;
	descriptiveNameField: DescriptiveNameField;
	daysInPastField: DaysInPastField;
	reportField: ReportField;
	eventField: EventField;
}): AppsFlyerForm => ({
	metadata,
	type: 'appsFlyer',
	label: 'Apps flyer',
	fields: [
		organizationField,
		eligibleAtField,
		enabledField,
		descriptiveNameField,
		daysInPastField,
		reportField,
		eventField
	]
});

type FacebookAccessForm = {
	type: 'facebookAccess';
	label: 'Facebook access';
	metadata?: Metadata;
	fields: [DescriptiveNameField, AccountIdField, AccessTokenField];
};
export const createFacebookAccessForm = ({
	metadata,
	descriptiveNameField,
	accountIdField,
	accessTokenField
}: {
	metadata?: Metadata;
	descriptiveNameField: DescriptiveNameField;
	accountIdField: AccountIdField;
	accessTokenField: AccessTokenField;
}): FacebookAccessForm => ({
	metadata,
	type: 'facebookAccess',
	label: 'Facebook access',
	fields: [descriptiveNameField, accountIdField, accessTokenField]
});

type FacebookAdsForm = {
	type: 'facebookAds';
	label: 'Facebook ads';
	metadata?: Metadata;
	fields: [OrganizationField, EligibleAtField, EnabledField, DescriptiveNameField, DaysInPastField, SnapshotField];
};
export const createFacebookAdsForm = ({
	metadata,
	organizationField,
	eligibleAtField,
	enabledField,
	descriptiveNameField,
	daysInPastField,
	snapshotField
}: {
	metadata?: Metadata;
	organizationField: OrganizationField;
	eligibleAtField: EligibleAtField;
	enabledField: EnabledField;
	descriptiveNameField: DescriptiveNameField;
	daysInPastField: DaysInPastField;
	snapshotField: SnapshotField;
}): FacebookAdsForm => ({
	metadata,
	type: 'facebookAds',
	label: 'Facebook ads',
	fields: [organizationField, eligibleAtField, enabledField, descriptiveNameField, daysInPastField, snapshotField]
});

type FixerForm = {
	type: 'fixer';
	label: 'Fixer';
	metadata?: Metadata;
	fields: [OrganizationField, EligibleAtField, EnabledField, DescriptiveNameField, DaysInPastField, CurrencyField];
};
export const createFixerForm = ({
	metadata,
	organizationField,
	eligibleAtField,
	enabledField,
	descriptiveNameField,
	daysInPastField,
	currencyField
}: {
	metadata?: Metadata;
	organizationField: OrganizationField;
	eligibleAtField: EligibleAtField;
	enabledField: EnabledField;
	descriptiveNameField: DescriptiveNameField;
	daysInPastField: DaysInPastField;
	currencyField: CurrencyField;
}): FixerForm => ({
	metadata,
	type: 'fixer',
	label: 'Fixer',
	fields: [organizationField, eligibleAtField, enabledField, descriptiveNameField, daysInPastField, currencyField]
});

type GoogleAdsAccessForm = {
	type: 'googleAdsAccess';
	label: 'Google adwords access';
	metadata?: Metadata;
	fields: [DescriptiveNameField, ClientIdField, ClientSecretField, DeveloperTokenField];
};
export const createGoogleAdsAccessForm = ({
	metadata,
	descriptiveNameField,
	clientIdField,
	clientSecretField,
	developerTokenField
}: {
	metadata?: Metadata;
	descriptiveNameField: DescriptiveNameField;
	clientIdField: ClientIdField;
	clientSecretField: ClientSecretField;
	developerTokenField: DeveloperTokenField;
}): GoogleAdsAccessForm => ({
	metadata,
	type: 'googleAdsAccess',
	label: 'Google adwords access',
	fields: [descriptiveNameField, clientIdField, clientSecretField, developerTokenField]
});

type GoogleAdsCustomerAccessForm = {
	type: 'googleAdsCustomerAccess';
	label: 'Google adwords customer access';
	metadata?: Metadata;
	fields: [DescriptiveNameField, CustomerAccIdField, LoginCustomerIdField, RefreshTokenField];
};
export const createGoogleAdsCustomerAccessForm = ({
	metadata,
	descriptiveNameField,
	customerAccIdField,
	loginCustomerIdField,
	refreshTokenField
}: {
	metadata?: Metadata;
	descriptiveNameField: DescriptiveNameField;
	customerAccIdField: CustomerAccIdField;
	loginCustomerIdField: LoginCustomerIdField;
	refreshTokenField: RefreshTokenField;
}): GoogleAdsCustomerAccessForm => ({
	metadata,
	type: 'googleAdsCustomerAccess',
	label: 'Google adwords customer access',
	fields: [descriptiveNameField, customerAccIdField, loginCustomerIdField, refreshTokenField]
});

type GoogleAdwordsForm = {
	type: 'googleAdwords';
	label: 'Google adwords';
	metadata?: Metadata;
	fields: [OrganizationField, EligibleAtField, EnabledField, DescriptiveNameField];
};
export const createGoogleAdwordsForm = ({
	metadata,
	organizationField,
	eligibleAtField,
	enabledField,
	descriptiveNameField
}: {
	metadata?: Metadata;
	organizationField: OrganizationField;
	eligibleAtField: EligibleAtField;
	enabledField: EnabledField;
	descriptiveNameField: DescriptiveNameField;
}): GoogleAdwordsForm => ({
	metadata,
	type: 'googleAdwords',
	label: 'Google adwords',
	fields: [organizationField, eligibleAtField, enabledField, descriptiveNameField]
});

type GooglePlayAccessForm = {
	type: 'googlePlayAccess';
	label: 'Google play access';
	metadata?: Metadata;
	fields: [
		DescriptiveNameField,
		SourceProjectIdField,
		SourceClientEmailField,
		SourcePrivateKeyField,
		BucketIdField,
		PackageIdentifierField
	];
};
export const createGooglePlayAccessForm = ({
	metadata,
	descriptiveNameField,
	sourceProjectIdField,
	sourceClientEmailField,
	sourcePrivateKeyField,
	bucketIdField,
	packageIdentifierField
}: {
	metadata?: Metadata;
	descriptiveNameField: DescriptiveNameField;
	sourceProjectIdField: SourceProjectIdField;
	sourceClientEmailField: SourceClientEmailField;
	sourcePrivateKeyField: SourcePrivateKeyField;
	bucketIdField: BucketIdField;
	packageIdentifierField: PackageIdentifierField;
}): GooglePlayAccessForm => ({
	metadata,
	type: 'googlePlayAccess',
	label: 'Google play access',
	fields: [
		descriptiveNameField,
		sourceProjectIdField,
		sourceClientEmailField,
		sourcePrivateKeyField,
		bucketIdField,
		packageIdentifierField
	]
});

type GooglePlayForm = {
	type: 'googlePlay';
	label: 'Google play';
	metadata?: Metadata;
	fields: [OrganizationField, EligibleAtField, EnabledField, DescriptiveNameField, DateField];
};
export const createGooglePlayForm = ({
	metadata,
	organizationField,
	eligibleAtField,
	enabledField,
	descriptiveNameField,
	dateField
}: {
	metadata?: Metadata;
	organizationField: OrganizationField;
	eligibleAtField: EligibleAtField;
	enabledField: EnabledField;
	descriptiveNameField: DescriptiveNameField;
	dateField: DateField;
}): GooglePlayForm => ({
	metadata,
	type: 'googlePlay',
	label: 'Google play',
	fields: [organizationField, eligibleAtField, enabledField, descriptiveNameField, dateField]
});

type IronSourceAccessForm = {
	type: 'ironSourceAccess';
	label: 'Iron source access';
	metadata?: Metadata;
	fields: [DescriptiveNameField, UsernameField, SecretKeyField, RefreshTokenField];
};
export const createIronSourceAccessForm = ({
	metadata,
	descriptiveNameField,
	usernameField,
	secretKeyField,
	refreshTokenField
}: {
	metadata?: Metadata;
	descriptiveNameField: DescriptiveNameField;
	usernameField: UsernameField;
	secretKeyField: SecretKeyField;
	refreshTokenField: RefreshTokenField;
}): IronSourceAccessForm => ({
	metadata,
	type: 'ironSourceAccess',
	label: 'Iron source access',
	fields: [descriptiveNameField, usernameField, secretKeyField, refreshTokenField]
});

type IronSourceForm = {
	type: 'ironSource';
	label: 'Iron source';
	metadata?: Metadata;
	fields: [OrganizationField, EligibleAtField, EnabledField, DescriptiveNameField, DaysInPastField, AppKeyField];
};
export const createIronSourceForm = ({
	metadata,
	organizationField,
	eligibleAtField,
	enabledField,
	descriptiveNameField,
	daysInPastField,
	appKeyField
}: {
	metadata?: Metadata;
	organizationField: OrganizationField;
	eligibleAtField: EligibleAtField;
	enabledField: EnabledField;
	descriptiveNameField: DescriptiveNameField;
	daysInPastField: DaysInPastField;
	appKeyField: AppKeyField;
}): IronSourceForm => ({
	metadata,
	type: 'ironSource',
	label: 'Iron source',
	fields: [organizationField, eligibleAtField, enabledField, descriptiveNameField, daysInPastField, appKeyField]
});

type LibringAccessForm = {
	type: 'libringAccess';
	label: 'Libring access';
	metadata?: Metadata;
	fields: [DescriptiveNameField, ApiTokenField];
};
export const createLibringAccessForm = ({
	metadata,
	descriptiveNameField,
	apiTokenField
}: {
	metadata?: Metadata;
	descriptiveNameField: DescriptiveNameField;
	apiTokenField: ApiTokenField;
}): LibringAccessForm => ({
	metadata,
	type: 'libringAccess',
	label: 'Libring access',
	fields: [descriptiveNameField, apiTokenField]
});

type LibringForm = {
	type: 'libring';
	label: 'Libring';
	metadata?: Metadata;
	fields: [
		OrganizationField,
		EligibleAtField,
		EnabledField,
		DescriptiveNameField,
		DaysInPastField,
		AppNameField,
		DataTypeField
	];
};
export const createLibringForm = ({
	metadata,
	organizationField,
	eligibleAtField,
	enabledField,
	descriptiveNameField,
	daysInPastField,
	appNameField,
	dataTypeField
}: {
	metadata?: Metadata;
	organizationField: OrganizationField;
	eligibleAtField: EligibleAtField;
	enabledField: EnabledField;
	descriptiveNameField: DescriptiveNameField;
	daysInPastField: DaysInPastField;
	appNameField: AppNameField;
	dataTypeField: DataTypeField;
}): LibringForm => ({
	metadata,
	type: 'libring',
	label: 'Libring',
	fields: [
		organizationField,
		eligibleAtField,
		enabledField,
		descriptiveNameField,
		daysInPastField,
		appNameField,
		dataTypeField
	]
});

type UnityAccessForm = {
	type: 'unityAccess';
	label: 'Unity access';
	metadata?: Metadata;
	fields: [DescriptiveNameField, ApiKeyField];
};
export const createUnityAccessForm = ({
	metadata,
	descriptiveNameField,
	apiKeyField
}: {
	metadata?: Metadata;
	descriptiveNameField: DescriptiveNameField;
	apiKeyField: ApiKeyField;
}): UnityAccessForm => ({
	metadata,
	type: 'unityAccess',
	label: 'Unity access',
	fields: [descriptiveNameField, apiKeyField]
});

type UnityAdsForm = {
	type: 'unityAds';
	label: 'Unity ads';
	metadata?: Metadata;
	fields: [OrganizationField, EligibleAtField, EnabledField, DescriptiveNameField, DaysInPastField];
};
export const createUnityAdsForm = ({
	metadata,
	organizationField,
	eligibleAtField,
	enabledField,
	descriptiveNameField,
	daysInPastField
}: {
	metadata?: Metadata;
	organizationField: OrganizationField;
	eligibleAtField: EligibleAtField;
	enabledField: EnabledField;
	descriptiveNameField: DescriptiveNameField;
	daysInPastField: DaysInPastField;
}): UnityAdsForm => ({
	metadata,
	type: 'unityAds',
	label: 'Unity ads',
	fields: [organizationField, eligibleAtField, enabledField, descriptiveNameField, daysInPastField]
});

type UnityAdvertiserForm = {
	type: 'unityAdvertiser';
	label: 'Unity advertiser';
	metadata?: Metadata;
	fields: [OrganizationField, EligibleAtField, EnabledField, DescriptiveNameField, DaysInPastField];
};
export const createUnityAdvertiserForm = ({
	metadata,
	organizationField,
	eligibleAtField,
	enabledField,
	descriptiveNameField,
	daysInPastField
}: {
	metadata?: Metadata;
	organizationField: OrganizationField;
	eligibleAtField: EligibleAtField;
	enabledField: EnabledField;
	descriptiveNameField: DescriptiveNameField;
	daysInPastField: DaysInPastField;
}): UnityAdvertiserForm => ({
	metadata,
	type: 'unityAdvertiser',
	label: 'Unity advertiser',
	fields: [organizationField, eligibleAtField, enabledField, descriptiveNameField, daysInPastField]
});

export type Field =
	| BQProjectsField
	| TablePrefixField
	| TableSuffixField
	| OrganizationField
	| EligibleAtField
	| EnabledField
	| DescriptiveNameField
	| UsernameField
	| DaysInPastField
	| DescriptiveNameField
	| AppNameField
	| DataTypeField
	| DatasetField
	| AppKeyField
	| ApiKeyField
	| ApiTokenField
	| AccessTokenField
	| SecretKeyField
	| RefreshTokenField
	| SourceProjectIdField
	| ProjectIdField
	| SourceClientEmailField
	| ClientEmailField
	| SourcePrivateKeyField
	| PrivateKeyField
	| BucketIdField
	| PackageIdentifierField
	| ClientIdField
	| ClientSecretField
	| DeveloperTokenField
	| CustomerAccIdField
	| LoginCustomerIdField
	| GameIdField
	| ReportField
	| EventField
	| AccountIdField
	| SnapshotField
	| CurrencyField
	| LoginField
	| PasswordField
	| AccountNameField
	| DateField;

type BQProjectsField = {
	id: 'bQProjectId';
	label: 'Big query project';
	type: 'multiselect';
	value: BQProjectOptions[number]['value'][];
	options: BQProjectOptions;
};
const createBQProjectsField = (
	value: BQProjectsField['options'][number]['value'][] = [],
	options: BQProjectOptions = [
		{
			text: 'Select a big query projects',
			value: '',
			label: <span className="text-grey">Select a big query projects</span>
		}
	]
): BQProjectsField => ({
	id: 'bQProjectId',
	label: 'Big query project',
	type: 'multiselect',
	value,
	options
});

type BQProjectOption = { text: string; value: string; label?: JSX.Element };
type BQProjectOptions = [{ text: string; value: string; label?: JSX.Element }, ...BQProjectOption[]];
const createBQProjectOptions = (options: BQProjectOption[] = []): BQProjectOptions => [
	{
		text: 'Select a big query projects',
		value: '',
		label: <span className="text-grey">Select a big query projects</span>
	},
	...options
];

type EligibleAtField = { id: 'cron'; label: 'Cron-like expression'; type: 'string'; value: string };
const createEligibleAtField = (value = ''): EligibleAtField => ({
	id: 'cron',
	label: 'Cron-like expression',
	type: 'string',
	value
});

type TablePrefixField = { id: 'tablePrefix'; label: 'Table prefix'; type: 'string'; value: string };
const createTablePrefixField = (value = ''): TablePrefixField => ({
	id: 'tablePrefix',
	label: 'Table prefix',
	type: 'string',
	value
});

type TableSuffixField = { id: 'tableSuffix'; label: 'Table suffix'; type: 'string'; value: string };
const createTableSuffixField = (value = ''): TableSuffixField => ({
	id: 'tableSuffix',
	label: 'Table suffix',
	type: 'string',
	value
});

type EnabledField = { id: 'enabled'; label: 'Enabled'; type: 'checkbox'; value: boolean };
const createEnabledField = (value = true): EnabledField => ({
	id: 'enabled',
	label: 'Enabled',
	type: 'checkbox',
	value
});

// type NameField = { id: 'name'; label: 'Name'; type: 'string'; value: string };
// const createNameField = (value = ''): NameField => ({ id: 'name', label: 'Name', type: 'string', value });

type UsernameField = { id: 'username'; label: 'Username'; type: 'string'; value: string };
const createUsernameField = (value = ''): UsernameField => ({
	id: 'username',
	label: 'Username',
	type: 'string',
	value
});

type DaysInPastField = { id: 'daysInPast'; label: 'Days in past'; type: 'number'; value: number };
const createDaysInPastField = (value = 2): DaysInPastField => ({
	id: 'daysInPast',
	label: 'Days in past',
	type: 'number',
	value
});

type DescriptiveNameField = { id: 'descriptiveName'; label: 'Descriptive name'; type: 'string'; value: string };
const createDescriptiveNameField = (value = ''): DescriptiveNameField => ({
	id: 'descriptiveName',
	label: 'Descriptive name',
	type: 'string',
	value
});

type AppNameField = { id: 'appName'; label: 'App name'; type: 'string'; value: string };
const createAppNameField = (value = ''): AppNameField => ({ id: 'appName', label: 'App name', type: 'string', value });

type DataTypeField = { id: 'dataType'; label: 'Data type'; type: 'string'; value: string };
const createDataTypeField = (value = ''): DataTypeField => ({
	id: 'dataType',
	label: 'Data type',
	type: 'string',
	value
});

type DatasetField = { id: 'dataset'; label: 'Dataset'; type: 'string'; value: string };
const createDatasetField = (value = ''): DatasetField => ({
	id: 'dataset',
	label: 'Dataset',
	type: 'string',
	value
});

type AppKeyField = { id: 'appKey'; label: 'App key'; type: 'string'; value: string };
const createAppKeyField = (value = ''): AppKeyField => ({ id: 'appKey', label: 'App key', type: 'string', value });

type ApiKeyField = { id: 'apiKey'; label: 'Api key'; type: 'string'; value: string };
const createApiKeyField = (value = ''): ApiKeyField => ({ id: 'apiKey', label: 'Api key', type: 'string', value });

type ApiTokenField = { id: 'apiToken'; label: 'Api token'; type: 'string'; value: string };
const createApiTokenField = (value = ''): ApiTokenField => ({
	id: 'apiToken',
	label: 'Api token',
	type: 'string',
	value
});

type AccessTokenField = { id: 'accessToken'; label: 'Access token'; type: 'string'; value: string };
const createAccessTokenField = (value = ''): AccessTokenField => ({
	id: 'accessToken',
	label: 'Access token',
	type: 'string',
	value
});

type SecretKeyField = { id: 'secretKey'; label: 'Secret key'; type: 'string'; value: string };
const createSecretKeyField = (value = ''): SecretKeyField => ({
	id: 'secretKey',
	label: 'Secret key',
	type: 'string',
	value
});

type RefreshTokenField = { id: 'refreshToken'; label: 'Refresh token'; type: 'string'; value: string };
const createRefreshTokenField = (value = ''): RefreshTokenField => ({
	id: 'refreshToken',
	label: 'Refresh token',
	type: 'string',
	value
});

type SourceProjectIdField = { id: 'sourceProjectId'; label: 'Source project Id'; type: 'string'; value: string };
const createSourceProjectIdField = (value = ''): SourceProjectIdField => ({
	id: 'sourceProjectId',
	label: 'Source project Id',
	type: 'string',
	value
});

type ProjectIdField = { id: 'projectId'; label: 'Project Id'; type: 'string'; value: string };
const createProjectIdField = (value = ''): ProjectIdField => ({
	id: 'projectId',
	label: 'Project Id',
	type: 'string',
	value
});

type SourceClientEmailField = { id: 'sourceClientEmail'; label: 'Source client email'; type: 'string'; value: string };
const createSourceClientEmailField = (value = ''): SourceClientEmailField => ({
	id: 'sourceClientEmail',
	label: 'Source client email',
	type: 'string',
	value
});

type ClientEmailField = { id: 'clientEmail'; label: 'Client email'; type: 'string'; value: string };
const createClientEmailField = (value = ''): ClientEmailField => ({
	id: 'clientEmail',
	label: 'Client email',
	type: 'string',
	value
});

type SourcePrivateKeyField = { id: 'sourcePrivateKey'; label: 'Source private key'; type: 'string'; value: string };
const createSourcePrivateKeyField = (value = ''): SourcePrivateKeyField => ({
	id: 'sourcePrivateKey',
	label: 'Source private key',
	type: 'string',
	value
});

type PrivateKeyField = { id: 'privateKey'; label: 'Private key'; type: 'string'; value: string };
const createPrivateKeyField = (value = ''): PrivateKeyField => ({
	id: 'privateKey',
	label: 'Private key',
	type: 'string',
	value
});

type BucketIdField = { id: 'bucketId'; label: 'Bucket Id'; type: 'string'; value: string };
const createBucketIdField = (value = ''): BucketIdField => ({
	id: 'bucketId',
	label: 'Bucket Id',
	type: 'string',
	value
});

type PackageIdentifierField = { id: 'packageIdentifier'; label: 'Package identifier'; type: 'string'; value: string };
const createPackageIdentifierField = (value = ''): PackageIdentifierField => ({
	id: 'packageIdentifier',
	label: 'Package identifier',
	type: 'string',
	value
});

type ClientIdField = { id: 'clientId'; label: 'Client Id'; type: 'string'; value: string };
const createClientIdField = (value = ''): ClientIdField => ({
	id: 'clientId',
	label: 'Client Id',
	type: 'string',
	value
});

type ClientSecretField = { id: 'clientSecret'; label: 'Client secret'; type: 'string'; value: string };
const createClientSecretField = (value = ''): ClientSecretField => ({
	id: 'clientSecret',
	label: 'Client secret',
	type: 'string',
	value
});

type DeveloperTokenField = { id: 'developerToken'; label: 'Developer token'; type: 'string'; value: string };
const createDeveloperTokenField = (value = ''): DeveloperTokenField => ({
	id: 'developerToken',
	label: 'Developer token',
	type: 'string',
	value
});

type CustomerAccIdField = { id: 'customerAccId'; label: 'Customer account Id'; type: 'string'; value: string };
const createCustomerAccIdField = (value = ''): CustomerAccIdField => ({
	id: 'customerAccId',
	label: 'Customer account Id',
	type: 'string',
	value
});

type LoginCustomerIdField = { id: 'loginCustomerId'; label: 'Login customer Id'; type: 'string'; value: string };
const createLoginCustomerIdField = (value = ''): LoginCustomerIdField => ({
	id: 'loginCustomerId',
	label: 'Login customer Id',
	type: 'string',
	value
});

type GameIdField = { id: 'gameId'; label: 'Game Id'; type: 'string'; value: string };
const createGameIdField = (value = ''): GameIdField => ({ id: 'gameId', label: 'Game Id', type: 'string', value });

type ReportField = { id: 'report'; label: 'Report type'; type: 'string'; value: string };
const createReportField = (value = ''): ReportField => ({ id: 'report', label: 'Report type', type: 'string', value });

type EventField = { id: 'event'; label: 'Event name'; type: 'string'; value: string };
const createEventField = (value = ''): EventField => ({ id: 'event', label: 'Event name', type: 'string', value });

type AccountIdField = { id: 'accountId'; label: 'Account Id'; type: 'string'; value: string };
const createAccountIdField = (value = ''): AccountIdField => ({
	id: 'accountId',
	label: 'Account Id',
	type: 'string',
	value
});

type SnapshotField = { id: 'snapshot'; label: 'Store as snapshot?'; type: 'checkbox'; value: boolean };
const createSnapshotField = (value = false): SnapshotField => ({
	id: 'snapshot',
	label: 'Store as snapshot?',
	type: 'checkbox',
	value
});

type CurrencyField = { id: 'currency'; label: 'Currency'; type: 'string'; value: string };
const createCurrencyField = (value = ''): CurrencyField => ({
	id: 'currency',
	label: 'Currency',
	type: 'string',
	value
});

type LoginField = { id: 'login'; label: 'Login'; type: 'string'; value: string };
const createLoginField = (value = ''): LoginField => ({ id: 'login', label: 'Login', type: 'string', value });

type PasswordField = { id: 'password'; label: 'Password'; type: 'password'; value: string };
const createPasswordField = (value = ''): PasswordField => ({
	id: 'password',
	label: 'Password',
	type: 'password',
	value
});

type AccountNameField = { id: 'accountName'; label: 'Account name'; type: 'string'; value: string };
const createAccountNameField = (value = ''): AccountNameField => ({
	id: 'accountName',
	label: 'Account name',
	type: 'string',
	value
});

type DateField = {
	id: 'date';
	label: 'Date';
	type: 'select';
	value: GoogleDateConstantOptions[number]['value'];
	options: GoogleDateConstantOptions;
};
const createDateField = (value = '' as GoogleDateConstantOptions[0]['value']): DateField => ({
	id: 'date',
	label: 'Date',
	type: 'select',
	value,
	options: createGoogleDateConstantOptions()
});

type GoogleDateConstantOptions = [
	{
		label: JSX.Element;
		text: 'Select date constant';
		value: '';
	},
	{ text: 'LAST_14_DAYS'; value: 'last_14_days' },
	{ text: 'LAST_30_DAYS'; value: 'last_30_days' },
	{ text: 'LAST_7_DAYS'; value: 'last_7_days' },
	{ text: 'LAST_BUSINESS_WEEK'; value: 'last_business_week' },
	{ text: 'LAST_MONTH'; value: 'last_month' },
	{ text: 'LAST_WEEK_MON_SUN'; value: 'last_week_mon_sun' },
	{ text: 'LAST_WEEK_SUN_SAT'; value: 'last_week_sun_sat' },
	{ text: 'THIS_MONTH'; value: 'this_month' },
	{ text: 'THIS_WEEK_MON_TODAY'; value: 'this_week_mon_today' },
	{ text: 'THIS_WEEK_SUN_TODAY'; value: 'this_week_sun_today' },
	{ text: 'TODAY'; value: 'today' },
	{ text: 'YESTERDAY'; value: 'yesterday' }
];

const createGoogleDateConstantOptions = (): GoogleDateConstantOptions => [
	{
		label: <span className="text-grey">Select date constant</span>,
		text: 'Select date constant',
		value: ''
	},
	{ text: 'LAST_14_DAYS', value: 'last_14_days' },
	{ text: 'LAST_30_DAYS', value: 'last_30_days' },
	{ text: 'LAST_7_DAYS', value: 'last_7_days' },
	{ text: 'LAST_BUSINESS_WEEK', value: 'last_business_week' },
	{ text: 'LAST_MONTH', value: 'last_month' },
	{ text: 'LAST_WEEK_MON_SUN', value: 'last_week_mon_sun' },
	{ text: 'LAST_WEEK_SUN_SAT', value: 'last_week_sun_sat' },
	{ text: 'THIS_MONTH', value: 'this_month' },
	{ text: 'THIS_WEEK_MON_TODAY', value: 'this_week_mon_today' },
	{ text: 'THIS_WEEK_SUN_TODAY', value: 'this_week_sun_today' },
	{ text: 'TODAY', value: 'today' },
	{ text: 'YESTERDAY', value: 'yesterday' }
];
