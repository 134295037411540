import * as React from 'react';
import { classNames } from './imports';
import { useOnClickOutside } from '@hooks/';
import { view } from 'funwork-js';
import { isMetricSelected } from './model/metrics/reducer';

// -----------------------------------------------------------------------------------------
// ICON
// -----------------------------------------------------------------------------------------

interface IconProps {
	weight?: 'solid' | 'regular' | 'light';
	name: string;
	size?: 'pico' | 'nano' | 'micro' | 'mili' | 'normal' | 'big' | 'large';
	onClick?: <T>(e: React.MouseEvent<HTMLElement, MouseEvent>) => T | void;
	className?: string;
}

export const ViewIcon = view(({ className, size = 'normal', weight = 'regular', name, onClick }: IconProps) => {
	const solidity = {
		solid: 'fas',
		regular: 'far',
		light: 'fal'
	};

	const classes = classNames(className, size, solidity[weight], `fa-${name}`, {
		'cursor-pointer': onClick
	});
	return <i className={classes} onClick={onClick} />;
});

// -----------------------------------------------------------------------------------------
// POPUP
// -----------------------------------------------------------------------------------------

interface PopupProps {
	opened: boolean;
	className?: string;
	arrowPosition?: 'top' | 'bottom' | 'none';
	children: string | JSX.Element;
}

export const Popup = React.forwardRef<HTMLDivElement, PopupProps>(
	({ opened, className, arrowPosition = 'bottom', children }, ref) => {
		if (!opened) return null;

		const classes = classNames(className, 'popup', `arrow-${arrowPosition}`);

		return (
			<div ref={ref} className={classes}>
				{children}
			</div>
		);
	}
);

// -----------------------------------------------------------------------------------------
// INPUT
// -----------------------------------------------------------------------------------------

export interface InputProps<T> {
	className?: string;
	value?: T;
	defaultValue?: T;
	onChange: (a: T) => T;
	onConfirm?: (a: T) => T;
	placeholder?: string;
	type?: 'string' | 'number' | 'date' | 'checkbox' | 'password';
	id?: string;
}

export const ViewInput = React.forwardRef<HTMLInputElement, InputProps<string | number | boolean>>(
	(
		{
			className,
			value = undefined,
			defaultValue = undefined,
			onChange,
			onConfirm,
			type = 'string',
			placeholder,
			id
		},
		ref
	) => {
		const classes = classNames(className, 'border focus:outline-input p-2-4');
		const [state, setState] = React.useState<string | number | boolean>(value || defaultValue || '');

		const hasValue = value !== undefined && value !== null;
		const parseInputEvent = e => {
			if (type === 'checkbox') return e.target.checked;
			if (type === 'number') return parseInt(e.target.value, 10) || '';
			return e.target.value || '';
		};

		const _onChange = e => {
			const val = parseInputEvent(e);
			const afterChange = onChange ? onChange(val) : val;
			if (!hasValue) {
				setState(afterChange);
			}
		};

		const _onKeyPress = e => {
			if (!onConfirm) return;
			if (e.key !== 'Enter') return;
			if (!hasValue) {
				const afterConfirmed = onConfirm(state);
				setState(afterConfirmed);
				return;
			}
			onConfirm(value);
		};

		return (
			<input
				className={classes}
				ref={ref}
				{...(type === 'checkbox'
					? hasValue
						? { checked: value as boolean }
						: { checked: state as boolean }
					: {})}
				{...(type !== 'checkbox'
					? hasValue
						? { value: value as string | number }
						: { value: state as string | number }
					: {})}
				onChange={_onChange}
				onKeyPress={_onKeyPress}
				placeholder={placeholder || `${type}...`}
				type={type}
				id={id}
			/>
		);
	}
);

// -----------------------------------------------------------------------------------------
// DROPDOWN
// -----------------------------------------------------------------------------------------

type DropdownProps = ({ multiple?: false } & SingleDropdownProps) | ({ multiple?: true } & MultiDropdownProps);

export const ViewDropdown = view(({ multiple = false, ...props }: DropdownProps) => {
	return multiple === true ? (
		<ViewMultiDropdown {...(props as MultiDropdownProps)} />
	) : (
		<ViewSingleDropdown {...(props as SingleDropdownProps)} />
	);
});

type Option<T> = {
	label?: JSX.Element;
	text: string;
	value: T;
};

type Options<T> = [Option<''>, ...Option<T>[]];

interface SingleDropdownProps {
	className?: string;
	menuClassName?: string;
	selectedItemClassName?: string;
	itemClassName?: string;
	activeItemClassName?: string;
	options: Options<string>;
	value?: string;
	defaultValue?: string;
	trigger?: JSX.Element;
	icon?: JSX.Element;
	onChange: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => any;
	onOpen?: () => any;
}

export const ViewSingleDropdown = view(
	({
		className,
		menuClassName,
		selectedItemClassName,
		itemClassName,
		activeItemClassName,
		options,
		value,
		defaultValue,
		trigger,
		onChange,
		onOpen,
		icon
	}: SingleDropdownProps) => {
		const [opened, setIsOpened] = React.useState(false);
		const [selectedItem, setSelectedItem] = React.useState(
			options.find(item => item.value === defaultValue || item.value === value || item.value === '') || options[0]
		);

		React.useEffect(() => {
			if (opened && onOpen) {
				onOpen();
			}
		}, [opened]);

		const getSelected = () => {
			const item = options.find(item => item.value === value);
			if (item) {
				return item.label || item.text;
			}
			return undefined;
		};

		const hasValue = value !== undefined && value !== null;

		const classes = classNames(className, 'dropdown-presentational', 'relative center-v jc-between cursor-pointer');
		const menuClasses = classNames(
			menuClassName,
			'text-main-font text-xs left-0 mt-2 w-full min-w-max-content cursor-auto absolute top-full bg-white border border-blue-pale-dark rounded z-1000'
		);
		const itemClasses = classNames(itemClassName, 'cursor-pointer p-3-4 min-w-max-content hover:bg-dropdown');
		const activeItemClasses = classNames(activeItemClassName, 'bg-dropdown');

		const menuRef = React.useRef(null);
		useOnClickOutside(menuRef, () => {
			setIsOpened(false);
		});

		return (
			<div ref={menuRef} className={classes} onClick={e => (e.stopPropagation(), setIsOpened(!opened))}>
				{trigger || (
					<div className={selectedItemClassName}>
						<div className="center-v">
							{hasValue ? getSelected() : selectedItem.label || selectedItem.text}
						</div>
						{icon && <div className="center-v ml-2">{icon}</div>}
					</div>
				)}
				{opened && (
					<div className={menuClasses}>
						{options.map(item => {
							const selected =
								!trigger && (hasValue ? item.value === value : item.value === selectedItem.value);
							const _itemClasses = classNames(itemClasses, {
								[activeItemClasses]: selected,
								'font-bold': selected
							});

							return (
								<div
									key={item.value}
									className={_itemClasses}
									onClick={e => {
										e.stopPropagation();
										if (!trigger && !value) {
											setSelectedItem(item);
										}
										onChange(e, item.value);
										setIsOpened(false);
									}}
								>
									{item.text}
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
);

interface MultiDropdownProps {
	className?: string;
	menuClassName?: string;
	selectedItemClassName?: string;
	itemClassName?: string;
	activeItemClassName?: string;
	options: Option<string>[];
	placeholder: { label: string | JSX.Element };
	value: string[];
	icon?: JSX.Element;
	onChange: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, values: string[]) => void;
	onOpen?: () => void;
	onItemClick?: (value: string) => void;
	onItemRemoveClick?: (value: string) => void;
}

export const ViewMultiDropdown = view<React.FunctionComponent<MultiDropdownProps>>(
	({
		className,
		menuClassName,
		selectedItemClassName,
		itemClassName,
		activeItemClassName,
		options,
		placeholder,
		value,
		onChange,
		onOpen,
		icon,
		onItemClick,
		onItemRemoveClick
	}) => {
		const [opened, setIsOpened] = React.useState(false);

		React.useEffect(() => {
			if (opened && onOpen) {
				onOpen();
			}
		}, [opened]);

		const getSelected = () => options.filter(item => value.includes(item.value));

		const classes = classNames(className, 'dropdown-presentational', 'relative center-v jc-between cursor-pointer');
		const menuClasses = classNames(
			menuClassName,
			'text-main-font text-xs left-0 mt-2 w-full min-w-max-content cursor-auto absolute top-full bg-white border border-blue-pale-dark rounded z-1000'
		);
		const itemClasses = classNames(itemClassName, 'cursor-pointer p-3-4 min-w-max-content hover:bg-dropdown');
		const activeItemClasses = classNames(activeItemClassName, 'bg-dropdown');

		const menuRef = React.useRef(null);
		useOnClickOutside(menuRef, () => {
			setIsOpened(false);
		});

		return (
			<div ref={menuRef} className={classes} onClick={e => (e.stopPropagation(), setIsOpened(!opened))}>
				<div className={selectedItemClassName}>
					<div className="center-v text-xs children:mr-2 last-child:mr-0">
						{getSelected().length === 0
							? placeholder.label
							: getSelected().map((item, index) => (
									<div
										className="center-v rounded p-3/2-2 bg-primary"
										onClick={() => onItemClick && onItemClick(item.value)}
										key={index}
									>
										<div className="mr-2">{item.text || item.value}</div>
										<ViewIcon
											onClick={() => onItemRemoveClick && onItemRemoveClick(item.value)}
											name="times"
											size="mili"
										/>
									</div>
							  ))}
					</div>
					{icon && <div className="center-v ml-2">{icon}</div>}
				</div>
				{opened && (
					<div className={menuClasses}>
						{options.map(item => {
							const selected = value.includes(item.value);
							const _itemClasses = classNames(itemClasses, {
								[activeItemClasses]: selected,
								'font-bold': selected
							});

							return (
								<div
									key={item.value}
									className={_itemClasses}
									onClick={e => {
										e.stopPropagation();
										onChange(e, [...value, item.value]);
									}}
								>
									{item.text}
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
);
