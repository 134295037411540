import { classNames } from '@/imports';
import { intents } from '@/intents';
import { unit } from '@functions/crocks';
import { OpenMetricPickerButton } from '@/Page/Home/Metrics';
import * as Reusable from '@/Reusable';
import { view } from 'funwork-js';
import * as React from 'react';

export const View = view(() => {
	const classes = classNames(
		'table-controls',
		'.h-screen-control',
		'text-xs',
		'bg-white',
		'flex',
		'ai-center',
		'p-2-4',
		'border border-sidebar-font-light'
	);

	return (
		<div className={classes}>
			<div className="mr-4">
				<OpenMetricPickerButton />
			</div>
			<div className="mr-4">
				<ViewFlat />
			</div>
			<div className="mr-4">
				<CollapseAll />
			</div>
			<div className="mr-4">
				<ResetSorting />
			</div>
			<LoadAlmostAll />
		</div>
	);
});

const ViewFlat = view(() => {
	return <ViewIconText icon={{ name: 'table', weight: 'light' }} text="View flat" />;
});

const CollapseAll = view(() => {
	return <ViewIconText onClick={intents.COLLAPSE_ALL_ROWS} icon={{ name: 'chevron-double-up' }} text="Collapse all" />;
});

const ResetSorting = view(() => {
	return <ViewIconText onClick={intents.REMOVE_ALL_SORTINGS} icon={{ name: 'sort-alpha-down' }} text="Reset sorting" />;
});

const LoadAlmostAll = view(() => {
	return <ViewIconText onClick={intents.FETCH_ALMOST_ALL_TABLE_DATA} icon={{ name: 'poo-storm' }} text="Load almost all" />;
});

const ViewIconText = view(({ icon, text, onClick = unit, className = '' }) => {
	const classes = classNames(className, 'center', 'cursor-pointer');

	return (
		<div className={classes} onClick={onClick}>
			<Reusable.ViewIcon className="mr-2" {...icon} />
			<span>{text}</span>
		</div>
	);
});
