import { createSheet, deleteFile, exportAnalyticsData, saveCsvToSheets } from '@/Api';
import { classNames } from '@/imports';
import { intents } from '@/intents';
import { metricToDropdown } from '@/Metrics';
import { model } from '@/model';
import { prepareFetchArguments } from '@/model/reducer';
import { getHeadOfSplit } from '@/model/utils';
import * as Reusable from '@/Reusable';
import * as SavedView from '@/SavedView';
import { DimensionFilterRow } from '@/_components/DimensionFilterRow';
import { FilterRowOfRawFilters } from '@/_components/FilterRowOfRawFilters';
import { DimensionCategoryLabelPresentational } from '@components/Dimensions/DimensionCategoryLabelPresentational/DimensionCategoryLabelPresentational';
import { DimensionSortingRowPresentational } from '@components/Dimensions/DimensionSortingRowPresentational/DimensionSortingRowPresentational';
import { DimensionSortingsDropdownPresentational } from '@components/Dimensions/DimensionSortingsDropdownPresentational/DimensionSortingsDropdownPresentational';
import { SidebarDropdownPresentational } from '@components/Sidebar/SidebarDropdownPresentational';
import { SidebarItemPresentational } from '@components/Sidebar/SidebarItemPresentational';
import { Async, concat, equals, find, getProp, map, not, pipe, propEq, unit } from '@functions/crocks';
import { pickByFunctionOnValue } from '@functions/pickByFunctionOnValue';
import { render } from '@functions/render';
import { useOnClickOutside } from '@hooks/';
import { DnDListPresentational } from '@reusable/DnDListPresentational/DnDListPresentational';
import { InputWithConfirmationPresentational } from '@reusable/InputWithConfirmationPresentational/InputWithConfirmationPresentational';
import { LabelPresentational } from '@reusable/LabelPresentational/LabelPresentational';
import { MiniLabelPresentational } from '@reusable/MiniLabelPresentational/MiniLabelPresentational';
import { view } from 'funwork-js';
import * as React from 'react';
import * as Views from '@/model/views';
import { AsyncState } from '@/Async';

// -----------------------------------------------------------------------------------------
// MODEL
// -----------------------------------------------------------------------------------------

export const View = view(({ Section }) => {
	return (
		<>
			<Section>
				<SavedViewsSection />
			</Section>
			<Section>
				<RawFiltersSection />
			</Section>
			<Section>
				<DimensionsSection />
			</Section>
		</>
	);
});

export const SavedViewsSection = view(() => {
	return (
		<div>
			<LabelPresentational
				icon={<Reusable.ViewIcon className="w-5" name="eye" />}
				label="Views"
				onChevron={intents.TOGGLE_VIEWS_OF_SIDEBAR}
				opened={model.isViewsOpened}
				empty={false}
			/>
			{model.isViewsOpened && (
				<>
					<LabelPresentational
						className="pl-8"
						icon={<Reusable.ViewIcon className="w-5" name="sitemap" />}
						onChevron={intents.TOGGLE_ORGANIZATION_VIEWS_OF_SIDEBAR}
						empty={false}
						opened={model.isOrganizationViewsOpened}
						label={<div className="text-sidebar-font-dark">Organization</div>}
					></LabelPresentational>
					{model.isOrganizationViewsOpened && (
						<>
							<LabelPresentational
								className="pl-10"
								icon={<Reusable.ViewIcon className="w-5" name="running" />}
								onChevron={intents.TOGGLE_ORGANIZATION_ACTIVE_VIEWS_OF_SIDEBAR}
								empty={false}
								opened={model.isOrganizationActiveViewsOpened}
								label={<div className="text-sidebar-font-dark">Active</div>}
							></LabelPresentational>
							{model.isOrganizationActiveViewsOpened && (
								<div className="overflow-overlay max-h-38 sidebar-dropdown-scroll">
									{model.activeViews.map(view => (
										<ViewActiveView key={view.id} view={view} />
									))}
								</div>
							)}
							<LabelPresentational
								className="pl-10"
								icon={<Reusable.ViewIcon className="w-5" name="archive" />}
								onChevron={intents.TOGGLE_ORGANIZATION_ARCHIVED_VIEWS_OF_SIDEBAR}
								empty={false}
								opened={model.isOrganizationArchivedViewsOpened}
								label={<div className="text-sidebar-font-dark">Archived</div>}
							></LabelPresentational>
							{model.isOrganizationArchivedViewsOpened && (
								<div className="overflow-overlay max-h-38 sidebar-dropdown-scroll">
									{model.archivedViews.map(view => (
										<ViewArchivedView key={view.id} view={view} />
									))}
								</div>
							)}
						</>
					)}
					<LabelPresentational
						className="pl-8"
						icon={<Reusable.ViewIcon className="w-5" name="user" />}
						onChevron={intents.TOGGLE_USER_VIEWS_OF_SIDEBAR}
						empty={false}
						opened={model.isUserViewsOpened}
						label={<ViewUserViewsLabel />}
					></LabelPresentational>
					{model.isUserViewsOpened && (
						<div className="overflow-overlay max-h-38 sidebar-dropdown-scroll">
							{!model.user || !model.user.views || model.user.views.length === 0
								? null
								: model.user.views.map(view => <ViewSavedView key={view.id} view={view} />)}
							{/* {!model.user || !model.user.views || model.user.views.length === 0 ? null : model.user
								.chain(getProp('views'))
								.map(map(view => <ViewSavedView key={view.id} view={view} />))
								.option(null)} */}
						</div>
					)}
				</>
			)}
		</div>
	);
});

export const RawFiltersSection = view(() => {
	const opened = model.isRawFiltersSectionOpened;

	return (
		<div className="dimension-filters-section">
			<LabelPresentational
				className="px4"
				icon={<Reusable.ViewIcon name="filter" />}
				label={<AddDimensionFilter />}
				onChevron={intents.TOGGLE_DIMENSION_FILTERS_OF_SIDEBAR}
				opened={opened}
				empty={Object.entries(model.rawFilters).length === 0}
			/>
			{opened && <FilterRowOfRawFiltersList />}
		</div>
	);
});

const AddDimensionFilterPresentational = view(({ options, onChange }) => (
	<SidebarDropdownPresentational
		options={options}
		onChange={onChange}
		trigger={
			<MiniLabelPresentational text="DIMENSION FILTERS" icon={<Reusable.ViewIcon name="plus" size="nano" />} />
		}
	/>
));

const AddDimensionFilterContainer = props => ({
	...props,
	options: model.rawFieldsSchema.map(item => ({ value: item.value, text: item.label })),
	onChange: (_, dimension) => {
		intents.CREATE_RAW_FILTER({ dimension });
	}
});

const AddDimensionFilter = view(
	pipe(
		AddDimensionFilterContainer,
		render(AddDimensionFilterPresentational)
	)
);

const FilterRowOfRawFiltersList = view(() => (
	<div className="dimension-filters-row-list">
		{model.rawFilters.map((item, index) => (
			<FilterRowOfRawFilters key={`${item.dimension}-${item.operator}-${index}`} {...item} />
		))}
	</div>
));

export const DimensionsSection = view(() => {
	const opened = model.isDimensionsSectionOpened;

	return (
		<div className="dimensions-section">
			<LabelPresentational
				className="mb-2 px-6"
				icon={<Reusable.ViewIcon name="layer-group" weight="light" />}
				label={<AddDimension />}
				onChevron={() => {
					intents.TOGGLE_DIMENSIONS_OF_SIDEBAR();
				}}
				opened={opened}
				empty={model.breakdowns.allIds.length < 2}
			/>
			{opened && <DimensionList />}
		</div>
	);
});

const AddDimensionPresentational = view(({ options, onChange }) => (
	<SidebarDropdownPresentational
		options={options}
		onChange={onChange}
		trigger={<MiniLabelPresentational text="DIMENSIONS" icon={<Reusable.ViewIcon name="plus" size="nano" />} />}
	/>
));

const AddDimensionContainer = props => ({
	...props,
	options: model.breakdownsSchema
		.filter(option => !model.breakdowns.allIds.find(breakdown => breakdown === option.value))
		.map(item => ({
			text: item.label,
			value: item.value
		})),

	onChange: (_, value) => {
		intents.ADD_DIMENSION(value);
	}
});

const AddDimension = view(
	pipe(
		AddDimensionContainer,
		render(AddDimensionPresentational)
	)
);

const DimensionDnDContainer = props => ({
	...props,
	onDragEnd: onDragEnd(intents.UPDATE_ORDER_OF_DIMENSIONS)
});

const DimensionsContainer = props => ({
	...props,
	children: model.breakdowns.allIds
		.filter(not(equals('cohort')))
		.map(breakdown => provided => <Dimension key={breakdown} breakdown={breakdown} provided={provided} />)
});

const DimensionListContainer = pipe(
	DimensionDnDContainer,
	DimensionsContainer
);

const DimensionList = view(
	pipe(
		DimensionListContainer,
		props => {
			return (
				<>
					<DnDListPresentational {...props} />
					{find(equals('cohort'))(model.breakdowns.allIds)
						.map(breakdown => <Dimension key={breakdown} breakdown={breakdown} />)
						.option(null)}
				</>
			);
		}
	)
);

const Dimension = view(({ breakdown, provided }: { breakdown; provided? }) => {
	const [isDimensionOpened, seDimensiontIsOpened] = React.useState(true);
	const [isSortingOpened, setIsSortingOpened] = React.useState(true);
	const [isFilterOpened, setIsFilterOpened] = React.useState(true);

	const { innerRef = null, draggableProps = {}, dragHandleProps = {} } = provided || {};

	const index = model.breakdowns.allIds.findIndex(equals(breakdown));
	const options = model.breakdownsSchema
		.filter(
			option =>
				option.value === model.breakdowns.allIds[index] ||
				!model.breakdowns.allIds.find(breakdown => breakdown === option.value)
		)
		.map(item => ({
			text: item.label,
			value: item.value,
			label: <div className="ellipsis max-w-24">{item.label}</div>
		}));

	return (
		<div ref={innerRef} {...draggableProps}>
			<ViewDimensionLabel
				dragHandleProps={dragHandleProps}
				level={index + 1}
				filter={
					<SidebarDropdownPresentational
						{...FiltersDropDownContainer({ breakdownId: breakdown })}
						trigger={<Reusable.ViewIcon name="filter" weight="solid" size="micro" />}
					/>
				}
				sort={
					<SidebarDropdownPresentational
						{...SortingDropDownContainer({ breakdownId: breakdown })}
						trigger={<Reusable.ViewIcon name="sort" weight="solid" size="mili" />}
					/>
				}
				onExpand={e => (e.stopPropagation(), intents.EXPAND_ROWS_OF_DIMENSION(index))}
				onExport={e => (e.stopPropagation(), intents.EXPORT_TABLE_AT_DIMENSION(index))}
				onRemove={e => (e.stopPropagation(), intents.REMOVE_DIMENSION(index))}
				onChevron={() => seDimensiontIsOpened(!isDimensionOpened)}
				empty={!breakdownHasSortings(breakdown) && !breakdownHasFilters(breakdown)}
				opened={isDimensionOpened}
				select={
					<SidebarDropdownPresentational
						options={options}
						value={model.breakdowns.allIds[index]}
						onChange={(_, value) => intents.UPDATE_DIMENSION(index)(value)}
					/>
				}
			/>
			{isDimensionOpened && (
				<>
					{breakdownHasSortings(breakdown) && (
						<>
							<SortingLabel
								breakdownId={breakdown}
								opened={isSortingOpened}
								onToggle={() => setIsSortingOpened(!isSortingOpened)}
							/>
							{isSortingOpened && <DimensionSortingRowList breakdownId={breakdown} />}
						</>
					)}
					{breakdownHasFilters(breakdown) && (
						<>
							<FiltersLabel
								breakdownId={breakdown}
								opened={isFilterOpened}
								onToggle={() => setIsFilterOpened(!isFilterOpened)}
							/>
							{isFilterOpened && <FilterRowOfDimensionList breakdownId={breakdown} />}
						</>
					)}
				</>
			)}
		</div>
	);
});

const SortingLabelContainer = props => ({
	...props,
	icon: <Reusable.ViewIcon name="sort" weight="solid" size="nano" />,
	text: 'sorting',
	className: 'mb-2'
});

const SortingDropDownContainer = props => ({
	...props,
	onChange: (_, value) => intents.UPDATE_SORTING_OF_DIMENSION(props.breakdownId)(value)('ASC'),
	options: [{ text: 'Name', value: '_key' }].concat(model.selectedMetrics.map(metricToDropdown)).filter(
		metric =>
			!getProp(props.breakdownId)(model.orderings.byIds)
				.chain(find(ordering => ordering.metric === metric.value))
				.option(false)
	)
});

const FiltersLabelContainer = props => ({
	...props,
	icon: <Reusable.ViewIcon name="filter" weight="solid" size="nano" />,
	text: 'filters',
	className: 'mb-2'
});

const FiltersDropDownContainer = props => ({
	...props,
	onChange: (e, value) => (
		e.stopPropagation(),
		intents.UPDATE_FILTER_PREDICATE_OF_DIMENSION(props.breakdownId)(value)({
			...getDefaultOperator(value)
		})
	),
	options: model.selectedMetrics.map(metricToDropdown)
});

const SortingLabel = view(
	pipe(
		SortingDropDownContainer,
		props => ({ ...props, onAdd: props.onChange }),
		SortingLabelContainer,
		render(DimensionCategoryLabelPresentational)
	)
);

const FiltersLabel = view(
	pipe(
		FiltersDropDownContainer,
		props => ({ ...props, onAdd: props.onChange }),
		FiltersLabelContainer,
		render(DimensionCategoryLabelPresentational)
	)
);

const DimensionSortingRowContainer = props => ({
	...props,
	activeSort: model.orderings.byIds[props.breakdownId][props.index].order,
	onSortUp: () => intents.UPDATE_SORTING_OF_DIMENSION(props.breakdownId)(props.metric)('ASC'),
	onSortDown: () => intents.UPDATE_SORTING_OF_DIMENSION(props.breakdownId)(props.metric)('DESC'),
	isMovePriorityUpAvailable: props.index === 0,
	onRemove: () => intents.REMOVE_SORTING_OF_DIMENSION(props.breakdownId)(props.metric),
	metricsDropdown: view(
		pipe(
			DimensionSortingsDropdownContainer,
			render(DimensionSortingsDropdownPresentational)
		)(props)
	)
});

const DimensionSortingsDropdownContainer = props => ({
	...props,
	options: [{ text: 'Name', value: '_key' }]
		.concat(model.selectedMetrics.map(metricToDropdown))
		.filter(
			metric =>
				!model.orderings.byIds[props.breakdownId].find(ordering => ordering.metric === metric.value) ||
				model.orderings.byIds[props.breakdownId][props.index].metric === metric.value
		),
	value: model.orderings.byIds[props.breakdownId][props.index].metric,
	onChange: (_, value) => {
		intents.UPDATE_SORTING_METRIC_OF_DIMENSION(props.breakdownId)(
			model.orderings.byIds[props.breakdownId][props.index].metric
		)(value);
	}
});

const DimensionSortingRow = view(
	pipe(
		DimensionSortingRowContainer,
		render(DimensionSortingRowPresentational)
	)
);

const DimensionSortingRowListContainer = props => {
	return {
		...props,
		items: model.orderings.byIds[props.breakdownId].map((item, index) => (
			<DimensionSortingRow key={index} breakdownId={props.breakdownId} index={index} metric={item.metric} />
		))
	};
};

const DimensionSortingRowList = view(props =>
	getProp(props.breakdownId)(model.orderings.byIds)
		.map(_metrics => DimensionSortingRowListContainer({ breakdownId: props.breakdownId }))
		.map(props => <div>{props.items}</div>)
		.option(null)
);

const FilterRowOfDimensionList = view(props => (
	<div>
		{Object.entries(
			pickByFunctionOnValue(predicate => predicate.breakdown === props.breakdownId)(model.predicates.byIds)
		).map(([id, predicate]) => (
			<DimensionFilterRow key={id} {...predicate} />
		))}
	</div>
));

const onDragEnd = dndAction => e => {
	const { source, destination } = e;
	if (!destination) return;
	if (source.index === destination.index) return;
	dndAction(source.index)(destination.index);
};

const getDefaultOperator = metric =>
	find(item => item.value === metric)(model.selectedMetrics)
		.map(item => item.predicates.operators[0])
		.option({});

const breakdownHasSortings = breakdownId => model.orderings.allIds.includes(breakdownId);
const breakdownHasFilters = breakdownId =>
	model.predicates.allIds.some(item => getHeadOfSplit('-')(item) === breakdownId);

// -----------------------------------------------------------------------------------------
// VIEW
// -----------------------------------------------------------------------------------------

interface ViewDimensionLabelProps {
	level?: number;
	select: JSX.Element;
	empty: boolean;
	opened: boolean;
	filter: JSX.Element;
	sort: JSX.Element;
	onExpand;
	onExport;
	onRemove;
	onChevron;
	dragHandleProps?;
	gripClassName?: string;
}

export const ViewDimensionLabel = ({
	level,
	select,
	empty,
	opened,
	filter,
	sort,
	onExpand,
	onExport,
	onRemove,
	onChevron,
	dragHandleProps,
	gripClassName
}: ViewDimensionLabelProps) => {
	const gripClasses = classNames(gripClassName, 'mr-2', `c-level${level}`);
	const chevronClass = classNames('cursor-pointer, transition-03', { '-rotateZ-180': opened });

	const icon = empty ? (
		<Reusable.ViewIcon className="text-sidebar-font-dark" name="circle" weight="solid" size="pico" />
	) : (
		<Reusable.ViewIcon className={chevronClass} name="chevron-down" size="nano" onClick={onChevron} />
	);

	const chevronClick = () => {
		if (!empty) onChevron();
	};

	return (
		<SidebarItemPresentational
			onClick={chevronClick}
			onIconClick={chevronClick}
			className="dimension-label-presentational mb-2 cursor-auto"
			icon={icon}
		>
			<div className="center pl-8 w-full">
				<div className="content center-v jc-between flex-grow">
					<div className="center-v uppercase">
						<div className={gripClasses} {...dragHandleProps}>
							<Reusable.ViewIcon name="grip-vertical" weight="solid" />
						</div>
						{select}
					</div>
					<div className="right center-v">
						<div className="mr-2">{filter}</div>
						<div className="mr-2">{sort}</div>
						<Reusable.ViewIcon
							className="mr-2 zoom"
							name="stream"
							weight="solid"
							size="micro"
							onClick={onExpand}
						/>
						<ExportTablePopup onClick={onExport} level={level} />
						<Reusable.ViewIcon
							className="zoom"
							name="trash"
							weight="solid"
							size="micro"
							onClick={onRemove}
						/>
					</div>
				</div>
			</div>
		</SidebarItemPresentational>
	);
};

const ExportTablePopup = ({ onClick, level }) => {
	const [opened, setOpened] = React.useState(false);
	const [saveToDiskState, setSaveToDiskState] = React.useState<AsyncState>('notLoading');
	const [saveToDriveState, setSaveToDriveState] = React.useState<AsyncState>('notLoading');

	const saveToDiskStateToNotLoading = () => setSaveToDiskState('notLoading');
	const saveToDriveStateToNotLoading = () => setSaveToDriveState('notLoading');

	const saveToDisk = e => {
		setSaveToDiskState('loading');
		onClick(e)
			.then(() => {
				setSaveToDiskState('loaded');
				setTimeout(saveToDiskStateToNotLoading, 1500);
			})
			.catch(() => {
				setSaveToDiskState('error');
				setTimeout(saveToDiskStateToNotLoading, 1500);
			});
	};

	const saveToDrive = e => {
		setSaveToDriveState('loading');
		const title = `${model.breakdowns.allIds.slice(0, level + 1).join('-')}-${new Date()
			.toISOString()
			.slice(0, -5)}.csv`;

		const sheetPromise = createSheet(title).run();
		const exportPromise = Async.fromPromise(() =>
			exportAnalyticsData({
				...prepareFetchArguments(model),
				exportBreakdown: model.breakdowns.allIds[level]
			}).run()
		)();

		Async.all([sheetPromise, exportPromise]).fork(
			e => {
				setSaveToDriveState('error');
				setTimeout(saveToDriveStateToNotLoading, 1500);
			},
			([sheets, exp]) => {
				saveCsvToSheets(sheets.data.spreadsheetId)(exp.data.data.reduce(concat, ''))
					.run()
					.fork(
						() => {
							setSaveToDriveState('error');
							setTimeout(saveToDriveStateToNotLoading, 1500);
							deleteFile(sheets.data.spreadsheetId)
								.run()
								.fork(console.error, unit);
						},
						() => {
							setSaveToDriveState('loaded');
							setTimeout(saveToDriveStateToNotLoading, 1500);
						}
					);
			}
		);
	};

	return (
		<ViewExportTablePopup
			opened={opened}
			onClickOutside={() => setOpened(false)}
			onIconClick={() => setOpened(true)}
			saveToDrive={saveToDrive}
			saveToDisk={saveToDisk}
			saveToDiskState={saveToDiskState}
			saveToDriveState={saveToDriveState}
		/>
	);
};

// -----------------------------------------------------------------------------------------
// VIEW
// -----------------------------------------------------------------------------------------

const ViewExportTablePopup = ({
	opened,
	onClickOutside,
	onIconClick,
	saveToDrive,
	saveToDisk,
	saveToDiskState,
	saveToDriveState
}) => {
	const popupRef = React.useRef(null);
	useOnClickOutside(popupRef, onClickOutside);

	const buttonClasses = 'center w-22 relative p-2 cursor-pointer text-xs rounded bg-primary hover:bg-dropdown h-8';
	return (
		<div className="center-v relative">
			<ViewExportIcon className="mr-2" onClick={onIconClick} />
			<Reusable.Popup
				ref={popupRef}
				opened={opened}
				className="z-1 absolute center rounded bg-white text-main-font -translateXY-45-full w-54 h-12"
			>
				<div className="w-full px-4 center-v jc-between text-main-font">
					<ViewButtonAsync className={buttonClasses} onClick={saveToDrive} state={saveToDriveState}>
						Save to drive
					</ViewButtonAsync>
					<ViewButtonAsync className={buttonClasses} onClick={saveToDisk} state={saveToDiskState}>
						Save to disk
					</ViewButtonAsync>
				</div>
			</Reusable.Popup>
		</div>
	);
};

const ViewExportIcon = ({ className, onClick }) => (
	<Reusable.ViewIcon
		className={classNames(className, 'zoom')}
		name="table"
		weight="solid"
		size="micro"
		onClick={onClick}
	/>
);

const ViewButtonAsync = ({ className, onClick, children, state }) => {
	return (
		<div onClick={onClick} className={className}>
			{state === 'notLoading' ? children : <ViewLoadingStateIcon state={state} />}
		</div>
	);
};

const ViewLoadingStateIcon = ({ className = '', state }: { className?: string; state: AsyncState }) => (
	<div className={className}>
		{state === 'loading' && <Reusable.ViewIcon className="spin" name="spinner" weight="solid" size="normal" />}
		{state === 'loaded' && (
			<Reusable.ViewIcon className="text-c-accent2-pale" name="check" weight="solid" size="normal" />
		)}
		{state === 'error' && (
			<Reusable.ViewIcon className="text-red" name="exclamation-triangle" weight="solid" size="normal" />
		)}
	</div>
);

const ViewActiveView = ({ view }: { view: Views.View }) => {
	return (
		<div className="relative center-v py-2 pr-9 text-sidebar-font-light">
			<div className="pl-12 center-v nowrap text-xs w-full">
				<div className="center-v mr-2">
					<Reusable.ViewIcon name="eye" size="micro" />
				</div>
				<div className="center-v jc-between w-full">
					<div className="cursor-pointer" onClick={() => intents.LOAD_MODEL(view.urlQueryParams)}>
						<div className="ellipsis w-20">{view.name}</div>
					</div>
					<div className="center-v">
						<IconWithAsyncState
							name="archive"
							size="mili"
							weight="solid"
							className="mr-2"
							onClick={() => intents.ARCHIVE_VIEW({ id: view.id })}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const ViewArchivedView = ({ view }: { view: Views.View }) => {
	return (
		<div className="relative center-v py-2 pr-9 text-sidebar-font-light">
			<div className="pl-12 center-v nowrap text-xs w-full">
				<div className="center-v mr-2">
					<Reusable.ViewIcon name="eye" size="micro" />
				</div>
				<div className="center-v jc-between w-full">
					<div className="cursor-pointer" onClick={() => intents.LOAD_MODEL(view.urlQueryParams)}>
						<div className="ellipsis w-20">{view.name}</div>
					</div>
					<div className="center-v">
						<IconWithAsyncState
							name="running"
							size="mili"
							weight="solid"
							className="mr-2"
							onClick={() => intents.RESTORE_VIEW({ id: view.id })}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const ViewSavedView = ({ view }: { view: Views.View }) => {
	const [tempName, setTempName] = React.useState(view.name);
	const [editState, setEditState] = React.useState(false);

	const viewRef = React.createRef<HTMLDivElement>();
	const updateName = () =>
		intents
			.UPDATE_VIEW({ id: view.id, name: tempName, urlQueryParams: view.urlQueryParams })
			.finally(_ => setEditState(false));

	const onKeyPress = e => {
		if (propEq('key')('Enter')(e)) {
			updateName().finally(() => setEditState(false));
		}
	};
	useOnClickOutside(viewRef, () => {
		setEditState(false);
		setTempName(view.name);
	});

	return (
		<div className="relative center-v py-2 pr-9 text-sidebar-font-light">
			<div className="pl-12 center-v nowrap text-xs w-full">
				<div className="center-v mr-2">
					<Reusable.ViewIcon name="eye" size="micro" />
				</div>
				<div className="center-v jc-between w-full">
					<div className="cursor-pointer" onClick={() => intents.LOAD_MODEL(view.urlQueryParams)}>
						{editState ? (
							<div ref={viewRef} tabIndex={0} onKeyPress={onKeyPress} className="center-v">
								<div className="mr-2 w-12">
									<InputWithConfirmationPresentational
										isEditable={editState}
										type="string"
										value={tempName}
										onChange={setTempName}
									/>
								</div>
								<IconWithAsyncState name="check" size="mili" weight="solid" onClick={updateName} />
							</div>
						) : (
							<div className="ellipsis w-20">{view.name}</div>
						)}
					</div>
					<div className="center-v">
						<Reusable.ViewIcon
							onClick={() => setEditState(true)}
							className="mr-2 cursor-pointer zoom"
							name="edit"
							size="mili"
							weight="solid"
						/>
						<IconWithAsyncState
							name="upload"
							size="mili"
							weight="solid"
							className="mr-2"
							onClick={() => intents.PUBLISH_VIEW({ id: view.id, organizationName: 'SuperScale' })}
						/>
						<IconWithAsyncState
							name="save"
							size="mili"
							weight="solid"
							className="mr-2"
							onClick={() => intents.UPDATE_VIEW({ id: view.id, name: view.name })}
						/>
					</div>
				</div>
			</div>
			<div className="absolute flex right-0 -translateX-4 cursor-pointer text-sidebar-font-dark">
				<IconWithAsyncState
					name="times"
					size="nano"
					weight="regular"
					onClick={e => (e.stopPropagation(), intents.DELETE_VIEW({ id: view.id }))}
				/>
			</div>
		</div>
	);
};

const ViewUserViewsLabel = () => {
	return (
		<div className="center-v jc-between w-full text-sidebar-font-dark">
			<span>User</span>
			<IconWithAsyncState
				name="save"
				size="mili"
				weight="solid"
				className="mr-2"
				onClick={e => (e.stopPropagation(), intents.CREATE_VIEW({ name: 'New View' }))}
			/>
		</div>
	);
};

const IconWithAsyncState = ({ className = '', name, size, weight, onClick }) => {
	const [saveAsyncState, handleClick] = useAsyncState('notLoading');

	const saveClasses = classNames(className, 'cursor-pointer zoom', {
		'text-c-accent2-pale': saveAsyncState === 'loaded',
		'text-red': saveAsyncState === 'error'
	});

	const loadClasses = classNames(className, 'spin');

	return (
		<>
			{saveAsyncState === 'loading' ? (
				<Reusable.ViewIcon className={loadClasses} name="spinner" weight={weight} size={size} />
			) : (
				<Reusable.ViewIcon
					onClick={e => handleClick(() => onClick(e))}
					className={saveClasses}
					name={name}
					size={size}
					weight={weight}
				/>
			)}
		</>
	);
};

const useAsyncState = (initState: AsyncState) => {
	const [state, setState] = React.useState<AsyncState>(initState);

	const run = <T extends unknown>(fn: () => Promise<T>) => {
		setState('loading');
		fn()
			.then(() => {
				setState('loaded');
				setTimeout(() => {
					setState('notLoading');
				}, 1500);
			})
			.catch(() => {
				setState('error');
				setTimeout(() => {
					setState('notLoading');
				}, 1500);
			});
	};

	return [state, run];
};

// -----------------------------------------------------------------------------------------
// UPDATE
// -----------------------------------------------------------------------------------------
