import { view } from 'funwork-js';
import React from 'react';
import * as Sidebar from './Etl/Sidebar';
import * as Reusable from '@/Reusable';
import { Form } from '@/Etl';

export { Sidebar };

export const View = view(() => {
	return (
		<div className="p-8-4-0">
			<Reusable.ViewDropdown
				multiple
				onChange={(e, value) => {
					console.log('value', value);
				}}
				options={[{ text: 'first', value: '1' }, { text: 'second', value: '2' }]}
				placeholder={{ label: <span className="text-grey text-sm">Add big query destinations</span> }}
				value={[]}
				className="flex-grow p-2-4 border"
				selectedItemClassName="center-v h-4-half w-full jc-between"
				icon={<Reusable.ViewIcon name="chevron-down" size="nano" />}
			/>
			<Form />
		</div>
	);
});
