export interface SidebarModel {
	isSidebarOpened: boolean;
	// ------------------------------------------- HOME SIDEBAR --------------------------------
	isDimensionsSectionOpened: boolean;
	isExportModalOpened: boolean;
	isOrganizationActiveViewsOpened: boolean;
	isOrganizationArchivedViewsOpened: boolean;
	isOrganizationViewsOpened: boolean;
	isRawFiltersSectionOpened: boolean;
	isUserViewsOpened: boolean;
	isViewsOpened: boolean;
	// ------------------------------------------- ETL SIDEBAR --------------------------------
	isAccessesSectionOpened: boolean;
	isFeedsSectionOpened: boolean;
	// ------------------------------------------- ETL ACCESSES --------------------------------
	isBQProjectAccessesOpened: boolean;
	isAppleAccessesOpened: boolean;
	// ------------------------------------------- ETL FEEDS --------------------------------
	isAppleFeedsOpened: boolean;
}

export const sidebarModel: SidebarModel = {
	isSidebarOpened: true,
	// ------------------------------------------- HOME SIDEBAR --------------------------------
	isDimensionsSectionOpened: true,
	isExportModalOpened: true,
	isOrganizationActiveViewsOpened: true,
	isOrganizationArchivedViewsOpened: false,
	isOrganizationViewsOpened: true,
	isRawFiltersSectionOpened: true,
	isUserViewsOpened: true,
	isViewsOpened: true,
	// ------------------------------------------- ETL SIDEBAR --------------------------------
	isAccessesSectionOpened: true,
	isFeedsSectionOpened: true,
	// ------------------------------------------- ETL ACCESSES --------------------------------
	isBQProjectAccessesOpened: true,
	isAppleAccessesOpened: true,
	// ------------------------------------------- ETL FEEDS --------------------------------
	isAppleFeedsOpened: true
};
