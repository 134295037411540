import { Async, compose, IO, Maybe } from '@functions/crocks';
import { CognitoAccessToken, CognitoAuth, CognitoAuthSession, CognitoIdToken } from 'amazon-cognito-auth-js';
import axios, { AxiosInstance } from 'axios';
import { parseQuery } from 'funwork-js';
import jwtDecode from 'jwt-decode';
import O from 'patchinko/constant';
import { isExpired } from './Date';
import { deserializeUrlToModel, model } from './model';
import { Feed } from './model/etl';
import { User } from './Session';
import { mock } from './mock';

const { Just, Nothing } = Maybe;

// -----------------------------------------------------------------------------------------
// COGNITO
// -----------------------------------------------------------------------------------------

let auth: CognitoAuth;

export const initSession = () => {
	auth = initAuth();
	window.auth = auth;
};

export const signIn = () => {
	window.localStorage.setItem('path', window.location.pathname.slice(1));
	window.localStorage.setItem('query', window.location.search);
	auth.getSession();
};

export const signOut = () => {
	auth.signOut();
};

const initAuth = () => {
	const auth = new CognitoAuth({
		AppWebDomain: process.env.APP_WEB_DOMAIN_URL as string,
		TokenScopesArray: ['openid', 'profile', 'email'],
		RedirectUriSignIn: process.env.LOGIN_REDIRECT_URL as string,
		RedirectUriSignOut: process.env.LOGOUT_REDIRECT_URL as string,
		IdentityProvider: 'Google',
		UserPoolId: process.env.COGNITO_USER_POOL_ID,
		ClientId: process.env.COGNITO_APP_CLIENT_ID as string
	});

	auth.useCodeGrantFlow();
	auth.userhandler = {
		onSuccess: userSession => {
			createUaInstance(userSession.getIdToken().getJwtToken());
			createGapiInstance(jwtDecode(userSession.getIdToken().getJwtToken())['custom:access_token']);

			O(model, {
				...deserializeUrlToModel({
					path: window.localStorage.getItem('path'),
					query: parseQuery(window.localStorage.getItem('query'))
				}),
				user: getMaybeUser(userSession)
			});
		},
		onFailure: e => {
			console.error('Failed to sign in', e);
		}
	};

	if (auth.isUserSignedIn()) {
		createUaInstance(
			auth
				.getSignInUserSession()
				.getIdToken()
				.getJwtToken()
		);
		createGapiInstance(
			jwtDecode(
				auth
					.getSignInUserSession()
					.getIdToken()
					.getJwtToken()
			)['custom:access_token']
		);

		O(model, {
			user: getMaybeUser(auth.getSignInUserSession())
		});

		return auth;
	}

	if (
		auth
			.getSignInUserSession()
			.getRefreshToken()
			.getToken()
	) {
		auth.getSession();
		return auth;
	}

	auth.parseCognitoWebResponse(window.location.href);
	return auth;
};

const getSignInUserSession = (auth: CognitoAuth) => auth.getSignInUserSession();

const getIdToken = (signInUserSession: CognitoAuthSession) => signInUserSession.getIdToken();
const getRefreshToken = (signInUserSession: CognitoAuthSession) => signInUserSession.getRefreshToken().getToken();

const getJwtToken = (token: CognitoAccessToken | CognitoIdToken) => token.getJwtToken();
const getTokenExpiration = (token: CognitoAccessToken | CognitoIdToken) => token.getExpiration() * 1000;

const refreshSession = (auth: CognitoAuth) => {
	auth.refreshSession(
		compose(
			getRefreshToken,
			getSignInUserSession
		)(auth)
	);
};

const refreshCognitoSessionIfExpired = (auth: CognitoAuth) =>
	IO(() => {
		const isCognitoTokenExpired = compose(
			isExpired,
			getTokenExpiration,
			getIdToken,
			getSignInUserSession
		)(auth);
		if (isCognitoTokenExpired) {
			refreshSession(auth);
		}
	});

const getMaybeUser = (userSession: CognitoAuthSession): User | undefined => {
	if (!getIdToken(userSession)) return undefined;
	// if (!getIdToken(userSession)) return Nothing();
	const payload = compose(
		jwtDecode,
		getJwtToken,
		getIdToken
	)(userSession);
	if (!payload.email) return undefined;
	// if (!payload.email) return Nothing();

	return {
		email: payload.email,
		name: payload.given_name,
		surname: payload.family_name,
		profilePictureURL: payload.picture
	};
	// return Just({
	// 	email: payload.email,
	// 	name: payload.given_name,
	// 	surname: payload.family_name,
	// 	profilePictureURL: payload.picture
	// });
};

// -----------------------------------------------------------------------------------------
// GAPI
// -----------------------------------------------------------------------------------------

let gapiInstance: AxiosInstance;

const gapiSpreadsheetsURL = 'https://sheets.googleapis.com/v4/spreadsheets';
const gapiDriveURL = 'https://www.googleapis.com/drive/v2/files';

const createGapiInstance = (token: string) => {
	gapiInstance = axios.create({
		baseURL: '',
		params: {
			alt: 'json'
		},
		headers: { Authorization: `Bearer ${token}` }
	});
};

export const createSheet = (title: string) => refreshCognitoSessionIfExpired(auth).chain(() => _createSheet(title));

type _createSheet = (a: string) => Async<void>;
const _createSheet: _createSheet = title =>
	IO(Async.fromPromise(() => gapiInstance.post(gapiSpreadsheetsURL, { properties: { title } })));

export const saveCsvToSheets = (spreadsheetId: string) => data =>
	refreshCognitoSessionIfExpired(auth).chain(() => _saveCsvToSheets(spreadsheetId)(data));

type _saveCsvToSheets = (a: string) => (b: string) => Async<void>;
const _saveCsvToSheets: _saveCsvToSheets = spreadsheetId => data =>
	IO(
		Async.fromPromise(() =>
			gapiInstance.post(`${gapiSpreadsheetsURL}/${spreadsheetId}:batchUpdate`, {
				requests: [
					{
						pasteData: {
							data,
							coordinate: {
								sheetId: 0,
								rowIndex: 0,
								columnIndex: 0
							},
							type: 'PASTE_NORMAL',
							delimiter: ','
						}
					}
				]
			})
		)
	);

export const deleteFile = (fileId: string) => refreshCognitoSessionIfExpired(auth).chain(() => _deleteFile(fileId));

type _deleteFile = (a: string) => Async<void>;
const _deleteFile: _deleteFile = fileId =>
	IO(Async.fromPromise(() => gapiInstance.delete(`${gapiDriveURL}/${fileId}`)));

// -----------------------------------------------------------------------------------------
// UA API - CONFIG
// -----------------------------------------------------------------------------------------

const ANALYTICS_ENDPOINT_BQ = '/analytics-api-bq';
const ANALYTICS_ENDPOINT = '/analytics-api';
const INTEGRATION_ENDPOINT = '/invoke-etl-retry';
const GRAPH_ENDPOINT = '/graph-api';

let uaInstance: AxiosInstance;

export const createUaInstance = (token: string) => {
	uaInstance = axios.create({
		baseURL: process.env.UA_API,
		headers: { Authorization: `${token}`, Accept: 'application/json' }
	});
};

// -----------------------------------------------------------------------------------------
// UA API - ANALYTICS
// -----------------------------------------------------------------------------------------

export const getInitialAnalyticsData = () => refreshCognitoSessionIfExpired(auth).chain(_getInitialAnalyticsData);
const _getInitialAnalyticsData = () => IO(() => uaInstance(`${ANALYTICS_ENDPOINT}/schema`));

// export const fetchAnalyticsData = (fok: any) =>
// 	IO(() => {
// 		return Promise.resolve({ data: { data: mock.slice(1) } });
// 	});
// export const fetchAnalyticsData = props => refreshCognitoSessionIfExpired(auth).chain(() => _fetchAnalyticsData(props));
// const _fetchAnalyticsData = props =>
// 	IO(() => uaInstance.get(`${ANALYTICS_ENDPOINT_BQ}`, { params: { query: JSON.stringify(props).slice(1) } }));

export const fetchAnalyticsData = props => refreshCognitoSessionIfExpired(auth).chain(() => _fetchAnalyticsData(props));
const _fetchAnalyticsData = props =>
	IO(() => uaInstance.get(`${ANALYTICS_ENDPOINT}`, { params: { query: JSON.stringify(props) } }));

export const exportAnalyticsData = props =>
	refreshCognitoSessionIfExpired(auth).chain(() => _exportAnalyticsData(props));
const _exportAnalyticsData = props =>
	IO(() => uaInstance.get(`${ANALYTICS_ENDPOINT}`, { params: { query: JSON.stringify(props) } }));

// -----------------------------------------------------------------------------------------
// UA API - INTEGRATION
// -----------------------------------------------------------------------------------------

export const retryFeed = Async.fromPromise(id => uaInstance(`${INTEGRATION_ENDPOINT}/?requestID=${id}`));

// -----------------------------------------------------------------------------------------
// UA API - VIEWS
// -----------------------------------------------------------------------------------------

export const getUserViews = (_auth?: CognitoAuth) => refreshCognitoSessionIfExpired(_auth || auth).chain(_getUserViews);
const _getUserViews = () =>
	IO(() => uaInstance.post(`${GRAPH_ENDPOINT}`, { query: `{me { views { id name urlQueryParams } }}` }));

export const getOrganizationViews = (_auth?: CognitoAuth) =>
	refreshCognitoSessionIfExpired(_auth || auth).chain(_getOrganizationViews);
const _getOrganizationViews = () =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: `{organization(where: { name: "SuperScale" }) { views(orderBy: name_ASC) { id name urlQueryParams archivedAt } }}`
		})
	);

export type createViewRequestParams = { name: string; urlQueryParams: string };
export const createView = (props: createViewRequestParams) =>
	refreshCognitoSessionIfExpired(auth).chain(() => _createView(props));
const _createView = (props: createViewRequestParams) =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: createViewQuery,
			variables: {
				name: props.name,
				urlQueryParams: props.urlQueryParams
			}
		})
	);

export type updateViewRequestParams = { id: string; name: string; urlQueryParams: string };
export const updateView = (props: updateViewRequestParams) =>
	refreshCognitoSessionIfExpired(auth).chain(() => _updateView(props));
const _updateView = (props: updateViewRequestParams) =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: updateViewQuery,
			variables: props
		})
	);

export type deleteViewRequestParams = { id: string };
export const deleteView = (props: deleteViewRequestParams) =>
	refreshCognitoSessionIfExpired(auth).chain(() => _deleteView(props));
const _deleteView = (props: deleteViewRequestParams) =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: deleteViewQuery,
			variables: props
		})
	);

export type publishViewRequestParams = { id: string; organizationName: string };
export const publishView = (props: publishViewRequestParams) =>
	refreshCognitoSessionIfExpired(auth).chain(() => _publishView(props));
const _publishView = (props: publishViewRequestParams) =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: publishViewQuery,
			variables: props
		})
	);

export type archiveViewRequestParams = { id: string };
export const archiveView = (props: archiveViewRequestParams) =>
	refreshCognitoSessionIfExpired(auth).chain(() => _archiveView(props));
const _archiveView = (props: archiveViewRequestParams) =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: archiveViewQuery,
			variables: props
		})
	);

export type restoreViewRequestParams = { id: string };
export const restoreView = (props: restoreViewRequestParams) =>
	refreshCognitoSessionIfExpired(auth).chain(() => _restoreView(props));
const _restoreView = (props: restoreViewRequestParams) =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: restoreViewQuery,
			variables: props
		})
	);

export type copyViewRequestParams = { id: string };
export const copyView = (props: copyViewRequestParams) =>
	refreshCognitoSessionIfExpired(auth).chain(() => _copyView(props));
const _copyView = (props: copyViewRequestParams) =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: copyViewQuery,
			variables: props
		})
	);

const createViewQuery = `mutation createView($name: String!, $urlQueryParams: String!) {
    createView(name: $name, urlQueryParams: $urlQueryParams) {
      success
      message
    }
  }
  `;

const updateViewQuery = `mutation updateView($id: ID!, $name: String!, $urlQueryParams: String!) {
    updateView(id: $id, name: $name, urlQueryParams: $urlQueryParams) {
      success
      message
    }
  }
  `;

const deleteViewQuery = `mutation deleteView($id: ID!) {
    deleteView(id: $id) {
      success
      message
    }
  }
  `;

const publishViewQuery = `mutation publishView($id: ID!, $organizationName: String!) {
    publishView(id: $id, organizationName: $organizationName) {
      success
      message
    }
  }
  `;

const archiveViewQuery = `mutation archiveView($id: ID!) {
    archiveView(id: $id) {
      success
      message
    }
  }
  `;

const restoreViewQuery = `mutation restoreView($id: ID!) {
    restoreView(id: $id) {
      success
      message
    }
  }
  `;

const copyViewQuery = `mutation copyView($id: ID!) {
    copyView(id: $id) {
      success
      message
    }
  }
  `;

// -----------------------------------------------------------------------------------------
// UA API - ETL
// -----------------------------------------------------------------------------------------

export type runEtlFeedOnceRequestParams = { feed: Exclude<Feed, ''> };
export const runEtlFeedOnce = (props: runEtlFeedOnceRequestParams) =>
	refreshCognitoSessionIfExpired(auth).chain(() => _runEtlFeedOnce(props));
const _runEtlFeedOnce = (props: runEtlFeedOnceRequestParams) =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: runEtlFeedOnceQuery,
			variables: props
		})
	);

const runEtlFeedOnceQuery = ``;

export const getUserOrganizationRoles = (_auth?: CognitoAuth) =>
	refreshCognitoSessionIfExpired(_auth || auth).chain(_getUserOrganizationRoles);
const _getUserOrganizationRoles = () =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: `{me { organizationRoles { organizationId organizationName role } }}`
		})
	);

// 		ACCESSES

// 			BQ PROJECTS
export const getBQProjects = () => refreshCognitoSessionIfExpired(auth).chain(() => _getBQProjects());
const _getBQProjects = () =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: getBQProjectsQuery
		})
	);

const getBQProjectsQuery = `
	{
		bQProjects {
			id
			createdAt
			updatedAt
			organization {
			  id
			}
			descriptiveName
			projectId
			clientEmail
			privateKey
		}
	}
`;

export const createBQProject = props => refreshCognitoSessionIfExpired(auth).chain(() => _createBQProject(props));
const _createBQProject = props =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: createBQProjectQuery,
			variables: props
		})
	);

const createBQProjectQuery = `
	mutation createBQProject(
			$organizationId: ID!
			$descriptiveName: String!
			$projectId: String!
			$clientEmail: String!
			$privateKey: String!
		) {
		createBQProject(
			data: {
				organization: { connect: { id: $organizationId } }
				descriptiveName: $descriptiveName
				projectId: $projectId
				clientEmail: $clientEmail
				privateKey: $privateKey
			}
		) {
			success
			message
		}
	}
`;

export const updateBQProject = props => refreshCognitoSessionIfExpired(auth).chain(() => _updateBQProject(props));
const _updateBQProject = props =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: updateBQProjectQuery,
			variables: props
		})
	);

const updateBQProjectQuery = `
	mutation updateBQProject(
			$id: ID!
			$organizationId: ID!
			$descriptiveName: String!
			$projectId: String!
			$clientEmail: String!
			$privateKey: String!
		) {
		updateBQProject(
			where: { id: $id }
			data: {
				organization: { connect: { id: $organizationId } }
				descriptiveName: $descriptiveName
				projectId: $projectId
				clientEmail: $clientEmail
				privateKey: $privateKey
			}
		) {
			success
			message
		}
	}
`;

// 			APPLE
export const getAppleAccesses = () => refreshCognitoSessionIfExpired(auth).chain(() => _getAppleAccesses());
const _getAppleAccesses = () =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: getAppleAccessesQuery
		})
	);

const getAppleAccessesQuery = `
	{
		appleAccesses {
			id
			createdAt
			updatedAt
			descriptiveName
			login
			password
			accountName
		}
	}
`;

type CreateAppleAccessParams = {
	descriptiveName: string;
	login: string;
	password: string;
	accountName: string;
};
export const createAppleAccess = (props: CreateAppleAccessParams) =>
	refreshCognitoSessionIfExpired(auth).chain(() => _createAppleAccess(props));
const _createAppleAccess = (props: CreateAppleAccessParams) =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: createAppleAccessQuery,
			variables: props
		})
	);

const createAppleAccessQuery = `
	mutation createAppleAccess(
			$descriptiveName: String!
			$login: String!
			$password: String!
			$accountName: String!
		) {
		createAppleAccess(
			data: {
				descriptiveName: $descriptiveName
				login: $login
				password: $password
				accountName: $accountName
			}
		) {
			success
			message
		}
	}
`;

type UpdateAppleAccessParams = {
	id: string;
	descriptiveName: string;
	login: string;
	password: string;
	accountName: string;
};
export const updateAppleAccess = (props: UpdateAppleAccessParams) =>
	refreshCognitoSessionIfExpired(auth).chain(() => _updateAppleAccess(props));
const _updateAppleAccess = (props: UpdateAppleAccessParams) =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: updateAppleAccessQuery,
			variables: props
		})
	);

const updateAppleAccessQuery = `
	mutation updateAppleAccess(
			$id: ID!
			$descriptiveName: String!
			$login: String!
			$password: String!
			$accountName: String!
		) {
		updateAppleAccess(
			where: { id: $id }
			data: {
				descriptiveName: $descriptiveName
				login: $login
				password: $password
				accountName: $accountName
			}
		) {
			success
			message
		}
	}
`;

// 		FEEDS
// 			APPLE
export const getAppleFeeds = () => refreshCognitoSessionIfExpired(auth).chain(() => _getAppleFeeds());
const _getAppleFeeds = () =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: getAppleFeedsQuery
		})
	);

const getAppleFeedsQuery = `
	{
		appleAccesses {
			id
			createdAt
			updatedAt
			descriptiveName
			login
			password
			accountName
		}
	}
`;

export const createAppleFeed = props => refreshCognitoSessionIfExpired(auth).chain(() => _createAppleFeed(props));
const _createAppleFeed = props =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: createAppleFeedQuery,
			variables: props
		})
	);

const createAppleFeedQuery = `
	mutation createAppleFeed(
			$organizationId: ID!
			$descriptiveName: String!
			$projectId: String!
			$clientEmail: String!
			$privateKey: String!
		) {
		createAppleFeed(
			data: {
				organization: { connect: { id: $organizationId } }
				descriptiveName: $descriptiveName
				projectId: $projectId
				clientEmail: $clientEmail
				privateKey: $privateKey
			}
		) {
			success
			message
		}
	}
`;

type UpdateAppleFeedParams = {
	id: string;
	descriptiveName: string;
	login: string;
	password: string;
	accountName: string;
};
export const updateAppleFeed = (props: UpdateAppleFeedParams) =>
	refreshCognitoSessionIfExpired(auth).chain(() => _updateAppleFeed(props));
const _updateAppleFeed = (props: UpdateAppleFeedParams) =>
	IO(() =>
		uaInstance.post(`${GRAPH_ENDPOINT}`, {
			query: updateAppleFeedQuery,
			variables: props
		})
	);

const updateAppleFeedQuery = `
	mutation updateAppleFeed(
			$id: ID!
			$descriptiveName: String!
			$login: String!
			$password: String!
			$accountName: String!
		) {
		updateAppleFeed(
			where: { id: $id }
			data: {
				descriptiveName: $descriptiveName
				login: $login
				password: $password
				accountName: $accountName
			}
		) {
			success
			message
		}
	}
`;
