import { Form } from '@/model/etl';
import { createIntents } from '@/model';

export type EtlIntents = {
	UPDATE_FIELD: ({ id, value }: { id: string; value: string | number | boolean }) => void;
	SELECT_FORM: (feed: Form) => void;
	FETCH_USER_ORGANIZATION_ROLES: () => void;
	// ACCESSES
	FETCH_BQ_PROJECT_ACCESSES: () => void;
	FETCH_APPLE_ACCESSES: () => void;
	// FEEDS
	FETCH_APPLE_FEEDS: () => void;
	UPDATE_ETL_CONFIG: () => void;
	CREATE_ETL_CONFIG: () => void;
	RUN_ETL_CONFIG: () => void;
};

export const etlIntents: EtlIntents = createIntents(present => ({
	UPDATE_FIELD: ({ id, value }) => present({ type: 'UPDATE_FIELD', payload: { id, value } }),
	SELECT_FORM: feed => present({ type: 'SELECT_FORM', payload: feed }),
	FETCH_USER_ORGANIZATION_ROLES: () => present({ type: 'FETCH_USER_ORGANIZATION_ROLES' }),
	// ACCESSES
	FETCH_BQ_PROJECT_ACCESSES: () => present({ type: 'FETCH_BQ_PROJECT_ACCESSES' }),
	FETCH_APPLE_ACCESSES: () => present({ type: 'FETCH_APPLE_ACCESSES' }),
	// FEEDS
	FETCH_APPLE_FEEDS: () => present({ type: 'FETCH_APPLE_FEEDS' }),
	UPDATE_ETL_CONFIG: () => present({ type: 'UPDATE_ETL_CONFIG' }),
	CREATE_ETL_CONFIG: () => present({ type: 'CREATE_ETL_CONFIG' }),
	RUN_ETL_CONFIG: () => present({ type: 'RUN_ETL_CONFIG' })
}));
