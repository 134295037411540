import { intents } from '@/intents';
import { model } from '@/model';
import { Table } from '@/_components/_Table/Table';
import { Modal } from '@components/reusable/Modal/Modal';
import { view } from 'funwork-js';
import * as React from 'react';
import * as Metrics from './Home/Metrics';
import * as Sidebar from './Home/Sidebar';
import * as TableControls from './Home/Table';

export { Sidebar };

// ------------------------------------------------ MODEL ------------------------------------------------

const Model = {};

// ------------------------------------------------ VIEW ------------------------------------------------

export const View = view(() => {
	return (
		<>
			<TableControls.View />
			<div className="flex w-full h-full bg-primary">
				<Modal isOpened={model.isMetricPickerOpened} onClose={intents.CLOSE_METRIC_PICKER}>
					<Metrics.View />
				</Modal>
				<div className="flex flex-col relative w-full h-full p-8-4-0 children:flex-shrink-0">
					<Table />
				</div>
			</div>
		</>
	);
});
