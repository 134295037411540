import { model, serializeModelToUrl } from '@/model';
import * as Api from '@/Api';
import { partition } from '@functions/partition';
import { View } from '.';

export type ViewsAction =
	| 'GET_USER_VIEWS'
	| 'GET_ORGANIZATION_VIEWS'
	| 'CREATE_VIEW'
	| 'UPDATE_VIEW'
	| 'DELETE_VIEW'
	| 'PUBLISH_VIEW'
	| 'ARCHIVE_VIEW'
	| 'RESTORE_VIEW'
	| 'COPY_VIEW';

export const viewsReducer = {
	GET_USER_VIEWS: getUserViews,
	GET_ORGANIZATION_VIEWS: getOrganizationViews,
	CREATE_VIEW: createView,
	UPDATE_VIEW: updateView,
	DELETE_VIEW: deleteView,
	PUBLISH_VIEW: publishView,
	ARCHIVE_VIEW: archiveView,
	RESTORE_VIEW: restoreView,
	COPY_VIEW: copyView
};

function getUserViews() {
	Api.getUserViews()
		.run()
		.then(setUserViews)
		.catch(e => console.error('Failed to get user views: ', e));
}

function getOrganizationViews() {
	Api.getOrganizationViews()
		.run()
		.then(setOrganizationViews)
		.catch(e => console.error('Failed to load organization views: ', e));
}

function createView() {
	const urlQueryParams = JSON.stringify(serializeModelToUrl(model).query);
	return Api.createView({ urlQueryParams, name: 'New view' })
		.run()
		.then(() => Api.getUserViews().run())
		.then(setUserViews)
		.catch(e => (console.error('Failed to create view: ', e), e));
}

function updateView({ id, name, urlQueryParams }: { id: string; name: string; urlQueryParams?: string }) {
	const _urlQueryParams = JSON.stringify(serializeModelToUrl(model).query);
	return Api.updateView({ id, name, urlQueryParams: urlQueryParams || _urlQueryParams })
		.run()
		.then(() => Api.getUserViews().run())
		.then(setUserViews)
		.catch(e => (console.error('Failed to update view: ', e), e));
}

function deleteView({ id }: { id: string }) {
	return Api.deleteView({ id })
		.run()
		.then(() => Api.getUserViews().run())
		.then(setUserViews)
		.catch(e => (console.error('Failed to delete view: ', e), e));
}

function publishView({ id, organizationName }: { id: string; organizationName: string }) {
	return Api.publishView({ id, organizationName })
		.run()
		.then(async res => {
			setUserViews(await Api.getUserViews().run());
			setOrganizationViews(await Api.getOrganizationViews().run());
			return res;
		})
		.catch(e => (console.error('Failed to publish view: ', e), e));
}

function archiveView({ id }: { id: string }) {
	return Api.archiveView({ id })
		.run()
		.then(() => Api.getOrganizationViews().run())
		.then(setOrganizationViews)
		.catch(e => console.error('Failed to load organization views: ', e));
}

function restoreView({ id }: { id: string }) {
	return Api.restoreView({ id })
		.run()
		.then(() => Api.getOrganizationViews().run())
		.then(setOrganizationViews)
		.catch(e => console.error('Failed to load organization views: ', e));
}

function copyView({ id }: { id: string }) {
	return Api.copyView({ id })
		.run()
		.then(() => Api.getUserViews().run())
		.then(setUserViews)
		.catch(e => (console.error('Failed to copy view: ', e), e));
}

const setUserViews = res => {
	if (model.user) {
		model.user.views = res.data.data.me.views;
	}
	// model.user = model.user.map(user => ({ ...user, views: res.data.data.me.views }));
	return res;
};

export const setOrganizationViews = res => {
	const [activeViews, archivedViews]: [View[], View[]] = partition((view: View) => view.archivedAt === null)(
		res.data.data.organization.views
	);
	model.activeViews = activeViews;
	model.archivedViews = archivedViews;
	return res;
};
