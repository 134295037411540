import {
	getCompositeStringValueOfMetric,
	getLabelOfMetric,
	getMetricValueOfCompositeString,
	MetricsSchema
} from '@/Metrics';
import { model, Model } from '@/model';
import { compose, getPath, getProp, Maybe, omit, pick, reduce } from '@functions/crocks';
import { pickByFunctionOnValue } from '@functions/pickByFunctionOnValue';
import { MetricSchema, MetricValue, SelectedMetric, MetricsModel } from '.';
import { setModelToFetchTableData } from '../reducer';

export type MetricsAction =
	| 'SELECT_METRIC'
	| 'SELECT_METRICS'
	| 'UNSELECT_METRIC'
	| 'UNSELECT_METRICS'
	| 'UNSELECT_CUMULATIVE_METRIC'
	| 'UNSELECT_CUMULATIVE_METRICS'
	| 'CHECK_CUMULATIVE_METRIC'
	| 'UNCHECK_CUMULATIVE_METRIC'
	| 'UNCHECK_ALL_METRICS'
	| 'UPDATE_ORDER_OF_METRICS'
	| 'SELECT_OPTION_FOR_ADDING_CUMULATIVE_METRICS'
	| 'ADD_NUMBER_FOR_CUMULATIVE_METRICS_SELECTION'
	| 'UPDATE_FILTER_OF_METRIC_SELECTION';

type Reducer = { [key in MetricsAction]: any };

export const metricsReducer: Reducer = {
	SELECT_METRIC: selectMetric,
	SELECT_METRICS: selectMetrics,
	UNSELECT_METRIC: unselectMetric,
	UNSELECT_METRICS: unselectMetrics,
	UNSELECT_CUMULATIVE_METRIC: unselectCumulativeMetric,
	UNSELECT_CUMULATIVE_METRICS: unselectCumulativeMetrics,
	CHECK_CUMULATIVE_METRIC: checkCumulativeMetric,
	UNCHECK_CUMULATIVE_METRIC: uncheckCumulativeMetric,
	UNCHECK_ALL_METRICS: uncheckAllMetrics,
	UPDATE_ORDER_OF_METRICS: changeMetricsOrder,
	SELECT_OPTION_FOR_ADDING_CUMULATIVE_METRICS: selectAddNumbersOption,
	ADD_NUMBER_FOR_CUMULATIVE_METRICS_SELECTION: addCustomAddNumber,
	UPDATE_FILTER_OF_METRIC_SELECTION: setMetricFilterInput
};

function selectMetric(metric: MetricValue) {
	compose(
		setModelToFetchTableData,
		addMetricToSelected(metric)
	)(model);
}

function selectMetrics(metrics: MetricValue[]) {
	compose(
		setModelToFetchTableData,
		reduce((acc, val) => addMetricToSelected(val)(acc), model)
	)(metrics);
}

function unselectMetric(metric: MetricValue) {
	compose(
		setModelToFetchTableData,
		removeUnnecessaryOrderings,
		removeUnnecessaryFiltersFromBreakdowns,
		removeUnnecessaryPredicates,
		removeUnnecessaryCustomButtons,
		removeMetricFromSelected(metric)
	)(model);
}

function unselectMetrics(metrics: MetricValue[]) {
	compose(
		setModelToFetchTableData,
		removeUnnecessaryOrderings,
		removeUnnecessaryFiltersFromBreakdowns,
		removeUnnecessaryPredicates,
		removeUnnecessaryCustomButtons,
		reduce((acc, val) => removeMetricFromSelected(val)(acc), model)
	)(metrics);
}

function unselectCumulativeMetric(metric: MetricValue) {
	compose(
		setModelToFetchTableData,
		removeUnnecessaryOrderings,
		removeUnnecessaryFiltersFromBreakdowns,
		removeUnnecessaryPredicates,
		removeUnnecessaryCustomButtons,
		removeCumulativeMetricFromSelected(metric)
	)(model);
}

function unselectCumulativeMetrics(metrics: MetricValue[]) {
	compose(
		setModelToFetchTableData,
		removeUnnecessaryOrderings,
		removeUnnecessaryFiltersFromBreakdowns,
		removeUnnecessaryPredicates,
		removeUnnecessaryCustomButtons,
		reduce((acc, val) => removeCumulativeMetricFromSelected(val)(acc), model)
	)(metrics);
}

function checkCumulativeMetric(metric: MetricValue) {
	model.checkedCumulativeMetrics = model.checkedCumulativeMetrics.concat(metric);
}

function uncheckCumulativeMetric(metric: MetricValue) {
	model.checkedCumulativeMetrics = model.checkedCumulativeMetrics.filter(
		item => item.value !== metric.value
	);
}

function uncheckAllMetrics() {
	model.checkedCumulativeMetrics = [];
}

function changeMetricsOrder(event) {
	if (!event.destination) return;
	if (event.source.index === event.destination.index) return;
	model.selectedMetrics = swapElements(event.source.index)(event.destination.index)(
		model.selectedMetrics
	);
	setModelToFetchTableData(model);
}

function selectAddNumbersOption(option: MetricsModel['addCumulativeMetricsOptions']) {
	model.selectedAddCumulativeMetricPreset = option;
}

function addCustomAddNumber(number: number) {
	addNumberToCustomOptions(number)(model);
}

function setMetricFilterInput(inputString: string) {
	model.metricFilterInput = inputString;
}

const removeUnnecessaryCustomButtons = (model: Model) => {
	model.addCumulativeMetricsOptions.custom = model.addCumulativeMetricsOptions.custom.filter(
		item =>
			model.selectedMetrics.some(metric =>
				getPath(['params', 'day_of_activity'])(metric).equals(Maybe.Just(item))
			)
	);
	return model;
};

const removeUnnecessaryFiltersFromBreakdowns = (model: Model) => {
	for (const breakdownId in model.breakdowns.byIds) {
		if (model.breakdowns.byIds[breakdownId].filters) {
			model.breakdowns.byIds[breakdownId].filters = model.breakdowns.byIds[breakdownId].filters.filter(id => {
				const metricId = id.split('-')[1];
				return model.selectedMetrics.some(
					item => metricId === getCompositeStringValueOfMetric(item)
				);
			});
		}
	}
	return model;
};

const removeUnnecessaryPredicates = (model: Model) => {
	model.predicates.allIds = model.predicates.allIds.filter(id => {
		const metricId = id.split('-')[1];
		return model.selectedMetrics.some(item => metricId === getCompositeStringValueOfMetric(item));
	});
	model.predicates.byIds = pick(model.predicates.allIds, model.predicates.byIds);
	return model;
};

const removeUnnecessaryOrderings = (model: Model) => {
	Object.entries(model.orderings.byIds).forEach(([breakdown]) => {
		model.orderings.byIds[breakdown] = model.orderings.byIds[breakdown].filter(ordering =>
			model.selectedMetrics.some(item => getCompositeStringValueOfMetric(item) === ordering.metric)
		);
	});
	model.orderings.byIds = pickByFunctionOnValue(breakdown => breakdown.length !== 0)(model.orderings.byIds);
	model.orderings.allIds = model.orderings.allIds.filter(breakdown => model.orderings.byIds[breakdown]);
	return model;
};

const addNumberToCustomOptions = (number: number) => (model: Model) => {
	model.addCumulativeMetricsOptions.custom = model.addCumulativeMetricsOptions.custom.concat(
		number
	);
	return model;
};

const swapElements = (oldIndex: number) => (newIndex: number) => <T>(list: T[]): T[] => {
	const item = list.splice(oldIndex, 1)[0];
	list.splice(newIndex, 0, item);
	return list;
};

const addMetricToSelected = (metric: MetricValue) => (model: Model) => {
	if (!isExactMetricSelected(metric)(model.selectedMetrics)) {
		model.selectedMetrics = model.selectedMetrics.concat(
			getEnhancedMetric(model.metricsSchema)(metric)
		);
	}
	return model;
};
// ? assign({ selectedMetrics: model.selectedMetrics.concat(getEnhancedMetric(model.metricsSchema)(metric)) })(model)
// : model;
// assign({ selectedMetrics: model.selectedMetrics.concat(getEnhancedMetric(model.metricsSchema, metric)) })(model);
// !isMetricSelected(metric)(model.selectedMetrics)
// 	? Maybe.Just(
// 			assign({ selectedMetrics: model.selectedMetrics.concat(getEnhancedMetric(model.metricsSchema, metric)) })(model)
// 	  )
// 	: Maybe.Nothing();

const removeMetricFromSelected = (metric: MetricValue) => (model: Model) => {
	model.selectedMetrics = model.selectedMetrics.filter(
		item => item.value !== metric.value || !getParams(item).equals(getParams(metric))
	);
	return model;
};

const removeCumulativeMetricFromSelected = (metric: MetricValue) => (model: Model) => {
	model.selectedMetrics = model.selectedMetrics.filter(
		item => item.value !== metric.value || (item.value === metric.value && !item.params)
	);
	return model;
};

export const getEnhancedMetric = (metricsSchema: MetricsSchema) => (metric: MetricValue): SelectedMetric =>
	getProp('params')(metric)
		.map(item => ({
			...metricsSchema[metric.value],
			params: item
		}))
		.option(omit(['params'])({ ...metricsSchema[metric.value] }));

export const getLifetimeMetrics = (metricsSchema: MetricsSchema) =>
	pickByFunctionOnValue(isLifetimeMetric)(metricsSchema);

export const getCumulativeMetrics = (metricsSchema: MetricsSchema) =>
	pickByFunctionOnValue(isCumulativeMetric)(metricsSchema);

export const isLifetimeMetric = (metric: MetricSchema | SelectedMetric) =>
	!getPath(['params', 'day_of_activity', 'required'])(metric).option(false);

export const isCumulativeMetric = (metric: MetricSchema | MetricValue | SelectedMetric) =>
	getParams(metric).option(false);

export const isMetricSelected = (metric: MetricValue) => (selectedMetrics: SelectedMetric[] | MetricValue[]) =>
	selectedMetrics.some(item =>
		!isCumulativeMetric(metric)
			? item.value === metric.value && !item.params
			: item.value === metric.value && !!item.params
	);

export const isExactMetricSelected = (metric: MetricValue) => (selectedMetrics: SelectedMetric[] | MetricValue[]) =>
	selectedMetrics.some(item =>
		!isCumulativeMetric(metric)
			? item.value === metric.value && !item.params
			: item.value === metric.value && getParams(item).equals(getParams(metric))
	);

export const getLabelOfMetricCompositeString = (model: Model) =>
	compose(
		getLabelOfMetric,
		getEnhancedMetric(model.metricsSchema),
		getMetricValueOfCompositeString
	);

const getParams = getProp('params');
