import { AsyncData } from '@/Async';
import { intents } from '@/intents';
import { model } from '@/model';
import {
	Apple,
	AppleAccess,
	appleAccessToForm,
	appleToForm,
	BQProject,
	bQProjectToForm,
	createAppleAccessForm,
	createBQProjectForm,
	Item,
	createAppleForm
} from '@/model/etl';
import { createOrganizationField, createOrganizationOptions, organizationRoleToOption } from '@/model/etl/Organization';
import * as Reusable from '@/Reusable';
import { LabelPresentational } from '@reusable/LabelPresentational/LabelPresentational';
import { view } from 'funwork-js';
import React from 'react';

interface ViewProps {
	Section: ({ children }: { children: JSX.Element }) => JSX.Element;
}

export const View = view(({ Section }: ViewProps) => {
	return (
		<>
			<Section>
				<>
					<LabelPresentational
						opened={model.isAccessesSectionOpened}
						icon={<Reusable.ViewIcon name="universal-access" className="w-5" />}
						empty={false}
						onChevron={() => intents.TOGGLE_ACCESSES_OF_SIDEBAR()}
						label={<div className="">Accesses</div>}
					/>
					{model.isAccessesSectionOpened && (
						<>
							<ViewSection
								title="BQ Projects"
								opened={model.isBQProjectAccessesOpened}
								empty={
									model.bQProjectAcceses._tag === 'loaded' &&
									model.bQProjectAcceses.value.length === 0
								}
								icon="database"
								onChevron={() => intents.TOGGLE_BQ_PROJECT_ACCESSES_OF_SIDEBAR()}
								onAdd={() => {
									const options =
										model.organizationRoles._tag === 'loaded'
											? createOrganizationOptions(
													model.organizationRoles.value.map(organizationRoleToOption)
											  )
											: createOrganizationOptions();
									intents.SELECT_FORM(
										createBQProjectForm({ organizationField: createOrganizationField('', options) })
									);
								}}
								items={model.bQProjectAcceses}
								onItemClick={item => {
									const organizationRoles =
										model.organizationRoles._tag === 'loaded' ? model.organizationRoles.value : [];
									intents.SELECT_FORM(bQProjectToForm(item as BQProject, organizationRoles));
								}}
							/>
							<ViewSection
								title="Apple"
								opened={model.isAppleAccessesOpened}
								empty={model.appleAcceses._tag === 'loaded' && model.appleAcceses.value.length === 0}
								icon="apple"
								onChevron={() => intents.TOGGLE_APPLE_ACCESSES_OF_SIDEBAR()}
								onAdd={() => intents.SELECT_FORM(createAppleAccessForm())}
								items={model.appleAcceses}
								onItemClick={item => intents.SELECT_FORM(appleAccessToForm(item as AppleAccess))}
							/>
						</>
					)}
				</>
			</Section>
			<Section>
				<>
					<LabelPresentational
						opened={model.isFeedsSectionOpened}
						icon={<Reusable.ViewIcon name="rss" className="w-5" />}
						empty={false}
						onChevron={() => intents.TOGGLE_FEEDS_OF_SIDEBAR()}
						label={<div className="">Feeds</div>}
					/>
					{model.isFeedsSectionOpened && (
						<>
							<ViewSection
								title="Apple"
								opened={model.isAppleFeedsOpened}
								empty={model.appleFeeds._tag === 'loaded' && model.appleFeeds.value.length === 0}
								icon="apple"
								onChevron={() => intents.TOGGLE_APPLE_FEEDS_OF_SIDEBAR()}
								onAdd={() => {
									const options =
										model.organizationRoles._tag === 'loaded'
											? createOrganizationOptions(
													model.organizationRoles.value.map(organizationRoleToOption)
											  )
											: createOrganizationOptions();
									intents.SELECT_FORM(
										createAppleForm({ organizationField: createOrganizationField('', options) })
									);
								}}
								items={model.appleFeeds}
								onItemClick={item => {
									const organizationRoles =
										model.organizationRoles._tag === 'loaded' ? model.organizationRoles.value : [];
									intents.SELECT_FORM(appleToForm(item as Apple, organizationRoles))
								}}
							/>
						</>
					)}
				</>
			</Section>
		</>
	);
});

const ViewSection = view(
	({
		title,
		opened,
		empty,
		icon,
		onChevron,
		onAdd,
		items,
		onItemClick
	}: {
		title: string;
		opened: boolean;
		empty: boolean;
		icon: string;
		onChevron: () => void;
		onAdd: () => void;
		items: AsyncData<Item[], string>;
		onItemClick: (access: Item) => void;
	}) => {
		return (
			<>
				<LabelPresentational
					className="pl-8"
					opened={opened}
					icon={<Reusable.ViewIcon name={icon} className="w-5" />}
					empty={empty}
					onChevron={onChevron}
					label={
						<div className="center-v jc-between w-full text-sidebar-font-dark">
							<span>{title}</span>
							<Reusable.ViewIcon
								className="cursor-pointer zoom"
								name="plus"
								onClick={e => {
									e.stopPropagation();
									onAdd();
								}}
							/>
						</div>
					}
				/>
				{opened && items._tag === 'loaded' ? (
					<>
						<div className="overflow-overlay max-h-38 sidebar-dropdown-scroll">
							{items.value.map(item => (
								<ViewItem key={item.id} item={item} onClick={() => onItemClick(item)} />
							))}
						</div>
					</>
				) : items._tag === 'loading' ? (
					<div className="relative center py-2 text-sidebar-font-light">
						<Reusable.ViewIcon className="spin " name="spinner" weight="solid" />
					</div>
				) : items._tag === 'error' ? (
					<div className="relative center py-2 text-sidebar-font-light">
						<Reusable.ViewIcon className="text-red" name="spinner" weight="solid" />
					</div>
				) : null}
			</>
		);
	}
);

const ViewItem = ({ item, onClick }: { item: Item; onClick: () => void }) => {
	return (
		<div className="relative center-v py-2 pr-9 text-sidebar-font-light">
			<div className="pl-12 center-v nowrap text-xs w-full">
				<Reusable.ViewIcon className="mr-2" name="hat-wizard" />
				<div className="ellipsis w-20 cursor-pointer" onClick={onClick}>
					{item.descriptiveName}
				</div>
			</div>
		</div>
	);
};
