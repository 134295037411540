import * as Reusable from '@/Reusable';
import * as React from 'react';

interface Props {
	onAdd;
}

export const MetricAddCustomNumberButtonPresentational = ({ onAdd }: Props) => {
	const [value, setValue] = React.useState('');

	const onConfirm = (val: number | '') => {
		if (!val) return val;
		onAdd(val);
		setValue('');
		return '';
	};

	return (
		<div className="center-v ml-2">
			<div className="mr-4">
				<Reusable.ViewInput
					value={value}
					onChange={val => (setValue(val), val)}
					onConfirm={onConfirm}
					type="number"
					placeholder="Add day..."
				/>
			</div>
			<button
				className="center rounded p-2-4 bg-c-accent2-pale text-white h-8 hover:bg-c-accent2-pale-darkened"
				onClick={() => onConfirm(value)}
			>
				<div className="mr-2">
					<Reusable.ViewIcon name="plus" />
				</div>
				<div>Add day</div>
			</button>
		</div>
	);
};
