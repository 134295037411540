import { createIntents } from '@/model';

export const sidebarIntents: SidebarIntents = createIntents(
	(present): SidebarIntents => ({
		TOGGLE_DIMENSION_FILTERS_OF_SIDEBAR: () => present({ type: 'TOGGLE_DIMENSION_FILTERS_OF_SIDEBAR' }),
		TOGGLE_DIMENSIONS_OF_SIDEBAR: () => present({ type: 'TOGGLE_DIMENSIONS_OF_SIDEBAR' }),
		TOGGLE_ORGANIZATION_ACTIVE_VIEWS_OF_SIDEBAR: () =>
			present({ type: 'TOGGLE_ORGANIZATION_ACTIVE_VIEWS_OF_SIDEBAR' }),

		TOGGLE_ORGANIZATION_ARCHIVED_VIEWS_OF_SIDEBAR: () =>
			present({ type: 'TOGGLE_ORGANIZATION_ARCHIVED_VIEWS_OF_SIDEBAR' }),
		TOGGLE_ORGANIZATION_VIEWS_OF_SIDEBAR: () => present({ type: 'TOGGLE_ORGANIZATION_VIEWS_OF_SIDEBAR' }),
		TOGGLE_SIDEBAR: () => present({ type: 'TOGGLE_SIDEBAR' }),
		TOGGLE_USER_VIEWS_OF_SIDEBAR: () => present({ type: 'TOGGLE_USER_VIEWS_OF_SIDEBAR' }),
		TOGGLE_VIEWS_OF_SIDEBAR: () => present({ type: 'TOGGLE_VIEWS_OF_SIDEBAR' }),
		TOGGLE_ACCESSES_OF_SIDEBAR: () => present({ type: 'TOGGLE_ACCESSES_OF_SIDEBAR' }),
		TOGGLE_FEEDS_OF_SIDEBAR: () => present({ type: 'TOGGLE_FEEDS_OF_SIDEBAR' }),
		TOGGLE_BQ_PROJECT_ACCESSES_OF_SIDEBAR: () => present({ type: 'TOGGLE_BQ_PROJECT_ACCESSES_OF_SIDEBAR' }),
		TOGGLE_APPLE_ACCESSES_OF_SIDEBAR: () => present({ type: 'TOGGLE_APPLE_ACCESSES_OF_SIDEBAR' }),
		TOGGLE_APPLE_FEEDS_OF_SIDEBAR: () => present({ type: 'TOGGLE_APPLE_FEEDS_OF_SIDEBAR' }),
	})
);

export type SidebarIntents = {
	TOGGLE_DIMENSION_FILTERS_OF_SIDEBAR(): void;
	TOGGLE_DIMENSIONS_OF_SIDEBAR(): void;
	TOGGLE_ORGANIZATION_ACTIVE_VIEWS_OF_SIDEBAR(): void;
	TOGGLE_ORGANIZATION_ARCHIVED_VIEWS_OF_SIDEBAR(): void;
	TOGGLE_ORGANIZATION_VIEWS_OF_SIDEBAR(): void;
	TOGGLE_SIDEBAR(): void;
	TOGGLE_USER_VIEWS_OF_SIDEBAR(): void;
	TOGGLE_VIEWS_OF_SIDEBAR(): void;
	TOGGLE_ACCESSES_OF_SIDEBAR(): void;
	TOGGLE_FEEDS_OF_SIDEBAR(): void;
	TOGGLE_BQ_PROJECT_ACCESSES_OF_SIDEBAR(): void;
	TOGGLE_APPLE_ACCESSES_OF_SIDEBAR(): void;
	TOGGLE_APPLE_FEEDS_OF_SIDEBAR(): void;
};
