import * as Reusable from '@/Reusable';
import * as React from 'react';
import { classNames } from '@/imports';

const unit = () => {};

export const SidebarItemPresentational = ({
	className = '',
	onClick = unit,
	children,
	icon = null,
	onIconClick = unit,
	hasEdit = true
}) => {
	const classes = classNames(
		className,
		'group hover:bg-sidebar-item-hover',
		'relative center-v py-2 pr-9 text-sidebar-font-light'
	);

	const editClass = classNames('hidden ai-center absolute text-sidebar-font-light translateX-2', {
		'group-hover:flex': hasEdit
	});

	return (
		<div className={classes} onClick={onClick}>
			<div className={editClass}>
				<Reusable.ViewIcon name="pen" size="nano" />
			</div>
			{children}
			<div
				className="absolute flex right-0 -translateX-4 cursor-pointer text-sidebar-font-dark"
				onClick={e => (e.stopPropagation(), onIconClick())}
			>
				{icon}
			</div>
		</div>
	);
};
