import React from 'react';
import { BQProject } from '..';

export type Organization = {
	id: string;
	createdAt: Date;
	updatedAt: Date;
	name: string;
	games: Game[];
	memberships: OrganizationMembership[];
	bqProjects: BQProject[];
};

type Game = {
	id: string;
	createdAt: Date;
	updatedAt: Date;
	name: string;
	organization: Organization;
	bqProjects: BQProject[];
};

type OrganizationMembership = {
	id: string;
	createdAt: Date;
	updatedAt: Date;
	organization: Organization;
	user: User;
	role: UserRole;
};

type User = {
	id: string;
	email: string;
	views: View[];
};

type View = {
	id: string;
	createdAt: Date;
	updatedAt: Date;
	archivedAt: Date;
	organization: Organization;
	author: User;
	name: string;
	urlQueryParams: string;
};

enum UserRole {
	SUPER_ADMIN,
	ADMIN,
	MEMBER,
	GUEST
}

export type OrganizationRole = {
	organizationId: string;
	organizationName: string;
	role: string;
};

export type OrganizationField = {
	id: 'organizationId';
	label: 'Organization';
	type: 'select';
	value: OrganizationOptions[number]['value'];
	options: OrganizationOptions;
};
export const createOrganizationField = (
	value = '',
	options: OrganizationOptions = [
		{ text: 'Select an organization', value: '', label: <span className="text-grey">Select an organization</span> }
	]
): OrganizationField => ({
	id: 'organizationId',
	label: 'Organization',
	type: 'select',
	value,
	options
});

type OrganizationOption = { text: string; value: string; label?: JSX.Element };
type OrganizationOptions = [{ text: 'Select an organization'; value: ''; label: JSX.Element }, ...OrganizationOption[]];
export const createOrganizationOptions = (options: OrganizationOption[] = []): OrganizationOptions => [
	{ text: 'Select an organization', value: '', label: <span className="text-grey">Select an organization</span> },
	...options
];

export const organizationRoleToOption = (role: OrganizationRole): OrganizationOption => ({
	text: role.organizationName,
	value: role.organizationId
});
