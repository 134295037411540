import { metricsIntents, MetricsIntents } from '@/intents/metrics';
import { createIntents } from '@/model';
import { rawFiltersIntents, RawFiltersIntents } from './rawFilters';
import { sidebarIntents, SidebarIntents } from './sidebar';
import { tableIntents, TableIntents } from './table';
import { sessionIntents, SessionIntents } from './session';
import { viewsIntents, ViewsIntents } from './views';
import { EtlIntents, etlIntents } from './etl';
// import { Operator } from '@dsl/operators';

export const intents: Intents = {
	...metricsIntents,
	...rawFiltersIntents,
	...sidebarIntents,
	...tableIntents,
	...sessionIntents,
	...viewsIntents,
	...etlIntents,
	...createIntents(
		(present): GeneralIntents => ({
			ADD_DIMENSION: groupBy => present({ payload: groupBy, type: 'ADD_DIMENSION' }),
			CLOSE_METRIC_PICKER: () => present({ type: 'UPDATE_IS_METRIC_PICKER_OPENED' }),
			COLLAPSE_ALL_ROWS: () => present({ type: 'COLLAPSE_ALL_ROWS' }),
			EXPAND_ROWS_OF_DIMENSION: breakdownLevel => {
				present({ type: 'COLLAPSE_ALL_ROWS' });
				present({ payload: breakdownLevel, type: 'EXPAND_ROWS_OF_DIMENSION' });
			},
			EXPORT_TABLE_AT_DIMENSION: (breakdownLevel, propagateError = false) =>
				present({ payload: { breakdownLevel, propagateError }, type: 'EXPORT_TABLE_AT_DIMENSION' }),
			FETCH_ALMOST_ALL_TABLE_DATA: () => present({ type: 'FETCH_ALMOST_ALL_TABLE_DATA' }),
			FETCH_MORE_TABLE_DATA: breakdownLevel => after => amount =>
				present({
					payload: { after, amount, breakdownLevel },
					type: 'FETCH_MORE_TABLE_DATA'
				}),
			FETCH_TABLE_DATA: () => present({ type: 'FETCH_TABLE_DATA' }),
			INITIALIZE_ANALYTICS_PAGE: () => present({ type: 'INITIALIZE_ANALYTICS_PAGE' }),
			LOAD_MODEL: (urlQueryParams: string) => present({ type: 'LOAD_MODEL', payload: urlQueryParams }),
			OPEN_METRIC_PICKER: () => present({ payload: true, type: 'UPDATE_IS_METRIC_PICKER_OPENED' }),
			REMOVE_ALL_SORTINGS: () => present({ type: 'REMOVE_ALL_SORTINGS' }),
			REMOVE_DIMENSION: breakdownLevel => present({ payload: breakdownLevel, type: 'REMOVE_DIMENSION' }),
			REMOVE_PREDICATE_OF_DIMENSION: breakdownId => column => predicate => {
				present({
					payload: { breakdownId, column, predicate },
					type: 'REMOVE_PREDICATE_OF_DIMENSION'
				});
			},
			REMOVE_SORTING_OF_DIMENSION: breakdownId => column =>
				present({
					payload: { breakdownId, column },
					type: 'REMOVE_SORTING_OF_DIMENSION'
				}),
			SET_URL: page => present({ payload: page, type: 'SET_URL' }),
			SIGN_USER_IN: () => present({ type: 'SIGN_USER_IN' }),
			SIGN_USER_OUT: () => present({ type: 'SIGN_USER_OUT' }),
			TOGGLE_ROW: rowToOpen => {
				present({ payload: rowToOpen, type: 'TOGGLE_ROW' });
			},
			UPDATE_DIMENSION: breakdownLevel => id => {
				if (!id) present({ payload: breakdownLevel, type: 'REMOVE_DIMENSION' });
				if (id) present({ payload: { breakdownLevel, id }, type: 'UPDATE_DIMENSION' });
			},
			UPDATE_FILTER_PREDICATE_OF_DIMENSION: breakdownId => column => predicate => {
				present({
					payload: { breakdownId, column, predicate },
					type: 'UPDATE_FILTER_PREDICATE_OF_DIMENSION'
				});
			},
			UPDATE_ORDER_OF_DIMENSIONS: oldIndex => newIndex => {
				present({
					payload: { newIndex, oldIndex },
					type: 'UPDATE_ORDER_OF_DIMENSIONS'
				});
			},
			UPDATE_ORDER_OF_SORTINGS_OF_DIMENSION: breakdownId => oldIndex => newIndex => {
				present({
					payload: { breakdownId, newIndex, oldIndex },
					type: 'UPDATE_ORDER_OF_SORTINGS_OF_DIMENSION'
				});
			},
			UPDATE_SORTING_METRIC_OF_DIMENSION: breakdownId => metricFrom => metricTo => {
				present({
					payload: { breakdownId, metricFrom, metricTo },
					type: 'UPDATE_SORTING_METRIC_OF_DIMENSION'
				});
			},
			UPDATE_SORTING_OF_DIMENSION: breakdownId => column => order => {
				present({
					payload: { breakdownId, column, order },
					type: 'UPDATE_SORTING_OF_DIMENSION'
				});
			},
			UPDATE_TABLE_WIDTH: width => present({ type: 'UPDATE_TABLE_WIDTH', payload: width })
		})
	)
};

type GeneralIntents = {
	ADD_DIMENSION(groupBy: Dimension['value']): void;
	CLOSE_METRIC_PICKER: () => void;
	COLLAPSE_ALL_ROWS: () => void;
	EXPAND_ROWS_OF_DIMENSION: (level: number) => void;
	EXPORT_TABLE_AT_DIMENSION: (level: number) => void;
	FETCH_ALMOST_ALL_TABLE_DATA(): void;
	FETCH_MORE_TABLE_DATA: (level: number) => (after: number) => (amount: number) => void;
	FETCH_TABLE_DATA: () => void;
	INITIALIZE_ANALYTICS_PAGE: () => void;
	LOAD_MODEL(urlQueryParams: string): void;
	OPEN_METRIC_PICKER: () => void;
	REMOVE_ALL_SORTINGS: () => void;
	REMOVE_DIMENSION(level: number): void;
	REMOVE_PREDICATE_OF_DIMENSION: (
		breakdownId: Dimension['value']
	) => (column: string) => (predicate: Operator['operator']) => void;
	REMOVE_SORTING_OF_DIMENSION: (breakdownId: Dimension['value']) => (column: string) => void;
	SET_URL: (page: Page) => void;
	SIGN_USER_IN: () => void;
	SIGN_USER_OUT;
	TOGGLE_ROW;
	UPDATE_DIMENSION;
	UPDATE_FILTER_PREDICATE_OF_DIMENSION;
	UPDATE_ORDER_OF_DIMENSIONS;
	UPDATE_ORDER_OF_SORTINGS_OF_DIMENSION;
	UPDATE_SORTING_METRIC_OF_DIMENSION;
	UPDATE_SORTING_OF_DIMENSION;
	UPDATE_TABLE_WIDTH;
};

export type Intents = GeneralIntents &
	MetricsIntents &
	SidebarIntents &
	RawFiltersIntents &
	TableIntents &
	SessionIntents &
	ViewsIntents &
	EtlIntents;
