import React from 'react';
import { classNames } from '@/imports';
import * as Reusable from '@/Reusable';
import { view } from 'funwork-js';

export const View = view(
	({
		children,
		closed = false,
		toggleOpened
	}: {
		children?: JSX.Element;
		closed?: boolean;
		toggleOpened: () => void;
	}) => {
		const classes = classNames(
			'sidebar-layout',
			'w-sidebar-width',
			'h-full',
			'bg-sidebar',
			'transition-03',
			closed ? 'w-sidebar-width-collapsed' : 'w-sidebar-width'
		);

		const toggleClasses = classNames('absolute', 'right-0', closed ? '-translateX-5px' : '-translateX-4');

		return (
			<div className={classes}>
				<div className="relative center h-header-height">
					<div className={toggleClasses}>
						<Reusable.ViewIcon className="cursor-pointer text-sidebar-font-light" name="bars" onClick={toggleOpened} />
					</div>
				</div>
				{closed ? null : children}
			</div>
		);
	}
);

export const Section = view(({ children }) => {
	return <div className="py-4 pt-2 border-b-2px border-sidebar-font-dark last-child:border-b-none">{children}</div>;
});
