import {
	deserializeUrlToModel,
	devTools,
	model,
	Model,
	routerSetup as _routerSetup,
	serializeModelToUrl
} from '@/model';
import { enhancePredicates, enhanceSelectedMetrics } from '@/model/reducer';
import { raw } from '@nx-js/observer-util';

const onPopState = (model: Model) => {
	model.predicates = enhancePredicates(model, model.metricsSchema);
	model.selectedMetrics = enhanceSelectedMetrics(model, model.metricsSchema);
	if (devTools) {
		devTools.send({}, raw(model));
	}
};

export const routerSetup = () => _routerSetup({ model, serializeModelToUrl, deserializeUrlToModel, onPopState });
