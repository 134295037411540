export type View = {
	id: string;
	name: string;
	urlQueryParams: string;
	archivedAt: Date;
};

export type ViewsModel = {
	activeViews: View[];
	archivedViews: View[];
	userViews: View[];
};

export const viewsModel: ViewsModel = {
	activeViews: [],
	archivedViews: [],
	userViews: []
};
