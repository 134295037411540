import React from 'react';
import * as Reusable from '@/Reusable';
import { classNames } from '@/imports';

interface Props {
	className?: string;
	menuClassName?: string;
	itemClassName?: string;
	activeItemClassName?: string;
	options;
	value?;
	defaultValue?;
	trigger?;
	icon?: JSX.Element;
	onChange;
	onOpen?;
}

export const SidebarDropdownPresentational = ({
	className = '',
	menuClassName = '',
	itemClassName = '',
	activeItemClassName = '',
	options,
	value,
	defaultValue,
	trigger,
	onChange,
	onOpen,
	icon
}: Props) => {
	const classes = classNames(className, 'sidebar-dropdown-presentational');
	const menuClasses = classNames(
		menuClassName,
		'override:bg-sidebar-dd text-sidebar-font-dark max-h-96 overflow-overlay sidebar-dropdown-scroll'
	);
	const itemClasses = classNames(itemClassName, 'hover:bg-sidebar-dd-item-hover');
	const activeItemClasses = classNames(activeItemClassName, 'bg-sidebar-dd-item-hover');

	return (
		<Reusable.ViewDropdown
			className={classes}
			menuClassName={menuClasses}
			itemClassName={itemClasses}
			activeItemClassName={activeItemClasses}
			options={options}
			value={value}
			defaultValue={defaultValue}
			trigger={trigger}
			onChange={onChange}
			onOpen={onOpen}
			icon={icon}
		/>
	);
};
