export const partition = <T>(condition: (T: T) => boolean) => (array: T[]): [T[], T[]] => {
	const orig = [];
	const filtered = [];
	for (const item of array) {
		if (condition(item)) {
			orig.push(item);
		} else {
			filtered.push(item);
		}
	}
	return [orig, filtered];
};
