import * as React from 'react';
import * as Reusable from '@/Reusable';

// ----------------------------------------------------- MODEL ---------------------------------------------------

export type AsyncState = 'notLoading' | 'loading' | 'loaded' | 'error';
export type AsyncData<T, E> =
	| { _tag: 'notLoading'}
	| { _tag: 'loading'}
	| { _tag: 'loaded', value: T}
	| { _tag: 'error', value?: E};

// ----------------------------------------------------- VIEW ---------------------------------------------------

export const ViewLoadingStateIcon = ({ className = '', state }: { className: string; state: AsyncState }) => (
	<div className={className}>
		{state === 'loading' && <Reusable.ViewIcon className="spin " name="spinner" weight="solid" size="normal" />}
		{state === 'loaded' && <Reusable.ViewIcon className="text-c-accent2-pale" name="check" weight="solid" size="normal" />}
		{state === 'error' && <Reusable.ViewIcon className="text-red" name="exclamation-triangle" weight="solid" size="normal" />}
	</div>
);
